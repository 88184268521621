import {Component, OnInit} from '@angular/core';
import {combineLatest, Observable, startWith, Subject, Subscription, switchMap} from 'rxjs';
import {Page} from '../../../shared/pagination/page';
import {Header} from '../../../shared/table/header';
import {Program} from '../../model/program';
import {TranslateService} from '@ngx-translate/core';
import {CreateOrUpdateProgramModalComponent} from '../create-or-update-program/create-or-update-program-modal.component';
import {ModalService} from '../../../shared/modal/modal.service';
import {ProgramService} from '../../_service/program.service';
import {DeleteObserverFactory} from '../../../shared/delete/delete-observer-factory';

@Component({
	selector: 'app-program-overview',
	templateUrl: './program-overview.component.html',
	standalone: false
})
export class ProgramOverviewComponent implements OnInit {
	page$: Observable<Page<Program>>;
	headers: Header[] = [
		{name: 'program'}
	];
	subscription = new Subscription();
	private refresh$ = new Subject<void>();
	private currentPage$ = new Subject<number>();

	constructor(private programService: ProgramService,
				private modalService: ModalService,
				private translateService: TranslateService,
				private deleteObserverFactory: DeleteObserverFactory) {
	}

	ngOnInit(): void {
		this.page$ = combineLatest([this.currentPage$.pipe(startWith(1)), this.refresh$.pipe(startWith(''))])
			.pipe(
				switchMap(([currentPage]) => this.programService.search(currentPage - 1))
			);
	}

	loadPage(page: number): void {
		this.currentPage$.next(page);
	}

	openCreateModal(): void {
		this.modalService.openCreateModal(CreateOrUpdateProgramModalComponent, 'program').subscribe(() => this.refresh$.next());
	}

	openUpdateModal(program: Program): void {
		this.modalService.openUpdateModal(CreateOrUpdateProgramModalComponent, program, 'program').subscribe(() => this.refresh$.next());
	}

	deleteProgram(program: Program): void {
		this.subscription.add(
			this.programService.delete(program.uuid)
				.subscribe(this.deleteObserverFactory.createFactory('program.delete.success-message', this.refresh$))
		);
	}

	getConfirmationMessage(): string {
		return `<p>${this.translateService.instant('program.delete.confirmation-message.are-you-sure')}</p>`;
	}
}
