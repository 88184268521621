<ng-container *ngIf="malwareTag$ | async as malwareTag">
	<div id="malware-tag" class="badge mb-1 tag"
		 [ngbTooltip]="'malware.tag.' + malwareTag.status | translate"
		 [class.not-scanned]="malwareTag.status === 'NOT_SCANNED_YET'"
		 [class.safe]="malwareTag.status === 'NO_THREATS_FOUND'"
		 [class.infected]="malwareTag.status === 'THREATS_FOUND'"
		 [class.suspicious]="malwareTag.status === 'UNSUPPORTED' || malwareTag.status === 'ACCESS_DENIED' || malwareTag.status === 'FAILED'"
	>
		<i class="ti ti-text-size=10"
		   [class.ti-clock-shield]="malwareTag.status === 'NOT_SCANNED_YET'"
		   [class.ti-shield-check-filled]="malwareTag.status === 'NO_THREATS_FOUND'"
		   [class.ti-shield-exclamation]="malwareTag.status === 'THREATS_FOUND'"
		   [class.ti-shield-question]="malwareTag.status === 'UNSUPPORTED' || malwareTag.status === 'ACCESS_DENIED' || malwareTag.status === 'FAILED'"
		></i>
	</div>
</ng-container>
