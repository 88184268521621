import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Observable, startWith, Subject, Subscription, switchMap} from 'rxjs';
import {ProductionCatalogue} from '../../_model/production-catalogue';
import {ProductionCatalogueService} from '../../_service/production-catalogue.service';
import {ToastrMessageService} from '../../../shared/toastr/toastr.message.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LaunchProductionCatalogueRequest} from '../../_model/launch-production-catalogue-request';
import {ToastrMessageType} from '../../../shared/toastr/toastr.message.type';
import {ArticleReferenceRequestStatus} from '../../_model/article-reference-request-status';
import {RequestStatus} from '../../_model/request-status';
import {HttpErrorResponse} from '@angular/common/http';
import {FormArray, FormGroup} from '@angular/forms';
import {Page} from '../../../shared/pagination/page';
import {Launch} from '../../_model/launch';

@Component({
	selector: 'app-production-catalogue-launch',
	templateUrl: 'production-catalogue-launch.component.html'
})
export class ProductionCatalogueLaunchComponent implements OnInit, OnDestroy {
	private subscription = new Subscription();

	page$: Observable<Page<Launch>>;
	productionCatalogue$: Observable<ProductionCatalogue>;
	errorResponse: HttpErrorResponse;
	productionCatalogueUuid: string | null = null;
	isLaunching: boolean = false;

	private refresh$ = new Subject<void>();
	private currentPage$ = new Subject<number>();

	constructor(private readonly productionCatalogueService: ProductionCatalogueService,
				private readonly toastrMessageService: ToastrMessageService,
				private readonly translateService: TranslateService,
				private readonly router: Router,
				private readonly activatedRoute: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.activatedRoute.params.subscribe((params): void => {
			this.productionCatalogueUuid = params['uuid'];
			this.productionCatalogue$ = this.productionCatalogueService.get(this.productionCatalogueUuid);
			this.page$ = combineLatest([
				this.currentPage$.pipe(startWith(1)),
				this.refresh$.pipe(startWith(''))
			]).pipe(
				switchMap(([currentPage]) => this.productionCatalogueService.getLaunchHistory(currentPage - 1, this.productionCatalogueUuid))
			);
			this.subscription.add(this.page$.subscribe(page => {
				if (page.content[0] !== undefined) {
					this.isLaunching = page.content[0].launchArticles.every(art => art.launching);
				}
			}));
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	closePage(): void {
		this.router.navigate(['production-catalogue']);
	}

	submitForm(form: FormGroup): void {
		this.launchCatalogue(this.getLaunchProductionCatalogueRequest(form));
		this.errorResponse = null;
	}

	launchCatalogue(request: LaunchProductionCatalogueRequest): void {
		this.subscription.add(this.productionCatalogueService.launch(request.uuid, request).subscribe({
			next: () => {
				this.errorResponse = null;
				this.isLaunching = true;
				this.toastrMessageService.show({
					type: ToastrMessageType.SUCCESS,
					messageHTML: `<i class="ti ti-check me-2 fs-5"></i> ${this.translateService.instant('production-catalogue.launch.success-message')}`
				});
			},
			error: (errorResponse) => {
				this.isLaunching = false;
				this.errorResponse = errorResponse;
			}
		}));
	}

	getArticleReferenceRequestStatuses(): ArticleReferenceRequestStatus[] {
		if (this.errorResponse.error.errorCode === 'error.launch.could.not.proceed') {
			return this.errorResponse.error.params as ArticleReferenceRequestStatus[];
		} else {
			return [];
		}
	}

	getTranslationKeyForRequestStatus(status: RequestStatus): string {
		return status.toLowerCase().replaceAll('_', '.');
	}

	getLaunchProductionCatalogueRequest(form: FormGroup): LaunchProductionCatalogueRequest {
		const launchForm = form.get('launchForm') as FormGroup;
		const articles = launchForm.get('launchArticles') as FormArray;
		const articleDatasets = {} as any;
		const articleDatabaseNames = {} as any;

		articles.controls
			.map(control => control as FormGroup)
			.filter(articleFormGroup => articleFormGroup.get('selected').value)
			.forEach(articleFormGroup => {
				const articleUuid = articleFormGroup.get('articleUuid').value;
				articleDatasets[articleUuid] = articleFormGroup.get('dataset').value.uuid;
				if (articleFormGroup.get('database').value) {
					if (articleFormGroup.get('database').value.databaseName) {
						articleDatabaseNames[articleUuid] = articleFormGroup.get('database').value.databaseName;
					} else {
						articleDatabaseNames[articleUuid] = articleFormGroup.get('database').value;
					}
				}

			});

		return {
			uuid: this.productionCatalogueUuid,
			launchType: launchForm.get('type').value,
			articleDataSetUuids: articleDatasets,
			articleDatabaseNames: articleDatabaseNames
		};
	}

	checkLaunchAndValidity(form: FormGroup): boolean {
		return form.invalid || this.isLaunching;
	}
}
