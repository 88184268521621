import {Component, OnDestroy, OnInit} from '@angular/core';
import {Header} from '../../../shared/table/header';
import {TranslateService} from '@ngx-translate/core';
import {Notice, NoticeStatus} from '../notice';
import {NoticeService} from '../../_service/notice.service';
import {startWith, Subject, Subscription, switchMap} from 'rxjs';
import {BaseOverviewComponent} from '../base-overview/base-overview.component';
import {DeleteObserverFactory} from '../../../shared/delete/delete-observer-factory';

@Component({
	selector: 'app-notice-overview',
	templateUrl: './notice-overview.component.html',
	styleUrls: ['./notice-overview.component.css'],
	standalone: false
})
export class NoticeOverviewComponent extends BaseOverviewComponent implements OnInit, OnDestroy {

	notices: Array<Notice>;
	activeSorting = 'ALL';
	sortingTypes: string[] = ['ALL', 'TO_COME', 'ACTIVE', 'OBSOLETE'];
	sortedNotices: Array<Notice>;
	headers: Header[] = [
		{name: 'status'},
		{name: 'publication-date'},
		{name: 'reference', permissions: ['VIEW_NOTICE_REFERENCE']},
		{name: 'title'},
		{name: 'level'},
		{name: 'programs'},
		{name: 'valid-period'}
	];

	private refresh$ = new Subject<void>();
	private subscription = new Subscription();

	constructor(private noticeService: NoticeService,
				private translateService: TranslateService,
				private deleteObserverFactory: DeleteObserverFactory) {
		super();
	}

	ngOnInit(): void {
		this.subscription.add(this.refresh$.pipe(startWith('')).pipe(switchMap(() => this.noticeService.getAll()))
			.subscribe(data => {
				this.notices = data;
				this.sortedNotices = data;
			}));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}


	getStatusCssClass(status: NoticeStatus): string {
		switch (status) {
			case 'TO_COME':
				return 'to-come';
			case 'OBSOLETE':
				return 'obsolete';
			case 'ACTIVE':
				return 'active-status';
		}
	}

	setSorting(sorting: string): void {
		this.activeSorting = sorting;
		if (sorting === 'ALL') {
			this.headers[0].visible = true;
			this.sortedNotices = this.notices;
		} else {
			this.headers[0].visible = false;
			this.sortedNotices = this.notices.filter(n => n.status.toUpperCase() === sorting);
		}
	}

	deleteNotice(notice: Notice): void {
		this.subscription.add(this.noticeService.delete(notice.uuid)
			.subscribe(this.deleteObserverFactory.createFactory('notice.overview.delete.success-message', this.refresh$))
		);
	}

}
