import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LaunchProductionArticle} from '../../_model/launch-production-article';
import {combineLatest, Observable, shareReplay, Subscription, tap} from 'rxjs';
import {DatasetService} from '../../../dataset/_service/dataset.service';
import {AbstractControl, ControlContainer, FormArray, FormControl, FormGroup, FormGroupDirective, ValidatorFn, Validators} from '@angular/forms';
import {ProductionCatalogueService} from '../../_service/production-catalogue.service';
import {SelectOption} from '../../../shared/modular-forms/_model/select-option';
import {launchTypeOptions, mapDatabaseToSelectOption, mapDatasetToSelectOption} from '../../../shared/modular-forms/_model/select-option.factory';
import {ModularFormComponent} from '../../../shared/modular-forms/modular-form/modular-form.component';
import {PermissionService} from '../../../shared/permission/permission.service';
import {ProductionCatalogue} from '../../_model/production-catalogue';
import {DatasetOverview} from '../../../dataset/_model/dataset-overview';
import {FlightDatabase} from '../../../db/_model/flight-database';
import {FlightDatabaseService} from '../../../db/_service/flight-database.service';
import {Cycle} from 'airac-cc';

@Component({
	selector: 'app-production-catalogue-launch-article-form',
	templateUrl: './launch-article-form.component.html',
	viewProviders: [
		{
			provide: ControlContainer,
			useExisting: FormGroupDirective
		}
	]
})
export class LaunchArticleFormComponent extends ModularFormComponent implements OnInit, OnDestroy {

	@Input() prodCat: ProductionCatalogue;

	private subscription = new Subscription();
	articleDatasets = new Map<string, SelectOption[]>;
	articleDatabases = new Map<string, SelectOption[]>;
	allArticleDatabases: SelectOption[] = [];
	launchTypes: SelectOption[];
	launchArticles$: Observable<LaunchProductionArticle[]>;
	map = new Map<string, FormGroup>;
	isAnArticleMultiLoad: boolean = false;

	constructor(private datasetService: DatasetService,
				private productionCatalogueService: ProductionCatalogueService,
				private flightDatabaseService: FlightDatabaseService,
				private controlContainer: ControlContainer,
				private permissionService: PermissionService) {
		super('production-catalogue.launch');

		this.subscription.add(permissionService.getPermissions().subscribe(permissions => this.launchTypes = launchTypeOptions(permissions)));

		this.form.addControl('allArticles', new FormControl<boolean>(false));

		this.form.addControl('launchArticles', new FormArray([], [this.minSelectedArticles(1)]));
		this.form.addControl('type', new FormControl(null, [Validators.required]));

		this.getParentForm().addControl('launchForm', this.form);
	}

	ngOnInit(): void {
		this.launchArticles$ = this.productionCatalogueService.getLinkedArticles(this.prodCat.uuid).pipe(
			shareReplay()
		);

		this.form.get('allArticles').valueChanges.subscribe(v => {
			const formArray = this.form.get('launchArticles') as FormArray;
			formArray.controls.forEach(articleForm => {
				const selectedControl = (articleForm as FormGroup).get('selected');
				if (!selectedControl.disabled && selectedControl.value !== v) {
					selectedControl.setValue(v);
				}
			});
		});
		this.subscription.add(this.launchArticles$.subscribe(launchArticles => {

			launchArticles.forEach(launchArticle => {
				const formArray = this.form.get('launchArticles') as FormArray;
				const formGroup = this.createLaunchArticleFormGroup(launchArticle);
				formArray.push(formGroup);
				this.map.set(launchArticle.articleUuid, formGroup);
				if (launchArticle.multiLoad && !this.isAnArticleMultiLoad) {
					this.isAnArticleMultiLoad = true;
				}
			});

			const observables = launchArticles.map(launchArticle => {
				return this.getLinkedDatasets(launchArticle).pipe(tap(options => {
					this.articleDatasets.set(launchArticle.articleUuid, options);
					const datasetPreselection = this.getDatasetPreselection(options);
					if (datasetPreselection) {
						this.map.get(launchArticle.articleUuid).get('dataset').patchValue(datasetPreselection);
					}
				}));
			});
			this.subscription.add(combineLatest(observables).subscribe());

			const databaseObservables = launchArticles.map(launchArticle => {
				return this.getLinkedDatabases(launchArticle.articleUuid).pipe(tap(options => {
					this.articleDatabases.set(launchArticle.articleUuid, options);
					if (options) {
						this.allArticleDatabases.push(...options);
					}
					const databasePreselection = this.getDatabasePreselection(options);
					if (databasePreselection) {
						this.map.get(launchArticle.articleUuid).get('database').patchValue(databasePreselection);
					}
				}));
			});
			this.subscription.add(combineLatest(databaseObservables).subscribe());
		}));
	}

	private createLaunchArticleFormGroup(launchArticle: LaunchProductionArticle): FormGroup<any> {
		const articleFormGroup = new FormGroup({});
		const datasetControl = new FormControl({value: null, disabled: true}, [this.datasetHasSourceFile()]);
		const selectedControl = new FormControl(false);
		const airacControl = new FormControl({value: null, disabled: true});
		const releaseNumberControl = new FormControl({value: null, disabled: true});
		const databaseControl = new FormControl({value: null, disabled: true});

		articleFormGroup.addControl('selected', selectedControl);
		articleFormGroup.addControl('articleReference', new FormControl({value: launchArticle.articleReference, disabled: true}));
		articleFormGroup.addControl('articleUuid', new FormControl(launchArticle.articleUuid));
		articleFormGroup.addControl('productionDate', new FormControl({value: launchArticle.productionDate, disabled: true}));
		articleFormGroup.addControl('airac', airacControl);
		articleFormGroup.addControl('releaseNumber', releaseNumberControl);
		articleFormGroup.addControl('dataset', datasetControl);
		articleFormGroup.addControl('database', databaseControl);

		this.subscription.add(selectedControl.valueChanges.subscribe(value => {
			if (value) {
				datasetControl.addValidators(Validators.required);
				datasetControl.enable();
				if (launchArticle.multiLoad) {
					this.checkArticleDatabase(launchArticle.articleUuid);
					databaseControl.addValidators(Validators.required);
					databaseControl.enable();
				}
			} else {
				datasetControl.removeValidators(Validators.required);
				datasetControl.disable();
				databaseControl.removeValidators(Validators.required);
				databaseControl.disable();
			}
			datasetControl.updateValueAndValidity();
			databaseControl.updateValueAndValidity();
			const allArticlesSelected = this.allArticlesSelected();
			if (this.form.get('allArticles').value !== allArticlesSelected) {
				this.form.get('allArticles').setValue(allArticlesSelected, {emitEvent: false});
			}
		}));

		this.subscription.add(datasetControl.valueChanges.subscribe(dataset => {
			if (dataset && dataset.hasSourceFileAndSoc) {
				airacControl.patchValue(dataset.airacCycle !== null ? dataset.airacCycle : 'N/A');
				releaseNumberControl.patchValue(dataset.releaseNumber);
			} else if (dataset) {
				airacControl.patchValue('N/A');
				releaseNumberControl.patchValue('N/A');
			}
		}));
		return articleFormGroup;
	}

	getLinkedDatasets(article: LaunchProductionArticle): Observable<SelectOption[]> {
		return this.datasetService.getLinkedDatasets(article.radicalIdentifierUuid).pipe(
			mapDatasetToSelectOption(),
			shareReplay()
		);
	}

	getLinkedDatabases(articleUuid: string): Observable<SelectOption[]> {
		return this.flightDatabaseService.getLinkedFlightDatabases(articleUuid).pipe(
			mapDatabaseToSelectOption(),
			shareReplay()
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	getParentForm(): FormGroup {
		return this.controlContainer.control as FormGroup;
	}

	allArticlesSelected(): boolean {
		const formArray = this.form.get('launchArticles') as FormArray;
		return formArray.controls
			.filter(formGroup => !(formGroup as FormGroup).get('selected').disabled)
			.map(formGroup => (formGroup as FormGroup).get('selected').value)
			.reduce((a, b) => a && b, true);
	}

	minSelectedArticles(min = 1): ValidatorFn {
		const validator: ValidatorFn = (formArray: AbstractControl) => {
			if (formArray instanceof FormArray) {
				const totalSelected = formArray.controls
					.map((control) => (control as FormGroup).get('selected').value)
					.reduce((prev, next) => (next ? prev + next : prev), 0);
				return totalSelected >= min ? null : {min1Selected: true};
			}

			throw new Error('formArray is not an instance of FormArray');
		};

		return validator;
	}

	datasetHasSourceFile(): ValidatorFn {
		return (formControl: AbstractControl) => {
			if ((formControl.value)) {
				return this.checkRequiredFileForLaunchTypes(formControl);
			}
			return null;
		};
	}

	checkRequiredFileForLaunchTypes(formControl: AbstractControl): any {
		const hasSourceFile: boolean = formControl.value.hasSourceFile;
		const hasSocFile: boolean = formControl.value.hasSocFile;

		if (this.form.get('type').value === 'PROD') {
			if (!hasSourceFile && !hasSocFile) {
				return {noSourceFileOrSoc: true};
			}

			if (!hasSocFile) {
				return {noSocFile: true};
			}
		}

		if (!hasSourceFile) {
			return {noSourceFile: true};
		}
		return null;
	}

	getDatasetPreselection(options: SelectOption[]): DatasetOverview {
		return options
			.filter(option => option.value.airacCycle === this.prodCat.airacCycle)
			.filter(option => option.value.hasSourceFile)
			.filter(option => option.value.hasSocFile)
			.reduce((a, b) => (a?.value && b?.value && a.value.releaseNumber > b.value.releaseNumber) ? a : b, null)
			?.value;
	}

	getDatabasePreselection(options: SelectOption[]): FlightDatabase {
		const currentCycle = Cycle.fromIdentifier(this.prodCat.airacCycle);
		const filteredOptions = options.filter(option => {
			const optionCycle = Cycle.fromIdentifier(option.value.airacCycle);
			return optionCycle.effectiveStart < currentCycle.effectiveStart;
		});

		const latestDatabase = filteredOptions.reduce<SelectOption | null>((latest, current) => {
			const latestCycle = Cycle.fromIdentifier(latest?.value.airacCycle || '0001');
			const currentCycle = Cycle.fromIdentifier(current.value.airacCycle);
			return latestCycle.effectiveStart > currentCycle.effectiveStart ? latest : current;
		}, null);

		return latestDatabase?.value;
	}

	copyPreviousDatabase(article: LaunchProductionArticle): void {
		const formGroup = this.map.get(article.articleUuid);
		const dataset = formGroup.get('dataset').value;
		this.allArticleDatabases.push({
			id: dataset.uuid,
			value: dataset.identifier,
			label: dataset.identifier
		});
		this.articleDatabases.set(article.articleUuid, this.allArticleDatabases);
		formGroup.get('database').setValue(dataset.identifier);
	}

	selectionChanged(): void {
		const formArray = this.form.get('launchArticles') as FormArray;

		formArray.controls.forEach((formGroup) => {
			const datasetControl = formGroup.get('dataset');
			if (datasetControl) {
				datasetControl.updateValueAndValidity();
			}
		});
	}

	checkArticleDatabase(articleUuid: string): void {
		if (this.articleDatabases.get(articleUuid).length === 0) {
			this.articleDatabases.set(articleUuid, this.allArticleDatabases);
		}
	}

	isDatasetSelected(articleUuid: string): boolean {
		return !this.map.get(articleUuid).get('dataset').value;
	}
}
