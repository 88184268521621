import {Component} from '@angular/core';
import {UploadStep} from '../../shared/upload/upload-step';
import {Subject} from 'rxjs';

@Component({
	selector: 'app-fms-upload',
	templateUrl: './fms-upload.component.html',
	standalone: false
})
export class FmsUploadComponent {

	step: UploadStep = 'INPUT';
	selectedFiles: File[];
	reset$ = new Subject<void>();

	public submit(selectedFiles: File[]): void {
		this.selectedFiles = selectedFiles;
		this.step = 'PROCESSING';
	}

	public reset(): void {
		this.step = 'INPUT';
		this.selectedFiles = null;
		this.reset$.next();
	}

}
