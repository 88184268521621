<app-base-modal
	title="{{ 'production-catalogue.launch-article.modal.title' | translate }} ({{ launchModalData.launchCreatedDate | date: 'dd/MM/yyyy HH:mm zzzz' }}) - {{ launchModalData.launchCreatedBy }}"
	[disabled]=isDownloading()>
	<ng-container *ngIf="page$ | async as page">
		<ng-container *ngIf="page.totalElements > 0">
			<app-table
				id="launch-article-table"
				[headerNamePrefix]="'production-catalogue.launch-article.modal.overview.headers.'"
				[headers]="headers"
				viewHeight="50vh">
				<tr *ngFor="let launchArticle of page.content; let i = index">
					<td>{{ launchArticle.reference }}</td>
					<td>{{ launchArticle.databaseRadicalIdentifier }}</td>
					<td>
						<app-production-step [steps]="launchArticle.productionSteps"></app-production-step>
					</td>
					<td>
						<div *ngIf="!!launchArticle.productionError" id="tooltip" ngbTooltip="{{ launchArticle.productionError.errorMessage }}" placement="auto" tooltipClass="text-center">
							<button class="float-start btn btn-table btn-download"
									id="download-{{i}}"
									[disabled]="isDownloading()"
									(click)="downloadFile(launchArticle.productionError, launchArticle.uuid)">
								<i class="ti ti-file-download fs-4 me-2"
								></i>
								{{ launchArticle.productionError.errorCode }}
							</button>
						</div>
					</td>
					<td>{{ launchArticle.productionStepLatestUpdated | date: 'dd/MM/yyyy HH:mm zzzz' }}</td>
				</tr>
			</app-table>
			<app-pagination [page]="page" (pageEvent)="loadPage($event)"></app-pagination>
		</ng-container>
	</ng-container>

</app-base-modal>
