import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {ErrorService} from './error.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {ErrorInterceptor} from './error.interceptor';
import {CommonModule} from '@angular/common';
import {InvalidFeedbackComponent} from './invalid-feedback.component';

@NgModule({
	exports: [
		InvalidFeedbackComponent
	],
	declarations: [
		InvalidFeedbackComponent
	],
	imports: [
		TranslateModule,
		CommonModule
	],
	providers: [
		ErrorService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true
		}
	]
})
export class ErrorHandlingModule {

}
