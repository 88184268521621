import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {ModalService} from '../modal.service';
import {ConfirmationModalConfiguration} from './confirmation.modal.configuration';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[appConfirmationModal]',
	standalone: false
})
export class ConfirmationModalDirective {
	@Input() event = 'click';
	@Input() confirmationMessage: string;
	@Input() cancelButtonText = 'general.cancel';
	@Input() confirmButtonText = 'general.confirm';
	@Input() modalIcon = 'ti-alert-circle';
	@Input() danger = true;
	@Input() hasFooter = true;
	@Output() cancelAction = new EventEmitter<void>();
	@Output() confirmAction = new EventEmitter<void>();

	constructor(private modalService: ModalService) {
	}

	@HostListener('click', ['$event'])
	click(event: Event): void {
		if (this.event === 'click') {
			event.stopPropagation();
			const configuration: ConfirmationModalConfiguration = {
				confirmationMessage: this.confirmationMessage,
				confirmButtonText: this.confirmButtonText,
				cancelButtonText: this.cancelButtonText,
				danger: this.danger,
				confirmAction: this.confirmAction,
				cancelAction: this.cancelAction,
				modalIcon: this.modalIcon,
				hasFooter: this.hasFooter
			};

			this.modalService.showConfirmationModal(configuration);
		}
	}

	@HostListener('change', ['$event'])
	change(): void {
		if (this.event === 'change') {
			const configuration: ConfirmationModalConfiguration = {
				confirmationMessage: this.confirmationMessage,
				confirmButtonText: this.confirmButtonText,
				cancelButtonText: this.cancelButtonText,
				danger: this.danger,
				confirmAction: this.confirmAction,
				cancelAction: this.cancelAction,
				modalIcon: this.modalIcon,
				hasFooter: this.hasFooter
			};

			this.modalService.showConfirmationModal(configuration);
		}
	}
}
