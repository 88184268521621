import {NgModule} from '@angular/core';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {PermissionsAllowedDirective} from './permission.allowed.directive';
import {PermissionService} from './permission.service';

@NgModule({
	declarations: [
		PermissionsAllowedDirective
	],
	exports: [PermissionsAllowedDirective], imports: [CommonModule], providers: [PermissionService, provideHttpClient(withInterceptorsFromDi())]
})
export class PermissionsModule {

}
