import {Component, Input} from '@angular/core';
import {DndDropEvent} from 'ngx-drag-drop';
import {ModularFormControl} from '../modular-form-control/modular-form-control';

@Component({
	selector: 'app-modular-form-control-files',
	templateUrl: './modular-form-control-files.component.html',
	styleUrls: ['./modular-form-control-files.component.css'],
	standalone: false
})
export class ModularFormControlFilesComponent extends ModularFormControl {

	@Input() public accept = '*/*';

	filesDropped(event: DndDropEvent): void {
		Array.from(event.event.dataTransfer.files)
			.filter(file => this.accept == '*/*' || file.type == this.accept)
			.forEach(file => this.addFile(file));
	}

	filesSelected(event: Event): void {
		event.preventDefault();
		const target = event.target as HTMLInputElement;
		Array.from(target.files)
			.forEach(file => this.addFile(file));
		target.value = '';
	}

	deleteFile(file: File): void {
		const value: File[] = this.formControl.value;
		const index = value.indexOf(file);
		value.splice(index, 1);
		this.formControl.setValue([...value]);
	}

	private addFile(file: File): void {
		const value: File[] = this.formControl.value || [];
		if (value.indexOf(file) !== -1) {
			return;
		}
		const result = [...value, file];
		this.formControl.setValue(result);
	}
}
