import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Article} from '../_model/article';
import {ArticleService} from '../_service/article.service';
import {CreateOrUpdateArticleRequest} from '../_model/create-or-update-article-request';
import {finalize, Observable, tap} from 'rxjs';
import {UserService} from '../../user/_service/user.service';
import {User} from '../../user/_model/user';
import {PermissionService} from '../../shared/permission/permission.service';
import {ToastrMessageService} from '../../shared/toastr/toastr.message.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrMessageType} from '../../shared/toastr/toastr.message.type';
import {Router} from '@angular/router';

@Component({
	selector: 'app-article-form-page',
	templateUrl: './article-form-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false
})
export class ArticleFormPageComponent {

	@Input() article: Article;
	@Input() readonly = false;

	user$: Observable<User>;
	approvalUser$: Observable<boolean>;
	approvalProvidor$: Observable<boolean>;
	submitting = false;

	constructor(private articleService: ArticleService,
				private userService: UserService,
				private permissionService: PermissionService,
				private toastrMessageService: ToastrMessageService,
				private translateService: TranslateService,
				private router: Router) {
		this.user$ = userService.getCurrentUser();
		this.approvalUser$ = permissionService.hasAtLeastOnePermission(['REQUEST_ARTICLE_APPROVAL']);
		this.approvalProvidor$ = this.permissionService.hasAtLeastOnePermission(['APPROVE_ARTICLES']);
	}

	get titleTranslationKey(): string {
		if (this.readonly) {
			return 'article.view.modal.title';
		}
		return this.article ? 'article.update.modal.title' : 'article.create.modal.title';
	}

	getSubmitTranslationKey(): string {
		return this.article ? 'general.save' : 'general.create';
	}

	submitForm(article: CreateOrUpdateArticleRequest): void {
		this.doSubmit(article).subscribe();
	}

	close(): void {
		this.router.navigate(['articles']);
	}

	requestApproval(article: CreateOrUpdateArticleRequest): void {
		this.submitting = true;
		this.articleService.requestApproval(article).subscribe({
			next: () => {
				this.close();
			},
			error: () => {
				this.toastrMessageService.show({
					type: ToastrMessageType.ERROR,
					messageHTML: `<i class="ti ti-trash me-2 fs-5"></i> ${this.translateService.instant('article.form.approval.fail')}`,
					sticky: true
				});
			}
		});
	}

	approveArticle(article: CreateOrUpdateArticleRequest, includeMail: boolean): void {
		this.submitting = true;
		this.articleService.approveArticle(this.article.uuid, article, includeMail).subscribe({
			next: () => {
				this.close();
			},
			error: () => {
				this.toastrMessageService.show({
					type: ToastrMessageType.ERROR,
					messageHTML: `<i class="ti ti-trash me-2 fs-5"></i> ${this.translateService.instant('article.form.approval.fail')}`,
					sticky: true
				});
			}
		});
	}

	private doSubmit(article: CreateOrUpdateArticleRequest): Observable<void> {
		this.submitting = true;

		return this.submit(article)
			.pipe(
				tap(() => {
					this.submitting = false;
					this.close();
				}),
				finalize(() => this.submitting = false)
			);
	}

	private submit(request: CreateOrUpdateArticleRequest): Observable<void> {
		if (this.article) {
			return this.articleService.update(this.article.uuid, request);
		}
		return this.articleService.create(request);
	}
}
