{
	"name": "aviobook-portal-ui",
	"version": "1.8.0",
	"scripts": {
		"ng": "ng",
		"start": "ng serve --configuration development --proxy-config proxy.conf.json",
		"build": "ng build",
		"build:ci": "ng build",
		"watch": "ng build --watch --configuration development",
		"test": "ng test --code-coverage",
		"lint": "ng lint",
		"package": "pack build registry.acagroup.be/aviobook-portal/portal-ui:$npm_package_version --descriptor portal-ui.toml",
		"sbom": "syft scan registry.acagroup.be/aviobook-portal/portal-ui:$npm_package_version -o cyclonedx-json=target/image-bom.cdx.json && cyclonedx-npm --omit dev --output-file target/application-bom.cdx.json --spec-version 1.5 --short-PURLs",
		"upload-sbom": "mvn validate -Pupload-sbom -DreleaseVersion=$npm_package_version"
	},
	"private": false,
	"dependencies": {
		"@angular/animations": "^19.2.0",
		"@angular/common": "^19.2.0",
		"@angular/compiler": "^19.2.0",
		"@angular/core": "^19.2.0",
		"@angular/forms": "^19.2.0",
		"@angular/platform-browser": "^19.2.0",
		"@angular/platform-browser-dynamic": "^19.2.0",
		"@angular/router": "^19.2.0",
		"@ng-bootstrap/ng-bootstrap": "^18.0.0",
		"@ngx-translate/core": "^16.0.0",
		"@ngx-translate/http-loader": "^16.0.1",
		"@popperjs/core": "^2.11.6",
		"@zip.js/zip.js": "^2.7.31",
		"airac-cc": "^1.0.6",
		"angular-auth-oidc-client": "^19.0.0",
		"bootstrap": "^5.2.3",
		"follow-redirects": "^1.15.6",
		"ng-multiselect-dropdown": "^1.0.0",
		"ngx-drag-drop": "^19.0.0",
		"ngx-google-analytics": "14.0.1",
		"ngx-translate-multi-http-loader": "^19.0.2",
		"rxjs": "~7.8.2",
		"tslib": "^2.3.0",
		"uuid": "^11.1.0",
		"zone.js": "~0.15.0"
	},
	"devDependencies": {
		"@angular-devkit/build-angular": "^19.2.0",
		"@angular-eslint/builder": "19.2.0",
		"@angular-eslint/eslint-plugin": "19.2.0",
		"@angular-eslint/eslint-plugin-template": "19.2.0",
		"@angular-eslint/schematics": "19.2.0",
		"@angular-eslint/template-parser": "19.2.0",
		"@angular/cli": "~19.2.0",
		"@angular/compiler-cli": "^19.2.0",
		"@angular/localize": "^19.2.0",
		"@be.aca.platform/aca-npm-tools": "2.0.0",
		"@cyclonedx/cyclonedx-npm": "^2.0.0",
		"@types/jasmine": "^5.1.7",
		"@types/uuid": "^10.0.0",
		"@typescript-eslint/eslint-plugin": "^8.26.0",
		"@typescript-eslint/parser": "^8.26.0",
		"eslint": "^9.21.0",
		"eslint-plugin-import": "^2.29.1",
		"jasmine-core": "^5.6.0",
		"karma": "~6.4.3",
		"karma-chrome-launcher": "~3.2.0",
		"karma-coverage": "~2.2.1",
		"karma-jasmine": "~5.1.0",
		"karma-jasmine-html-reporter": "~2.1.0",
		"sync-package-pom": "1.0.1",
		"typescript": "~5.8.2"
	},
	"acaConfig": {
		"fixatedVersions": {
			"npm": "10.7.0",
			"node": "18.20.3"
		}
	}
}
