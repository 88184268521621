import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
	selector: 'app-validated-input',
	templateUrl: './validated-input.component.html',
	standalone: false
})
export class ValidatedInputComponent {

	@Input() id: string;
	@Input() type = 'text';
	@Input() class = 'form-control';
	@Input() placeholder: string;
	@Input() control: FormControl<any>;
	@Input() validationPrefix: string;
	@Input() label: string;
	@Input() name: string;
	@Input() displayInline = false;
	@Input() disabled = false;
	@Input() tooltip: string;

	hasErrors(): boolean {
		return this.control.errors !== null;
	}

	getErrors(): string[] {
		return Object.keys(this.control.errors).map(key => {
			return this.validationPrefix + key;
		});
	}

	getContainingDivClasses(): string {
		if (this.displayInline) {
			return 'mb-3 d-flex flex-row justify-content-between align-items-center';
		}
		return '';
	}

}
