<label class="form-label big">{{ 'production-catalogue.launch-history.overview.title' | translate }}</label><br>
<ng-container *ngIf="page$ | async as page">
	<ng-container *ngIf="page.totalElements > 0 else noLaunches">
		<app-table
			[id]="'launch-history-table'"
			[headers]="headers"
			[headerNamePrefix]="'production-catalogue.launch-history.overview.headers.'"
			[addButtonColumn]="true"
			viewHeight="">
			<tr *ngFor="let launch of page.content; let i = index">
				<td>{{ launch.createdDate | date: 'dd/MM/yyyy HH:mm zzzz' }}</td>
				<td>{{ launch.launchType ?? ''}}</td>
				<td>
					<span *ngIf="!launchArticleFailed(launch.launchArticles)"
						  class="badge list-group-item-secondary cursor-default me-2 mb-1"
						  [ngClass]="getBadgeStatusColor(launch.launchArticles)">
						{{ getLaunchArticleCounter(launch.launchArticles) }}</span>

					<ng-template #tooltipContent>
						<div>
							{{ 'production-catalogue.launch-history.overview.launch-error.title' | translate }}<br>
							{{ 'production-catalogue.launch-history.overview.launch-error.message' | translate : {launchArticleError: getNumberOfFailedLaunchArticles(launch.launchArticles)} }}
						</div>
					</ng-template>
					<span *ngIf="launchArticleFailed(launch.launchArticles)"
						  class="badge list-group-item-secondary cursor-default me-2 mb-1 badge-bg-red" id="tooltip"
						  [ngbTooltip]="tooltipContent"
						  placement="auto" tooltipClass="text-center" container="body">
						{{ getLaunchArticleCounter(launch.launchArticles) }}</span>
				</td>
				<td>{{ launch.createdBy }}</td>
				<td>
					<div class="d-flex justify-content-end align-items-center">
						<i id="view-launch-{{i}}" class="ti ti-eye me-2 fs-4 pointer" (click)="openViewModal(launch)"></i>
					</div>
				</td>
			</tr>
		</app-table>
		<app-pagination [page]="page" (pageEvent)="loadPage($event)"></app-pagination>
	</ng-container>
</ng-container>

<ng-template #noLaunches>
	<span class="empty-state-text mb-4">{{ 'production-catalogue.launch-history.overview.no-content' | translate }}</span>
</ng-template>


