"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const errors_1 = require("./errors");
const utils_1 = require("./utils");
class Cycle {
  constructor(serial) {
    this.serial = serial;
  }
  static fromIdentifier(identifier) {
    const match = utils_1.matchAiracIdentifier(identifier);
    if (match == null) {
      throw new errors_1.InvalidCycleIdentifierError(`Not a valid AIRAC cycle identifier: '${identifier}'`);
    }
    const yearPart = parseInt(match[1], 10);
    const ordinalPart = parseInt(match[2], 10);
    let year;
    if (yearPart > 79) {
      year = 1900 + yearPart;
    } else {
      year = 2000 + yearPart;
    }
    const lastCyclePreviousYear = Cycle.fromDate(new Date(Date.UTC(year - 1, 11, 31)));
    const cycle = new Cycle(lastCyclePreviousYear.serial + ordinalPart);
    if (cycle.effectiveStart.getFullYear() !== year) {
      throw new errors_1.InvalidCycleIdentifierError(`${year} doesn't have ${ordinalPart} cycles!`);
    }
    return cycle;
  }
  static fromDate(date) {
    const baseMillis = utils_1.base.getTime();
    const dateMillis = date.getTime();
    const cycles = Math.floor((dateMillis - baseMillis) / utils_1.millisPerCycle);
    return new Cycle(cycles);
  }
  get identifier() {
    const yearPart = (this.year % 100).toString().padStart(2, "0");
    const ordinalPart = this.ordinal.toString().padStart(2, "0");
    return `${yearPart}${ordinalPart}`;
  }
  get effectiveStart() {
    const millis = utils_1.base.getTime() + this.serial * utils_1.millisPerCycle;
    return new Date(millis);
  }
  get effectiveEnd() {
    const millis = this.effectiveStart.getTime() + utils_1.millisPerCycle - utils_1.millisPerDay;
    return new Date(millis);
  }
  get year() {
    return this.effectiveStart.getFullYear();
  }
  get ordinal() {
    const yearStartMillis = new Date(Date.UTC(this.effectiveStart.getFullYear(), 0, 1));
    const yearMillis = this.effectiveStart.getTime() - yearStartMillis.getTime();
    return Math.floor(yearMillis / utils_1.millisPerCycle) + 1;
  }
}
exports.Cycle = Cycle;
