import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Page} from './page';

@Component({
	selector: 'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.css'],
	standalone: false
})
export class PaginationComponent implements OnInit, OnChanges {

	@Input() page: Page<any>;
	@Input() rulerLength = 10;
	@Output() pageEvent: EventEmitter<number> = new EventEmitter<number>();
	pageNumbers: number[];
	currentPage: number;

	ngOnInit(): void {
		this.currentPage = this.page.pageable.pageNumber + 1;
		this.pageNumbers = Array(this.page.totalPages).fill(0).map((x, i) => i + 1);
	}

	ngOnChanges(): void {
		if (this.page.content.length === 0 && this.page.totalElements > 0 && this.currentPage !== 1) {
			this.loadPage(this.currentPage - 1);
		} else {
			this.currentPage = this.page.pageable.pageNumber + 1;
			this.pageNumbers = Array(this.page.totalPages).fill(0).map((x, i) => i + 1);
		}
	}

	loadPage(page: number): void {
		this.pageEvent.emit(page);
		this.currentPage = page;
	}

	getPages(): number[] {
		const halfLength = Math.ceil(this.rulerLength / 2);
		const length = halfLength * 2;
		if (this.page.totalPages <= this.rulerLength) {
			return Array(this.page.totalPages).fill(0).map((x, i) => i + 1);
		} else if (this.currentPage < halfLength) {
			const retVal = [];
			for (let i = 1; i < length; i++) {
				retVal.push(i);
			}
			return retVal;
		} else if (this.currentPage > this.page.totalPages - halfLength) {
			const retVal = [];
			for (let i = this.page.totalPages - length + 2; i <= this.page.totalPages; i++) {
				retVal.push(i);
			}
			return retVal;
		} else {
			const retVal = [];
			for (let i = this.currentPage - halfLength + 1; i < this.currentPage + halfLength; i++) {
				retVal.push(i);
			}
			return retVal;
		}
	}
}
