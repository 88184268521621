"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
class InvalidCycleIdentifierError extends Error {
  constructor(message) {
    super(message);
  }
}
exports.InvalidCycleIdentifierError = InvalidCycleIdentifierError;
