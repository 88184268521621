import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ModularFormControlInputWithLayout} from './modular-form-control-input-with-layout';

@Component({
	selector: 'app-modular-form-control-layout[parent]',
	templateUrl: './modular-form-control-layout.component.html',
	styleUrls: ['./modular-from-control-layout.component.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: false
})
export class ModularFormControlLayoutComponent {

	@Input() public parent: ModularFormControlInputWithLayout;

}
