<div class="d-flex flex-column p-5">

	<div class="d-flex mb-3">
		<h1>{{ 'source-radical-identifier.overview.title' | translate }}</h1>
	</div>

	<div *ngIf="page$ | async as page">
		<div *ngIf="page.totalElements > 0; else emptyState">
			<div class="d-inline-flex flex-row justify-content-end w-100 ">
				<button id="open-create-source-radical-identifier-modal" type="button" *permissionsAllowed="['CREATE_SOURCE_RADICAL_IDENTIFIERS']"
						class="btn btn-primary" (click)="openCreateModal()"><i
					class="ti ti-plus me-2 fs-4"></i>{{ 'source-radical-identifier.create-button' | translate }}
				</button>
			</div>
			<app-table [addButtonColumn]="true" [headers]="headers"
					   [headerNamePrefix]="'source-radical-identifier.overview.headers.'"
					   [id]="'source-radical-identifier-table'">
				<ng-container *ngIf="page.totalElements > 0 else noResults">
					<tr *ngFor="let sourceRadicalIdentifier of page.content; let i = index">
						<td class="w-25">{{ sourceRadicalIdentifier.name }}</td>
						<td>{{ sourceRadicalIdentifier.usualDesignation }}</td>
						<td><app-project-names [projects]="sourceRadicalIdentifier.projects"/></td>
						<td>
							<div class="d-flex justify-content-end align-items-center">
								<i id="edit-source-radical-identifier-{{i}}" *permissionsAllowed="['UPDATE_SOURCE_RADICAL_IDENTIFIERS']" class="ti ti-pencil float-end me-2 fs-4 pointer"
								   (click)="openUpdateModal(sourceRadicalIdentifier)"></i>
								<i id="delete-source-radical-identifier-{{i}}" *permissionsAllowed="['DELETE_SOURCE_RADICAL_IDENTIFIERS']" class="ti ti-trash float-end text-danger me-2 fs-4 pointer"
								   appConfirmationModal
								   (confirmAction)="deleteSourceRadicalIdentifier(sourceRadicalIdentifier)"
								   confirmationMessage="{{getConfirmationMessage()}}">
								</i>
							</div>
						</td>
					</tr>
				</ng-container>
			</app-table>
			<app-pagination [page]="page" (pageEvent)="loadPage($event)"></app-pagination>
		</div>
	</div>

</div>

<ng-template #noResults>
	<tr>
		<td colspan="100%">
			<p class="empty-state-text my-4 justify-content-center">{{ 'source-radical-identifier.overview.search.no-results' | translate }}</p>
		</td>
	</tr>
</ng-template>

<ng-template #emptyState>
	<div class="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
		<p class="empty-state-text mb-4">{{ 'source-radical-identifier.overview.no-content' | translate }}</p>
		<button class="btn btn-primary" (click)="openCreateModal()"><i
			class="ti ti-plus me-2 fs-4"></i>{{ 'source-radical-identifier.create-button' | translate }}
		</button>
	</div>
</ng-template>
