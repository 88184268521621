import {Component} from '@angular/core';
import {User} from '../_model/user';
import {UserService} from '../_service/user.service';
import {finalize, Observable, tap} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UpdateForm} from '../../shared/modal/update.form';
import {CreateOrUpdateUserRequest} from '../create.or.update.user.request';

@Component({
	selector: 'app-user-update-form',
	templateUrl: './user-form.modal.component.html',
	standalone: false
})
export class UserFormModalComponent implements UpdateForm<User> {

	user: Partial<User>;
	submitting = false;

	constructor(private activeModal: NgbActiveModal,
				private userService: UserService) {
	}

	setContent(content: User): void {
		this.user = content;
	}

	getTitleTranslationKey(): string {
		return this.user ? 'user.update.modal.title' : 'user.create.modal.title';
	}

	getSubmitTranslationKey(): string {
		return this.user ? 'general.save' : 'general.create';
	}

	submitForm(user: User): void {
		this.doSubmit(user).subscribe();
	}

	private doSubmit(user: User): Observable<User> {
		this.submitting = true;
		return this.submit({
			...user,
			uuid: this.user?.uuid,
			customerUuid: user.customer?.uuid
		})
			.pipe(
				tap(() => {
					this.submitting = false;
					this.activeModal.close(true);
				}),
				finalize(() => this.submitting = false)
			);
	}

	private submit(request: CreateOrUpdateUserRequest): Observable<User> {
		if (this.user) {
			return this.userService.update(request);
		}
		return this.userService.create(request);
	}
}
