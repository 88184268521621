import {Component} from '@angular/core';
import {ErrorResponse} from './error-response';
import {ErrorService} from './error.service';
import {Observable} from 'rxjs';

@Component({
	selector: 'app-invalid-feedback',
	templateUrl: './invalid-feedback.component.html',
	standalone: false
})

export class InvalidFeedbackComponent {
	error$: Observable<ErrorResponse>;

	constructor(public errorService: ErrorService) {
		this.error$ = this.errorService.onErrorChange();
	}

	getParams(error: ErrorResponse): TranslationParams {
		if (error.params) {
			const retVal: TranslationParams = {};
			for (let i = 0; i < error.params.length; i++) {
				retVal[i.toString()] = error.params[i];
			}
			return retVal;
		}
		return null;
	}

}

export class TranslationParams {
	[key: string]: any;
}
