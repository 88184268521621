import {SelectOption} from './select-option';
import {Program} from '../../../notices/model/program';
import {map, OperatorFunction} from 'rxjs';
import {GeoAreaMap} from '../../../article/_model/geo-area-map';
import {Role} from '../../../role/role';
import {Customer} from '../../../customer/_model/customer';
import {ArticleDescriptor} from '../../../article/_model/article-descriptor';
import {Project} from 'src/app/project/_model/project';
import {Folder} from '../../../notices/folder/folder';
import {SourceRadicalIdentifier} from '../../../sourceradicalidentifier/_model/source-radical-identifier';
import {DatasetOverview} from '../../../dataset/_model/dataset-overview';
import {LaunchType} from '../../../productioncatalogue/_model/launch-type';
import {FlightDatabase} from '../../../db/_model/flight-database';

export function mapPrograms(): OperatorFunction<Program[], SelectOption<Program>[]> {
	return map((programs: Program[]) => programs.map(program => ({
		value: program,
		label: program.name,
		id: program.uuid
	})));
}

export function mapGeoAreaTypes(): OperatorFunction<GeoAreaMap, SelectOption<string>[]> {
	return map(geoAreaMap => Object.keys(geoAreaMap).map((type: string) => ({
		id: type,
		label: `geo-area.${type}.label`,
		translate: true,
		value: type
	})));
}

export function mapGeoAreas(): OperatorFunction<GeoAreaMap, { [key: string]: SelectOption<string>[] }> {
	return map(geoAreaMap => {
		const result: { [key: string]: SelectOption<string>[] } = {};
		for (const key in geoAreaMap) {
			result[key] = geoAreaMap[key].map(area => ({
				id: area,
				label: `geo-area.${key}.${area}.label`,
				translate: true,
				value: area
			}));
		}
		return result;
	});
}

export function mapRoles(): OperatorFunction<Role[], SelectOption<Role>[]> {
	return map(roles => roles.map(role => {
		return ({
			value: role,
			label: role.name,
			id: role.uuid
		});
	}));
}

export function mapCustomers(): OperatorFunction<Customer[], SelectOption<Customer>[]> {
	return map(customers => customers.map(customer => ({
		value: customer,
		label: customer.name,
		id: customer.uuid
	})));
}

export function mapArticleDescriptors(): OperatorFunction<ArticleDescriptor[], SelectOption<ArticleDescriptor>[]> {
	return map(articles => mapArticles(articles));
}

export function mapArticles(articles: ArticleDescriptor[]): SelectOption<ArticleDescriptor>[] {
	return articles.map(article => ({
		value: article,
		label: article.reference + (article.usualDesignation ? ` (${article.usualDesignation})` : ''),
		id: article.uuid
	}));
}

export function mapArticleDescriptorUuids(): OperatorFunction<ArticleDescriptor[], SelectOption<string>[]> {
	return map(articles => mapArticleUuids(articles));
}

export function mapArticleUuids(articles: ArticleDescriptor[]): SelectOption<string>[] {
	return articles.map(article => ({
		value: article.uuid,
		label: article.reference + (article.usualDesignation ? ` (${article.usualDesignation})` : ''),
		id: article.uuid
	}));
}

export function mapProjects(): OperatorFunction<Project[], SelectOption<string>[]> {
	return map(projects => projects.map(project => ({
		value: project.uuid,
		label: project.name,
		id: project.uuid
	})));
}

export function mapFolders(): OperatorFunction<Folder[], SelectOption<string>[]> {
	return map(folders => folders.map(folder => ({
		id: folder.uuid,
		value: folder.uuid,
		label: folder.name
	})));
}

export function mapSourceRadicalIdentifiers(): OperatorFunction<SourceRadicalIdentifier[], SelectOption<SourceRadicalIdentifier>[]> {
	return map(sourceRadicalIdentifiers => sourceRadicalIdentifiers.map(sourceRadicalIdentifier => ({
		id: sourceRadicalIdentifier.uuid,
		value: sourceRadicalIdentifier,
		label: sourceRadicalIdentifier.name
	})));
}

export function mapDatasetToSelectOption(): OperatorFunction<DatasetOverview[], SelectOption<DatasetOverview>[]> {
	return map(datasets => datasets.map(dataset => ({
		id: dataset.uuid,
		value: dataset,
		label: dataset.identifier
	})));
}

export function mapDatabaseToSelectOption(): OperatorFunction<FlightDatabase[], SelectOption<FlightDatabase>[]> {
	return map(flightDatabases => flightDatabases.map(database => ({
		id: database.uuid,
		value: database,
		label: database.databaseName + ' - ' + database.usualDesignation
	})));
}

export function mapStatusToSelectOption(status: string, prefix: string): SelectOption<string> {
	return {
		value: status,
		label: prefix + status,
		id: status,
		translate: true
	};
}

export function datasetFileTypeOptions(): SelectOption<string>[] {
	return [
		{id: 'SOURCE_FILE', value: 'SOURCE_FILE', label: 'dataset.upload.form.files.fileType.options.source_file', translate: true},
		{id: 'SOC', value: 'SOC', label: 'dataset.upload.form.files.fileType.options.soc', translate: true},
		{id: 'EXCLUDE', value: 'EXCLUDE', label: 'dataset.upload.form.files.fileType.options.exclude', translate: true},
		{id: 'OTHER', value: 'OTHER', label: 'dataset.upload.form.files.fileType.options.other', translate: true}
	];

}

export function rnpArOptions(): SelectOption<string>[] {
	return [
		{id: 'NO', value: 'NO', label: 'article.form.rnpAr.options.no', translate: true},
		{id: 'ZERO_THREE_ZERO_THREE', value: 'ZERO_THREE_ZERO_THREE', label: 'article.form.rnpAr.options.0_3_0_3', translate: true},
		{id: 'ZERO_THREE_ONE', value: 'ZERO_THREE_ONE', label: 'article.form.rnpAr.options.0_3_1', translate: true}
	];
}

export function dspOptions(): SelectOption<string>[] {
	return [
		{id: 'JEP', value: 'JEP', label: 'article.form.dsp.options.jep', translate: true},
		{id: 'LSY', value: 'LSY', label: 'article.form.dsp.options.lsy', translate: true},
		{id: 'NVB', value: 'NVB', label: 'article.form.dsp.options.nvb', translate: true}
	];
}

export function exportControlOptions(): SelectOption<string>[] {
	return [
		{id: 'NOT_CONTROLLED', value: 'NOT_CONTROLLED', label: 'article.form.exportControl.options.not_controlled', translate: true},
		{id: 'EAR99', value: 'EAR99', label: 'article.form.exportControl.options.ear99', translate: true},
		{id: 'EXPORT_CONTROLLED', value: 'EXPORT_CONTROLLED', label: 'article.form.exportControl.options.export_controlled', translate: true}
	];
}

const LAUNCH_OPTIONS_MAP: SelectOption<LaunchType>[] = [
	{
		id: 'TEST',
		value: LaunchType.TEST,
		label: 'production-catalogue.launch.type.test',
		translate: true
	},
	{
		id: 'PREPROD',
		value: LaunchType.PRE_PROD,
		label: 'production-catalogue.launch.type.preprod',
		translate: true
	},
	{
		id: 'PROD',
		value: LaunchType.PROD,
		label: 'production-catalogue.launch.type.prod',
		translate: true
	}
];

export function launchTypeOptions(permissions: string[]): SelectOption<LaunchType>[] {
	const permissionSet = new Set(permissions);
	const availableOptions = new Set<SelectOption<LaunchType>>();
	const hasPermission = (permission: string): boolean => permissionSet.has(permission);

	if (hasPermission('LAUNCH_PRODUCTION_CATALOGUES_TEST')) {
		availableOptions.add(LAUNCH_OPTIONS_MAP[0]);
	}

	if (hasPermission('LAUNCH_PRODUCTION_CATALOGUES_ONLY_PRE_PRD')) {
		availableOptions.add(LAUNCH_OPTIONS_MAP[1]);
	}

	if (hasPermission('LAUNCH_PRODUCTION_CATALOGUES_PRD')) {
		availableOptions.add(LAUNCH_OPTIONS_MAP[2]);
	}
	return LAUNCH_OPTIONS_MAP.filter(option => availableOptions.has(option));
}

