import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {ProductionCatalogue} from '../../_model/production-catalogue';
import {Comment} from '../../_model/comment';
import {UserService} from '../../../user/_service/user.service';
import {formatDate} from '@angular/common';
import {ProductionCatalogueService} from '../../_service/production-catalogue.service';
import {ModularFormComponent} from '../../../shared/modular-forms/modular-form/modular-form.component';
import {User} from '../../../user/_model/user';

@Component({
	selector: 'app-comment-form',
	templateUrl: './comment-form.component.html',
	styleUrls: ['./comment-form.component.css'],
	exportAs: 'commentForm',
	standalone: false
})
export class CommentFormComponent extends ModularFormComponent implements OnInit, OnDestroy {

	@Input() productionCatalogue?: ProductionCatalogue;
	@Input() launch: boolean = false;

	isAddingComment$ = new BehaviorSubject(false);
	comments$ = new BehaviorSubject<Comment[]>([]);
	user$: Observable<User>;

	editingComment?: Comment | null = null;

	private subscription = new Subscription();

	constructor(private userService: UserService,
				private productionCatalogueService: ProductionCatalogueService) {
		super('production-catalogue');

		this.user$ = this.userService.getCurrentUser();

		this.subscription.add(this.userService.getCurrentUser().subscribe(user => {
			this.form.addControl('comment', new FormControl('', [Validators.required, Validators.minLength(1)]));
			this.form.addControl('date', new FormControl(null, Validators.required));
			this.form.addControl('user', new FormControl(user, Validators.required));
		}));
	}

	ngOnInit(): void {
		if (this.productionCatalogue) {
			this.comments$.next(this.productionCatalogue.comments);
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	save(): void {
		const comment = {
			comment: this.form.get('comment').value,
			timestamp: this.form.get('date').value,
			user: this.form.get('user').value
		} as Comment;

		if (this.editingComment) {
			this.editingComment.comment = comment.comment;
			this.editingComment.timestamp = formatDate(Date.now(), 'dd/MM/yyyy HH:mm', 'en');

			this.productionCatalogueService.createOrUpdateComment(this.productionCatalogue.uuid, {
				userUuid: comment.user.uuid,
				comment: comment.comment,
				commentId: this.editingComment.commentId,
				date: this.editingComment.timestamp
			}).subscribe(() => {
				this.isAddingComment$.next(false);
				this.comments$.next(this.comments$.value);
				this.productionCatalogueService.get(this.productionCatalogue.uuid)
					.subscribe((updatedCatalogue: ProductionCatalogue) => {
						this.comments$.next(updatedCatalogue.comments);
					});
			});
		} else {
			this.comments$.value.splice(0, 0, comment);
			this.comments$.next(this.comments$.value);
			this.productionCatalogueService.createOrUpdateComment(this.productionCatalogue.uuid, {
				userUuid: comment.user.uuid,
				comment: comment.comment,
				date: comment.timestamp
			}).subscribe(() => {
				if (this.productionCatalogue) {
					this.productionCatalogueService.get(this.productionCatalogue.uuid)
						.subscribe((updatedCatalogue: ProductionCatalogue) => {
							this.comments$.next(updatedCatalogue.comments);
							this.isAddingComment$.next(false);
						});
				}
			});
		}

		this.editingComment = null;
	}

	cancel(): void {
		this.isAddingComment$.next(false);
		this.editingComment = null;
	}

	addComment(): void {
		this.form.get('date').setValue(formatDate(Date.now(), 'dd/MM/yyyy HH:mm', 'en'));
		this.form.get('comment').setValue('');
		this.isAddingComment$.next(true);
		this.editingComment = null;
	}

	editComment(index: number): void {
		const comment = this.comments$.value[index];
		this.form.get('date').setValue(comment.timestamp);
		this.form.get('comment').setValue(comment.comment);
		this.isAddingComment$.next(true);
		this.editingComment = comment;
	}

	deleteComment(commentId: string): void {
		this.user$.subscribe(() => {
			this.productionCatalogueService.deleteComment(this.productionCatalogue.uuid, commentId)
				.subscribe(() => {
					const updatedComments = this.comments$.value.filter(comment => comment.commentId !== commentId);
					this.comments$.next(updatedComments);
				});
		});
	}

	isValid(): boolean {
		return this.form.valid;
	}

	getCommentCount(): number {
		return this.comments$.value.length;
	}
}
