import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, map, Observable, startWith, Subject, Subscription, switchMap} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ArticleService} from '../_service/article.service';
import {Page} from '../../shared/pagination/page';
import {Header} from '../../shared/table/header';
import {ArticleOverview} from '../_model/article-overview';
import {HttpResponse} from '@angular/common/http';
import {ContentArray} from '../../shared/model/content-array';
import {DropdownData} from '../../shared/dropdown-multiselect/dropdown-data';
import {DropdownDataFactory} from '../../shared/dropdown-multiselect/dropdown-data.factory';
import {ProgramDescriptor} from '../../notices/program/program-descriptor';
import {FlightManagementSystemService} from '../../flight-management-system/_service/flight-management-system.service';
import {Project} from '../../project/_model/project';
import {Article} from '../_model/article';
import {ActivatedRoute, Router} from '@angular/router';
import {PermissionService} from '../../shared/permission/permission.service';
import {GuardDutyFileScanStatus} from '../../shared/model/guard-duty-file-scan-status';
import {DeleteObserverFactory} from '../../shared/delete/delete-observer-factory';

@Component({
	selector: 'app-article-overview',
	templateUrl: './article-overview.component.html',
	styleUrls: ['./article-overview.component.css'],
	standalone: false
})
export class ArticleOverviewComponent implements OnInit, OnDestroy {
	page$: Observable<Page<ArticleOverview>>;
	headers: Header[] = [
		{name: 'reference'},
		{name: 'databaseRadicalIdentifier'},
		{name: 'usualDesignation'},
		{name: 'type'},
		{name: 'program'},
		{name: 'offset', permissions: ['VIEW_ARTICLE_PARAMETERS']},
		{name: 'linkedDatabases', permissions: ['VIEW_ARTICLE_PARAMETERS']},
		{name: 'subscribedCustomers', permissions: ['VIEW_ARTICLE_PARAMETERS']},
		{name: 'lastUploaded', permissions: ['VIEW_ARTICLE_PRODUCTION_PARAMETERS']},
		{name: 'flightManagementSoftwareReference', permissions: ['VIEW_ARTICLE_PRODUCTION_PARAMETERS']},
		{name: 'projects', permissions: ['VIEW_ARTICLE_PRODUCTION_PARAMETERS']},
		{name: 'productionStatus', permissions: ['VIEW_ARTICLE_PRODUCTION_PARAMETERS']}
	];

	article$: Observable<Article>;
	subscription = new Subscription();
	private refresh$ = new Subject<void>();
	private currentPage$ = new Subject<number>();
	articlesWithMissingProperties$ = new Observable<number>();
	selectedFilterCriteria = {} as ContentArray;
	searchValue = '';
	filterDropdownData$: Observable<DropdownData[]>;
	articleUuid: string | null = null;
	isCreating: boolean = false;
	isViewing: boolean = false;
	isEditing: boolean = false;
	isFmsFileCorrupted: boolean = false;
	approvalUser$ = new Observable<boolean>();

	constructor(private articleService: ArticleService,
				private translateService: TranslateService,
				private dropdownDataFactory: DropdownDataFactory,
				private flightManagementSystemService: FlightManagementSystemService,
				private permissionService: PermissionService,
				private route: ActivatedRoute,
				private router: Router,
				private deleteObserverFactory: DeleteObserverFactory) {
	}

	ngOnInit(): void {
		const state = window.history.state as { mode?: string; readonly?: boolean };

		if (state) {
			this.isCreating = state.mode === 'create';
			this.isViewing = state.mode === 'detail' && state.readonly === true;
			this.isEditing = state.mode === 'detail' && state.readonly === false;

			this.route.params.subscribe((params): void => {
				this.articleUuid = params['uuid'];
				if (this.articleUuid) {
					this.article$ = this.articleService.getArticle(this.articleUuid);
				}
			});
		}

		this.page$ = combineLatest([this.currentPage$.pipe(startWith(1)), this.refresh$.pipe(startWith(''))])
			.pipe(
				switchMap(([currentPage]) => this.articleService.search(currentPage - 1, this.selectedFilterCriteria, this.searchValue))
			);

		this.articlesWithMissingProperties$ = this.refresh$.pipe(
			startWith(''),
			switchMap(() => this.articleService.getNrOfArticlesWithMissingProperties())
		);

		this.filterDropdownData$ = this.refresh$.pipe(
			startWith(''),
			switchMap(() => this.articleService.getFilterCriteria()),
			map(filterCriteria => filterCriteria.map(criteria => this.dropdownDataFactory.createDropdownData(criteria)))
		);

		this.approvalUser$ = this.permissionService.hasAtLeastOnePermission(['REQUEST_ARTICLE_APPROVAL']);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	loadPage(page: number): void {
		this.currentPage$.next(page);
	}

	openCreatePage(): void {
		this.router.navigate(['/articles/create'], {
			state: {mode: 'create'}
		});
	}

	openViewPage(uuid: string): void {
		this.router.navigate(['/articles/detail', uuid], {
			state: {mode: 'detail', readonly: true}
		});
	}

	openEditPage(uuid: string): void {
		this.router.navigate(['/articles/detail', uuid], {
			state: {mode: 'detail', readonly: false}
		});
	}

	deleteArticle(article: ArticleOverview): void {
		this.subscription.add(
			this.articleService.delete(article.uuid)
				.subscribe(this.deleteObserverFactory.createFactory('article.delete.success-message', this.refresh$))
		);
	}

	getConfirmationMessage(): string {
		return `<p class="mb-3">${this.translateService.instant('article.delete.confirmation-message.dbs-will-be-deleted')}</p>`
			+ `<p>${this.translateService.instant('article.delete.confirmation-message.are-you-sure')}</p>`;
	}

	doesArticleMissProperties(article: ArticleOverview): boolean {
		return article.missingProperties;
	}

	getExportFunction(): Observable<HttpResponse<Blob>> {
		return this.articleService.export(this.selectedFilterCriteria, this.searchValue);
	}

	getProgramsCommaSeperated(programs: ProgramDescriptor[]): string {
		return programs.map(p => p.name).join(', ');
	}

	getProjectsCommaSeperated(projects: Project[]): string {
		return projects?.map(p => p.name).join(', ');
	}

	search(searchValue: any): void {
		this.searchValue = searchValue;
		this.loadPage(1);
	}

	filtersSelected(event: ContentArray): void {
		this.selectedFilterCriteria = {};
		Object.keys(event).forEach(key => this.selectedFilterCriteria[key] = event[key]);
		this.loadPage(1);
	}

	hasSearchCriteria(): boolean {
		return !!this.searchValue || Object.keys(this.selectedFilterCriteria).length > 0;
	}

	downloadFMSConfig(): void {
		this.flightManagementSystemService.download().subscribe(file => {
			const link = document.createElement('a');
			link.href = `data:application/octet-stream;base64,${file.data}`;
			link.download = file.fileName;
			link.click();
			document.removeChild(link);
		});
	}

	getFmsMalwareStatus(): () => Observable<GuardDutyFileScanStatus> {
		return () => this.flightManagementSystemService.getFileStatus();
	}

	onFmsMalwareStatusUpdate(isCorrupted: boolean): void {
		this.isFmsFileCorrupted = isCorrupted;
	}

	protected readonly Article = Article;
}
