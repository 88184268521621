import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {UploadProgress} from './upload.progress';
import {UploadProgressStatus} from './upload.progress.status';
import {TranslateService} from '@ngx-translate/core';
import {GeneralUploadProgress} from './general.upload.progress';
import {ErrorResponse} from '../error-handling/error-response';

@Component({
	selector: 'app-file-upload-progress[translatePrefix]',
	templateUrl: './upload-file-progress-component.html',
	styleUrls: ['./upload-file-progress-component.css'],
	standalone: false
})
export class UploadFileProgressComponent {

	readonly STATUS = UploadProgressStatus;

	@Input() translatePrefix: string;

	@Input() progresses: Subject<UploadProgress>[];

	@Input() generalProgress: Subject<GeneralUploadProgress>;

	@Output() closeEvent = new EventEmitter<void>();

	constructor(public translateService: TranslateService) {
	}

	getProgressLabel(progress: UploadProgress): string {
		if (progress.status == UploadProgressStatus.UPLOADING) {
			return this.translateService.instant('general.uploading') + ' ' + progress.getUploadedSizeLabel() + '/' + progress.getTotalSizeLabel();
		} else if (progress.status === UploadProgressStatus.WAITING) {
			return this.translateService.instant('general.waiting-to-upload');
		} else if (progress.status === UploadProgressStatus.FAILED) {
			return this.translateService.instant('general.upload-failed');
		} else if (progress.status === UploadProgressStatus.CANCELLED) {
			return this.translateService.instant('general.upload-cancelled');
		}
		return '';
	}

	getFileIcon(progress: UploadProgress): string {
		if (progress.status === UploadProgressStatus.UPLOADING) {
			return 'ti-file-upload';
		} else if (progress.status === UploadProgressStatus.FAILED) {
			return 'ti-file-x';
		} else if (progress.status === UploadProgressStatus.DONE) {
			return 'ti-file-check';
		} else if (progress.status === UploadProgressStatus.CANCELLED) {
			return 'ti-file-off';
		}
		return 'ti-file-time';
	}

	getParams(error: ErrorResponse): TranslationParams {
		if (error.params) {
			const retVal: TranslationParams = {};
			for (let i = 1; i <= error.params.length; i++) {
				retVal[i.toString()] = error.params[i - 1];
			}
			return retVal;
		}
		return null;
	}

	close(): void {
		this.closeEvent.emit();
	}

	translate(key: string): string {
		return this.translateService.instant(this.translatePrefix + '.' + key);
	}
}

export class TranslationParams {
	[key: string]: any;
}
