import {NgModule} from '@angular/core';
import {MalwareStatusComponent} from './malware-status.component';
import {BrowserModule} from '@angular/platform-browser';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';

@NgModule({
	declarations: [
		MalwareStatusComponent
	],
	imports: [
		CommonModule,
		BrowserModule,
		TranslateModule,
		NgbTooltip
	],
	exports: [
		MalwareStatusComponent
	]
})
export class MalwareStatusModule {
}
