import {Component} from '@angular/core';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	standalone: false
})
export class ContactComponent {

}
