import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpBackend, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {CommonModule, registerLocaleData} from '@angular/common';
import {AuthConfigModule} from './auth/auth-config.module';
import {PermissionsModule} from './shared/permission/permissions.module';
import {ToastrMessageModule} from './shared/toastr/toastr.message.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbDateAdapter, NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TableModule} from './shared/table/table.module';
import {CustomerModule} from './customer/customer.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {SidebarModule} from './sidebar/sidebar.module';
import {RouterModule} from '@angular/router';
import {UserModule} from './user/user.module';
import {AuthModule} from './auth/auth.module';
import {ErrorHandlingModule} from './shared/error-handling/error-handling.module';
import {DbModule} from './db/db.module';
import {CustomDateParserFormatter} from './shared/datepicker/custom-date-parser-formatter';
import {CustomNgbDateAdapter} from './shared/datepicker/custom-ngb-date-adapter';
import {NoticeModule} from './notices/notice.module';
import {ArticleModule} from './article/article.module';
import {ContactModule} from './contact/contact.module';
import {ModularFormsModule} from './shared/modular-forms/modular-forms.module';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {FlightManagementSystemModule} from './flight-management-system/flight-management-system.module';
import {ProjectModule} from './project/project.module';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import {environment} from '../environments/environment';
import {ProductionCatalogueModule} from './productioncatalogue/production-catalogue.module';
import locale from '@angular/common/locales/nl-BE';
import {JobModule} from './job/job.module';
import {DatasetModule} from './dataset/dataset.module';
import {SourceRadicalIdentifierModule} from './sourceradicalidentifier/source-radical-identifier.module';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {DeleteModule} from './shared/delete/delete.module';

registerLocaleData(locale);

@NgModule({
	declarations: [
		AppComponent
	],
	bootstrap: [AppComponent], imports: [BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		CommonModule,
		AuthConfigModule,
		PermissionsModule,
		TableModule,
		CustomerModule,
		UserModule,
		JobModule,
		NoticeModule,
		TranslateModule.forRoot({
			defaultLanguage: 'en',
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpBackend]
			}
		}),
		SidebarModule,
		RouterModule,
		AuthModule,
		BrowserAnimationsModule,
		NgbModule,
		ToastrMessageModule,
		ErrorHandlingModule,
		DbModule,
		ArticleModule,
		ContactModule,
		ProductionCatalogueModule,
		ModularFormsModule,
		NgMultiSelectDropDownModule.forRoot(),
		FlightManagementSystemModule,
		ProjectModule,
		SourceRadicalIdentifierModule,
		DatasetModule,
		NgxGoogleAnalyticsModule.forRoot(environment.analytics.measurement_id),
		NgxGoogleAnalyticsRouterModule,
		DeleteModule
	],
	providers: [
		{provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
		{provide: NgbDateAdapter, useClass: CustomNgbDateAdapter},
		{provide: LOCALE_ID, useValue: 'en-GB'},
		provideHttpClient(withInterceptorsFromDi())
	]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpBackend): MultiTranslateHttpLoader {
	return new MultiTranslateHttpLoader(http, [
		'./assets/i18n/article/',
		'./assets/i18n/common/',
		'./assets/i18n/contact/',
		'./assets/i18n/customer/',
		'./assets/i18n/dataset/',
		'./assets/i18n/db/',
		'./assets/i18n/fms/',
		'./assets/i18n/folder/',
		'./assets/i18n/job/',
		'./assets/i18n/notice/',
		'./assets/i18n/production-catalogue/',
		'./assets/i18n/program/',
		'./assets/i18n/project/',
		'./assets/i18n/source-radical-identifier/',
		'./assets/i18n/user/',
		'./assets/i18n/malware/'
	]);
}
