import {Component, Input, OnInit} from '@angular/core';
import {Project} from '../_model/project';
import {UserService} from '../../user/_service/user.service';
import {combineLatest, map, Observable} from 'rxjs';

@Component({
	selector: 'app-project-names',
	templateUrl: './project-names.component.html',
	standalone: false
})
export class ProjectNamesComponent implements OnInit {
	@Input() projects: Project[] = [];
	projectsCommaSeparated$: Observable<string>;

	constructor(private userService: UserService) {
	}

	ngOnInit(): void {
		this.projectsCommaSeparated$ = this.getProjectsCommaSeparated(this.projects);
	}

	getProjectsCommaSeparated(projects: Project[]): Observable<string> {
		return combineLatest([
			this.userService.getCurrentUser(),
			this.userService.getPermissionsForCurrentUser()
		]).pipe(
			map(([user, permissions]) => {
				let retVal = projects;
				if (!permissions.includes('VIEW_ALL_PROJECTS')) {
					const userProjectUuids = user.projects.map(project => project.uuid);
					retVal = projects?.filter(project => userProjectUuids.includes(project.uuid));
				}
				return retVal.map(p => p.name).join(', ');
			})
		);
	}
}
