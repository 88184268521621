import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../../../shared/validators/custom-validators';
import {UploadFormResult} from '../../upload-base/upload-form-result';
import {CreateFlightDatabaseRequest} from '../../../_model/create-flight-database-request';
import {Observable, Subscription} from 'rxjs';
import {ModularFormComponent} from '../../../../shared/modular-forms/modular-form/modular-form.component';

@Component({
	selector: 'app-db-upload-db-form',
	templateUrl: './upload-db-form.component.html',
	standalone: false
})
export class UploadDbFormComponent extends ModularFormComponent implements OnInit, OnDestroy {

	@Input() reset$: Observable<void>;

	@Output() public submitForm = new EventEmitter<UploadFormResult>();

	private subscription = new Subscription();

	constructor() {
		super('db.upload');

		this.form.addControl('actualFiles', new FormControl([], [CustomValidators.requireNonEmptyArray]));
	}

	ngOnInit(): void {
		this.subscription.add(this.reset$.subscribe(() => this.form.reset()));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public createFileFormGroup(file: File): FormGroup {
		const fileNameControl = new FormControl(file.name, [Validators.required]);
		fileNameControl.disable();

		return new FormGroup({
			fileName: fileNameControl,
			hash: new FormControl('', [Validators.required, Validators.maxLength(255)]),
			description: new FormControl('', [Validators.required, Validators.maxLength(255)])
		});
	}

	public submit(): void {
		this.submitForm.emit({
			request: this.createRequest(),
			selectedFiles: this.form.get('actualFiles').value
		});
	}

	createRequest(): CreateFlightDatabaseRequest {
		const request = {...this.form.getRawValue()};
		delete request['actualFiles'];
		return request;
	}
}
