<div class="d-flex flex-column p-5">
	<div *ngIf="productionCatalogue$ | async as productionCatalogue">
		<div class="d-flex bd-highlight mb-3">
			<div><h1>{{ 'production-catalogue.launch.title' | translate }}</h1></div>
			<div class="ms-auto mt-4">
				<div class="d-flex justify-content-end gap-3">
					<button id="close" type="button" class="btn btn-secondary" (click)="closePage()">{{ "general.go-back" | translate }}
					</button>
					<button id="launch-catalogue" type="button" class="btn btn-primary"
							(click)="submitForm(form.form)"
							[disabled]="checkLaunchAndValidity(form.form)">
						<span>{{ 'general.launch' | translate }}</span>
					</button>
				</div>
			</div>
		</div>
        <div *ngIf="errorResponse || isLaunching" class="alert"
             [ngClass]="{
					'alert-danger': errorResponse,
					'alert-warning': isLaunching
			     }"
             role="alert">
            <div *ngIf="errorResponse" class="flex-column">
                {{ errorResponse.error.errorCode | translate }}
                <ul>
                    <li *ngFor="let articleReferenceRequestStatus of getArticleReferenceRequestStatuses()">
                        {{ 'error.article.reference' | translate }} : {{ articleReferenceRequestStatus.articleReference }} -
                        {{ 'error.' + getTranslationKeyForRequestStatus(articleReferenceRequestStatus.requestStatus) | translate }}
                    </li>
                </ul>
            </div>
            <div *ngIf="isLaunching" class="flex-column">
                {{ 'production-catalogue.launch.processing-alert' | translate }}
            </div>
        </div>
		<app-production-catalogue-form #form="productioncatalogueForm"
									   [productionCatalogue]="productionCatalogue"
									   [copy]="false"
									   [launch]="true">
		</app-production-catalogue-form>
	</div>
</div>
