import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import { HttpClient, HttpEvent } from '@angular/common/http';
import {Observable} from 'rxjs';
import {FlightManagementSystem} from '../_model/flight-management-system';
import {StoredFile} from '../../db/_model/stored-file';
import {GuardDutyFileScanStatus} from '../../shared/model/guard-duty-file-scan-status';

@Injectable()
export class FlightManagementSystemService {

	readonly baseUrl = environment.backend_url + '/fms';

	constructor(private http: HttpClient) {
	}

	getAll(): Observable<FlightManagementSystem[]> {
		return this.http.get<FlightManagementSystem[]>(this.baseUrl);
	}

	upload(file: File): Observable<HttpEvent<any>> {
		const formData = new FormData();
		formData.append('file', file);

		return this.http.post(`${this.baseUrl}/upload`, formData, {
			reportProgress: true,
			observe: 'events'
		});
	}

	download(): Observable<StoredFile> {
		return this.http.get<StoredFile>(`${this.baseUrl}/download`);
	}

	getFileStatus(): Observable<GuardDutyFileScanStatus> {
		return  this.http.get<GuardDutyFileScanStatus>(`${this.baseUrl}/file/status`);
	}
}
