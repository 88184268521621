import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Customer} from '../_model/customer';
import {FormArray, FormControl, Validators} from '@angular/forms';
import {ProgramService} from '../../notices/_service/program.service';
import {Observable, Subscription} from 'rxjs';
import {SelectOption} from '../../shared/modular-forms/_model/select-option';
import {mapPrograms} from '../../shared/modular-forms/_model/select-option.factory';
import {CreateOrUpdateCustomerRequest} from '../_model/create-or-update-customer.request';
import {ModularFormComponent} from '../../shared/modular-forms/modular-form/modular-form.component';
import {Program} from '../../notices/model/program';

@Component({
	selector: 'app-customer-form',
	templateUrl: './customer-form.component.html',
	exportAs: 'customerForm',
	standalone: false
})
export class CustomerFormComponent extends ModularFormComponent implements OnInit, OnDestroy {

	@Input()
	public customer: Customer;

	programs$: Observable<SelectOption<Program>[]>;

	private subscription = new Subscription();

	constructor(private programService: ProgramService) {
		super('customer');
		this.programs$ = this.programService.findAll().pipe(mapPrograms());

		this.form.addControl('name', new FormControl('', [Validators.required, Validators.maxLength(100)]));
		this.form.addControl('icao', new FormControl('', [Validators.maxLength(255)]));
		this.form.addControl('distributionSubscriptions', new FormArray([]));
		this.form.addControl('viewerSubscriptions', new FormArray([]));
		this.form.addControl('programs', new FormControl([]));
		this.form.addControl('suspended', new FormControl(false));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	ngOnInit(): void {
		if (this.customer) {
			this.form.patchValue(this.customer);

			this.subscription.add(this.programs$
				.subscribe(programs => {
					const value = this.customer.programs
						.map(p => p.uuid)
						.map(puuid => programs.find(option => option.id === puuid));
					this.form.get('programs').patchValue(value);
				}));
		}
	}

	getCreateOrUpdateRequest(): CreateOrUpdateCustomerRequest {
		const programUuids = this.form.get('programs').getRawValue().map((option: SelectOption<Program>) => option.id);
		return {
			name: this.form.get('name').getRawValue(),
			icao: this.form.get('icao').getRawValue(),
			suspended: this.form.get('suspended').getRawValue(),
			programUuids: programUuids,
			distributionSubscriptions: this.form.get('distributionSubscriptions').getRawValue(),
			viewerSubscriptions: this.form.get('viewerSubscriptions').getRawValue()
		};
	}

	getFormArray(name: string): FormArray {
		return this.form.get(name) as FormArray;
	}
}
