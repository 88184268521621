export enum RequestStatus {
	ACCEPTED = 'ACCEPTED',
	REJECTED_MISSING_SOCDAT1 = 'REJECTED_MISSING_SOCDAT1',
	REJECTED_INCORRECT_SOURCE_HASH = 'REJECTED_INCORRECT_SOURCE_HASH',
	REJECTED_INCORRECT_SOCDAT1_HASH = 'REJECTED_INCORRECT_SOCDAT1_HASH',
	REJECTED_INCORRECT_EXCLUDE_HASH = 'REJECTED_INCORRECT_EXCLUDE_HASH',
	REJECTED_SOURCE_NOT_FOUND = 'REJECTED_SOURCE_NOT_FOUND',
	REJECTED_SOCDAT1_NOT_FOUND = 'REJECTED_SOCDAT1_NOT_FOUND',
	REJECTED_EXCLUDE_NOT_FOUND = 'REJECTED_EXCLUDE_NOT_FOUND',
	REJECTED_ALREADY_IN_PROGRESS = 'REJECTED_ALREADY_IN_PROGRESS',
	REJECTED_ALREADY_SUCCESSFUL = 'REJECTED_ALREADY_SUCCESSFUL'
}
