import {ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {combineLatest, distinctUntilChanged, map, Observable, shareReplay, Subscription} from 'rxjs';
import {SelectOption} from '../../shared/modular-forms/_model/select-option';
import {CustomerService} from '../../customer/_service/customer.service';
import {FlightDatabaseService} from '../../db/_service/flight-database.service';
import {ProgramService} from '../../notices/_service/program.service';
import {PermissionService} from '../../shared/permission/permission.service';
import {FlightManagementSystemService} from '../../flight-management-system/_service/flight-management-system.service';
import {FlightManagementSystem} from '../../flight-management-system/_model/flight-management-system';
import {GeoAreaService} from '../_service/geo-area.service';
import {
	dspOptions,
	exportControlOptions,
	mapGeoAreas,
	mapGeoAreaTypes,
	mapPrograms,
	mapProjects,
	mapSourceRadicalIdentifiers,
	rnpArOptions
} from '../../shared/modular-forms/_model/select-option.factory';
import {Article} from '../_model/article';
import {ProjectService} from '../../project/_service/project.service';
import {CreateOrUpdateArticleRequest} from '../_model/create-or-update-article-request';
import {SourceRadicalIdentifierService} from '../../sourceradicalidentifier/_service/source-radical-identifier.service';
import {NamedFormGroup} from '../../shared/modular-forms/named-form-group';
import {GeographicalLocation} from '../_model/geographical-location';
import {CustomValidators} from '../../shared/validators/custom-validators';
import {ICAOService} from '../../icao/_service/icao.service';
import {ICAOCountry} from '../../icao/_model/icao-country';
import {Program} from '../../notices/model/program';
import {SourceRadicalIdentifier} from '../../sourceradicalidentifier/_model/source-radical-identifier';
import {Project} from '../../project/_model/project';

class Visible {
	[id: string]: boolean
}

@Component({
	selector: 'app-article-form',
	templateUrl: './article-form.component.html',
	exportAs: 'articleForm',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false
})
export class ArticleFormComponent implements OnInit, OnChanges, OnDestroy {

	public readonly GEOMETRIC_AREA = 'GEOMETRIC_AREA';
	public readonly REGIONAL_SOURCE = 'REGIONAL_SOURCE';
	public readonly REGIONAL_AREA = 'REGIONAL_AREA';
	public readonly COUNTRIES = 'COUNTRIES';
	private readonly REGIONAL = 'REGIONAL';
	private readonly WORLDWIDE = 'WORLDWIDE';

	@Input()
	public article: Article;

	@Input()
	public readonly = false;

	programs$: Observable<SelectOption<Program>[]>;
	flightManagementSystems$: Observable<SelectOption<FlightManagementSystem>[]>;
	geoAreasTypes$: Observable<SelectOption<string>[]>;
	geoAreas$: Observable<{ [key: string]: SelectOption<string>[] }>;
	projects$: Observable<SelectOption<string>[]>;
	sourceRadicalIdentifiers$: Observable<SelectOption<SourceRadicalIdentifier>[]>;

	approvalRequestUser$: Observable<boolean>;
	approvalUser$: Observable<boolean>;
	canRequestImmediately$: Observable<boolean>;
	visible = new Visible();

	dsps: SelectOption<string>[];
	rnpArs: SelectOption<string>[];
	exportControls: SelectOption<string>[];
	form: FormGroup;

	private subscription = new Subscription();

	constructor(private programService: ProgramService,
				private customerService: CustomerService,
				private flightDatabaseService: FlightDatabaseService,
				private permissionService: PermissionService,
				private geoAreaService: GeoAreaService,
				private sourceRadicalIdentifierService: SourceRadicalIdentifierService,
				private flightManagementSystemService: FlightManagementSystemService,
				private projectService: ProjectService,
				private icaoService: ICAOService) {
		this.visible['viewer'] = false;

		this.programs$ = this.programService.findAll()
			.pipe(
				mapPrograms(),
				shareReplay()
			);

		this.geoAreasTypes$ = this.geoAreaService.getAll().pipe(
			mapGeoAreaTypes(),
			shareReplay()
		);

		this.geoAreas$ = this.geoAreaService.getAll().pipe(
			mapGeoAreas(),
			shareReplay()
		);

		this.flightManagementSystems$ = this.flightManagementSystemService.getAll().pipe(
			map(systems => systems.map(fms => ({value: fms, label: fms.name, id: fms.identifier}))),
			shareReplay()
		);

		this.projects$ = this.projectService.getSelectableProjects().pipe(
			mapProjects(),
			shareReplay()
		);

		this.sourceRadicalIdentifiers$ = this.sourceRadicalIdentifierService.findAll().pipe(
			mapSourceRadicalIdentifiers(),
			shareReplay()
		);

		this.rnpArs = rnpArOptions();
		this.dsps = dspOptions();
		this.exportControls = exportControlOptions();

		this.form = new NamedFormGroup('article', {});
		this.form.addControl('reference', new FormControl('', [Validators.maxLength(20)]));
		this.form.addControl('usualDesignation', new FormControl('', [Validators.required, Validators.maxLength(255)]));
		this.form.addControl('type', new FormControl('', [Validators.required, Validators.maxLength(255)]));
		this.form.addControl('programs', new FormControl([], [Validators.required]));
		this.form.addControl('offset', new FormControl(0, [Validators.required]));
		this.form.addControl('customers', new FormControl([] as string[]));
		this.form.addControl('flightDatabases', new FormControl([] as string[]));
		this.form.addControl('projects', new FormControl(null, [Validators.required]));
		this.form.addControl('includeApprovalMail', new FormControl(false));

		// production parameters
		this.form.addControl('flightManagementSystem', new FormControl(null));
		this.form.addControl('dqrReference', new FormControl({value: '', disabled: true}));
		this.form.addControl('compatibleApp', new FormControl({value: '', disabled: true}));
		this.form.addControl('tailored', new FormControl(false));
		this.form.addControl('tailoredCode', new FormControl(undefined, [Validators.pattern(/^.{3}$/)]));
		this.form.addControl('geoAreaType', new FormControl(null));
		this.form.addControl('geoAreas', new FormControl(null));
		this.form.addControl('geometricLocations', new FormArray([]));
		this.form.addControl('regionalCode', new FormControl(null));
		this.form.addControl('regionalCodeDefinition', new FormControl(null));
		this.form.addControl('icaoCountries', new FormControl(null));
		this.form.addControl('sourceRadicalIdentifierUuid', new FormControl(null));
		this.form.addControl('databaseRadicalIdentifier', new FormControl(null, [Validators.maxLength(255)]));
		this.form.addControl('rnpAr', new FormControl(null));
		this.form.addControl('exportControl', new FormControl(null));
		this.form.addControl('lpv', new FormControl(null));
		this.form.addControl('dsp', new FormControl(null, [Validators.required]));


		this.subscription.add(this.projects$.subscribe(projects => {
			if (projects.length === 1) {
				this.form.get('projects').patchValue(projects[0]);
			}
		}));


		this.subscription.add(this.form.get('geoAreaType').valueChanges
			.pipe(distinctUntilChanged())
			.subscribe((value: string) => {
				if (value === 'WORLDWIDE') {
					this.form.get('geoAreas').setValue(value);
				} else {
					this.form.get('geoAreas').setValue(null);
				}
			}));

		this.subscription.add(this.form.get('flightManagementSystem').valueChanges.subscribe((value: FlightManagementSystem) => {
			if (value) {
				const dqrControl = this.form.get('dqrReference');
				dqrControl.setValue(value.dqrReference);
				dqrControl.disable();

				const compatibleAppControl = this.form.get('compatibleApp');
				compatibleAppControl.setValue(value.compatibleApp);
				compatibleAppControl.disable();
			}
		}));

		this.subscription.add(this.form.get('tailored').valueChanges.subscribe(value => {
			if (!value) {
				this.form.get('tailoredCode').patchValue(undefined);
				this.form.get('tailoredCode').updateValueAndValidity();

				this.form.get('geoAreaType').removeValidators(Validators.required);
			} else {
				this.form.get('geoAreaType').addValidators(Validators.required);
			}
			this.form.get('geoAreaType').updateValueAndValidity();
		}));
	}

	ngOnInit(): void {
		this.canRequestImmediately$ = this.permissionService.hasAtLeastOnePermission(['REQUEST_ARTICLES_IMMEDIATELY']);
		this.approvalUser$ = this.permissionService.hasAtLeastOnePermission(['APPROVE_ARTICLES']);
		this.approvalRequestUser$ = this.permissionService.hasAtLeastOnePermission(['REQUEST_ARTICLE_APPROVAL']);
		this.form.patchValue(this.article);
		if (this.article) {
			if (this.article.tailoredCode) {
				this.form.get('tailored').patchValue(true);
			}

			if (!this.article.canEditArticleReference) {
				this.form.get('reference').disable();
			}

			this.subscription.add(this.hasViewProductionParametersPermission().subscribe((viewProductionParameters) => {
				if (viewProductionParameters && !this.article.canEditProductionParameters) {
					this.disableProductionParameters();
				}

				if (!this.article.canEditProductionParameters) {
					this.form.get('type').disable();
					this.form.get('programs').disable();
				}
			}));

			this.setSelectedGeoInfo();
			this.setSelectedPrograms();
			this.setSubscribedCustomers();
			this.setLinkedFlightDatabases();
			this.setSelectedFlightManagementSystems();
			this.setSelectedProjects();
			this.setSelectedSourceRadicalIdentifier();
		} else {
			this.subscription.add(this.projects$.subscribe(projects => {
				if (projects.length === 1) {
					this.form.get('projects').patchValue(projects);
					this.form.get('projects').disable();
				}
			}));
			this.subscription.add(this.hasUpdateProductionParametersPermission().subscribe((updateProductionParameters) => {
				if (!updateProductionParameters) {
					this.disableProductionParameters();
				}
			}));
			this.subscription.add(
				this.approvalRequestUser$.subscribe(hasPermission => {
					const control = this.form.get('databaseRadicalIdentifier');
					if (hasPermission) {
						control.addValidators(Validators.required);
					} else {
						control.removeValidators(Validators.required);
					}
					control.updateValueAndValidity();
				})
			);

		}
		this.subscribeToFormValueChanges();

		this.subscription.add(combineLatest([this.canRequestImmediately$, this.form.valueChanges])
			.subscribe(([hasPermission]) => {
				if (hasPermission || this.noProductionParametersGiven()) {
					this.form.get('reference').enable({onlySelf: true});
					this.form.get('reference').addValidators(Validators.required);
				} else {
					this.form.get('reference').disable({onlySelf: true});
					this.form.get('reference').removeValidators(Validators.required);
				}
				this.form.get('reference').updateValueAndValidity({onlySelf: true});
			}));
	}

	private disableProductionParameters(): void {
		this.form.get('flightManagementSystem').disable();
		this.form.get('dqrReference').disable();
		this.form.get('compatibleApp').disable();
		this.form.get('tailored').disable();
		this.form.get('tailoredCode').disable();
		this.form.get('geoAreaType').disable();
		this.form.get('geoAreas').disable();
		this.form.get('geometricLocations').disable();
		this.form.get('icaoCountries').disable();
		this.form.get('regionalCode').disable();
		this.form.get('regionalCodeDefinition').disable();
		this.form.get('projects').disable();
		this.form.get('sourceRadicalIdentifierUuid').disable();
		this.form.get('rnpAr').disable();
		this.form.get('exportControl').disable();
		this.form.get('lpv').disable();
		this.form.get('dsp').disable();
	}

	private setSelectedProjects(): void {
		const projectUuids = this.article.projects.map(project => project.uuid);
		this.projects$.pipe(
			map(projects => projects.filter(project => projectUuids.indexOf(project.id) >= 0))
		)
			.subscribe(selectedProjects => this.form.get('projects').patchValue(selectedProjects));
	}

	private setSelectedFlightManagementSystems(): void {
		this.flightManagementSystems$
			.pipe(map(options => options.find(option => option.id === this.article.flightManagementSystemIdentifier)))
			.subscribe(option => {
				if (option) {
					this.form.patchValue({flightManagementSystem: option.value});
				}
			});
	}

	private setSelectedSourceRadicalIdentifier(): void {
		this.sourceRadicalIdentifiers$
			.pipe(map(options => options.find(option => option.id === this.article.sourceRadicalIdentifier.uuid)))
			.subscribe(option => {
				if (option) {
					this.form.patchValue({sourceRadicalIdentifierUuid: option.value});
				}
			});
	}

	private setSelectedGeoInfo(): void {
		if (this.article.geoAreas?.length > 0) {
			this.subscription.add(this.geoAreas$.subscribe(areaMap => {
				const type = Object.keys(areaMap).find(key => areaMap[key].map(option => option.value).includes(this.article.geoAreas[0]));
				this.form.get('geoAreaType').setValue(type);

				const areaOptions = this.article.geoAreas.map(area => areaMap[type].find(ga => ga.value === area));
				if (type !== this.REGIONAL && type !== this.REGIONAL_AREA) {
					this.form.get('geoAreas').setValue(areaOptions[0].value);
				} else {
					this.form.get('geoAreas').setValue(areaOptions);
				}
			}));
		} else if (this.article.geographicalLocations?.length > 0) {
			this.form.get('geoAreaType').setValue(this.GEOMETRIC_AREA);
			this.article.geographicalLocations.forEach(geoLocation => this.addGeometricControl(geoLocation));
		} else if (this.article.regionalCode) {
			this.form.get('geoAreaType').setValue(this.REGIONAL_SOURCE);
			this.form.get('regionalCode').setValue(this.article.regionalCode);
			this.form.get('regionalCodeDefinition').setValue(this.article.regionalCodeDefinition);
		} else if (this.article.icaoCountries?.length > 0) {
			this.form.get('geoAreaType').setValue(this.COUNTRIES);
			this.article.icaoCountries.join(',');
		} else {
			this.form.get('geoAreas').setValue(null);
		}
		this.setGeoInfoValidators();
	}

	private setLinkedFlightDatabases(): void {
		this.subscription.add(this.flightDatabaseService.getLinkedFlightDatabases(this.article.uuid)
			.subscribe(flightDatabases => {
				const flightDatabasesNames = flightDatabases.map(flightDatabase => flightDatabase.databaseName);
				this.form.get('flightDatabases').setValue(flightDatabasesNames);
			}));
	}

	private setSubscribedCustomers(): void {
		this.subscription.add(this.customerService.findActiveSubscribedCustomersForArticle(this.article.uuid)
			.subscribe(customers => {
				const customersNames = customers.map(customer => customer.name);
				this.form.get('customers').setValue(customersNames);
			}));
	}

	private setSelectedPrograms(): void {
		const programUuids = this.article.programs.map(program => program.uuid);
		this.programs$.pipe(
			map(programs => programs.filter(program => programUuids.indexOf(program.id) >= 0))
		)
			.subscribe(selectedPrograms => this.form.get('programs').patchValue(selectedPrograms));
	}

	private subscribeToFormValueChanges(): void {
		this.subscription.add(
			this.form.valueChanges.subscribe(() => {
				if (this.form.controls['tailored'].value
					|| this.form.controls['geoAreas']?.value
					|| this.form.controls['geoAreaType'].value
					|| this.form.controls['flightManagementSystem'].value
					|| this.form.controls['projects']?.value) {

					this.form.controls['reference'].clearValidators();
				} else {
					this.form.controls['geoAreaType'].clearValidators();
					this.form.controls['geoAreas'].clearValidators();
					this.form.controls['flightManagementSystem'].clearValidators();
					this.form.controls['projects'].clearValidators();
					this.form.controls['tailoredCode'].clearValidators();
				}

				if (this.form.controls['tailored'].value) {
					this.form.controls['tailoredCode'].addValidators([Validators.required, Validators.pattern(/^.{3}$/)]);
				} else {
					this.form.controls['tailoredCode'].clearValidators();
				}
			}));
		this.updateAdditionalGeograpicPropertiesValidators();
	}

	private updateAdditionalGeograpicPropertiesValidators(): void {
		this.subscription.add(this.form.controls['geoAreaType'].valueChanges.subscribe((value: string) => {
			this.form.controls['regionalCode'].clearValidators();
			this.form.controls['regionalCode'].setValue(null);
			this.form.controls['regionalCodeDefinition'].clearValidators();
			this.form.controls['regionalCodeDefinition'].setValue(null);
			this.form.controls['icaoCountries'].clearValidators();
			this.form.controls['icaoCountries'].setValue(null);
			this.form.controls['geoAreas'].clearValidators();
			if (value !== this.WORLDWIDE) {
				this.form.controls['geoAreas'].setValue([]);
			}
			this.form.controls['geometricLocations'].clearValidators();
			(this.form.controls['geometricLocations'] as FormArray).clear();

			this.setGeoInfoValidators();

			this.form.controls['geometricLocations'].updateValueAndValidity();
			this.form.controls['regionalCode'].updateValueAndValidity();
			this.form.controls['regionalCodeDefinition'].updateValueAndValidity();
			this.form.controls['icaoCountries'].updateValueAndValidity();
			this.form.controls['geoAreas'].updateValueAndValidity();
		}));
	}

	private setGeoInfoValidators(): void {
		if (this.form.controls['geoAreaType'].value === this.GEOMETRIC_AREA) {
			this.form.controls['geometricLocations'].addValidators([CustomValidators.minLengthArray(4)]);
		} else if (this.form.controls['geoAreaType'].value === this.REGIONAL_SOURCE) {
			this.form.controls['regionalCode'].addValidators([Validators.required, Validators.maxLength(255)]);
			this.form.controls['regionalCodeDefinition'].addValidators([Validators.required, Validators.maxLength(255)]);
		} else if (this.form.controls['geoAreaType'].value === this.COUNTRIES) {
			this.subscription.add(this.icaoService.getAllCountries().subscribe((countries: ICAOCountry[]) => {
				this.form.controls['icaoCountries'].addValidators([Validators.required, CustomValidators.validIcaoCountriesString(countries)]);
			}));
		} else if (this.form.controls['geoAreaType'].value === this.REGIONAL_AREA || this.form.controls['geoAreaType'].value === this.REGIONAL) {
			this.form.controls['geoAreas'].addValidators([CustomValidators.minLengthArray(1)]);
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	ngOnChanges(): void {
		if (this.readonly) {
			this.form.disable();
		} else {
			this.form.enable();
		}

		if (!this.form.controls['tailored'].value) {
			this.form.controls['tailoredCode'].patchValue(undefined);
		}
	}

	getControlValue(name: string): any {
		return this.form.get(name).value;
	}

	noProductionParametersGiven(): boolean {
		return !this.form.controls['tailored'].value
			&& !this.form.controls['geoAreas'].value
			&& !this.form.controls['geoAreaType'].value
			&& !this.form.controls['flightManagementSystem'].value
			&& !this.form.controls['projects'].value
			&& !this.form.controls['sourceRadicalIdentifierUuid'].value
			&& !this.form.controls['databaseRadicalIdentifier'].value
			&& !this.form.controls['rnpAr'].value
			&& !this.form.controls['exportControl'].value
			&& !this.form.controls['dsp'].value;
	}

	public isInvalid(): boolean {
		return this.form.invalid;
	}

	public isInvalidForApproval(): boolean {
		return this.isInvalid() || !this.form.controls['databaseRadicalIdentifier'].value;
	}

	public includeApprovalMail(): boolean {
		return this.form.controls['includeApprovalMail'].value;
	}

	public isValidForApproval(): boolean {
		return this.form.controls['geoAreas'].value
			&& this.form.controls['geoAreaType'].value
			&& this.form.controls['flightManagementSystem'].value
			&& this.form.controls['projects'].value
			&& this.form.controls['sourceRadicalIdentifierUuid'].value
			&& this.form.controls['databaseRadicalIdentifier'].value
			&& this.form.controls['rnpAr'].value
			&& this.form.controls['exportControl'].value
			&& this.form.controls['dsp'].value;
	}

	getArticle(): CreateOrUpdateArticleRequest {
		const controls = this.form.controls;
		const request: CreateOrUpdateArticleRequest = {
			programUuids: controls['programs'].value.map((p: SelectOption<Program>) => p.id),
			reference: controls['reference'].value,
			tailoredCode: controls['tailoredCode']?.value,
			usualDesignation: controls['usualDesignation']?.value,
			type: controls['type']?.value,
			offset: controls['offset']?.value,
			flightManagementSystemIdentifier: (controls['flightManagementSystem']?.value as FlightManagementSystem)?.identifier,
			sourceRadicalIdentifierUuid: controls['sourceRadicalIdentifierUuid']?.value?.uuid,
			databaseRadicalIdentifier: controls['databaseRadicalIdentifier']?.value,
			rnpAr: controls['rnpAr']?.value,
			exportControl: controls['exportControl']?.value,
			lpv: controls['lpv'].value,
			dsp: controls['dsp'].value
		};

		if (controls['geoAreaType'].value === this.REGIONAL || controls['geoAreaType'].value === this.REGIONAL_AREA) {
			request.geoAreas = controls['geoAreas'].value.map((ga: SelectOption<string>) => ga.id);
		} else if (controls['geoAreaType'].value === this.WORLDWIDE) {
			request.geoAreas = [controls['geoAreas'].value];
		} else if (controls['geoAreaType'].value === this.REGIONAL_SOURCE) {
			request.regionalCode = controls['regionalCode'].value;
			request.regionalCodeDefinition = controls['regionalCodeDefinition'].value;
		} else if (controls['geoAreaType'].value === this.COUNTRIES) {
			let icaoCountries = controls['icaoCountries'].value?.split(',');
			icaoCountries = icaoCountries.map((code: string) => code.trim().toUpperCase());
			request.icaoCountries = icaoCountries;
		} else if (controls['geoAreaType'].value === this.GEOMETRIC_AREA) {
			request.geoLocations = controls['geometricLocations'].getRawValue();
		}

		request.programUuids = controls['programs'].value.map((p: SelectOption<Program>) => p.id);
		request.projectUuids = this.getSelectedProjectUuids();
		if (this.form.get('projects').disabled && this.form.get('projects').value) {
			request.projectUuids = this.form.get('projects').value.map((p: SelectOption<Project>) => p.id);
		}

		return request;
	}

	private getSelectedProjectUuids(): string[] {
		return this.form.controls['projects'].value?.map((p: SelectOption<Project>) => p.id);
	}

	hasViewProductionParametersPermission(): Observable<boolean> {
		return this.permissionService.hasAtLeastOnePermission(['VIEW_ARTICLE_PRODUCTION_PARAMETERS']);
	}

	hasUpdateProductionParametersPermission(): Observable<boolean> {
		return this.permissionService.hasAtLeastOnePermission(['UPDATE_ARTICLE_PRODUCTION_PARAMETERS']);
	}

	getGeometricControls(): AbstractControl[] {
		return (this.form.get('geometricLocations') as FormArray).controls;
	}

	addGeometricControl(location?: GeographicalLocation): void {
		const controls: { [key: string]: FormControl } = {};
		controls['latitude'] = new FormControl(location?.latitude, [Validators.required, Validators.pattern('^(\\d{1,3})°\\s?(\\d{1,2})\'\\s?(\\d{1,2}(\\.\\d+)?)?"?\\s?([NSns])$')]);
		controls['longitude'] = new FormControl(location?.longitude, [Validators.required, Validators.pattern('^(\\d{1,3})°\\s?(\\d{1,2})\'\\s?(\\d{1,2}(\\.\\d+)?)?"?\\s?([WEwe])$')]);
		const group = new FormGroup(controls);

		(this.form.get('geometricLocations') as FormArray).push(group);
	}

	removeGeometricControl(index: number): void {
		this.getGeometricControls().splice(index, 1);
	}

	getGeometricErrors(): string[] {
		return Object.keys(this.form.get('geometricLocations').errors);
	}

	getSourceRadicalIdentifiersForSelectedProjects(): Observable<SelectOption<SourceRadicalIdentifier>[]> {
		const selectedProjectUuids = this.getSelectedProjectUuids();
		return this.sourceRadicalIdentifiers$.pipe(
			map(sourceRadicalIdentifiers => {
				return sourceRadicalIdentifiers.filter(sourceRadicalIdentifier => {
					const hasAnyProjects = selectedProjectUuids.map(selectedProjectUuid => sourceRadicalIdentifier.value.projects.map(project => project.uuid).indexOf(selectedProjectUuid) >= 0);
					return hasAnyProjects.reduce((prev, current) => prev || current);
				});
			})
		);
	}
}
