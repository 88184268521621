"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const chalk_1 = require("chalk");
const daysPerCycle = 28;
const cycleIdentifierPattern = /^([0-9]{2})([0-9]{2})$/;
exports.base = new Date(Date.UTC(1901, 0, 10));
exports.millisPerDay = 24 * 60 * 60 * 1000;
exports.millisPerCycle = daysPerCycle * exports.millisPerDay;
function matchAiracIdentifier(identifier) {
  return identifier.match(cycleIdentifierPattern);
}
exports.matchAiracIdentifier = matchAiracIdentifier;
function printCycle(cycle) {
  console.log(`AIRAC cycle: ${chalk_1.bgBlue.white.bold(` ${cycle.identifier} `)}`);
  console.log(`Effective from: ${chalk_1.blue.bold(cycle.effectiveStart.toLocaleDateString())}`);
  console.log(`Effective to (incl.): ${chalk_1.blue.bold(cycle.effectiveEnd.toLocaleDateString())}`);
}
exports.printCycle = printCycle;
