import {Component, OnDestroy, OnInit} from '@angular/core';
import {Page} from '../../shared/pagination/page';
import {catchError, combineLatest, filter, Observable, startWith, Subject, Subscription, switchMap, tap} from 'rxjs';
import {Header} from '../../shared/table/header';
import {ProductionCatalogueService} from '../_service/production-catalogue.service';
import {LaunchModalData} from '../launch-modal-data';
import {LaunchArticle} from '../_model/launch-article';
import {ToastrMessageType} from '../../shared/toastr/toastr.message.type';
import {ToastrMessageService} from '../../shared/toastr/toastr.message.service';
import {TranslateService} from '@ngx-translate/core';
import {BaseDownloadComponent} from '../../shared/download/base-download.component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductionErrorFileData} from '../_model/production-error-file-data';

@Component({
	selector: 'app-launch-modal',
	templateUrl: './launch-modal.component.html'
})
export class LaunchModalComponent extends BaseDownloadComponent<ProductionErrorFileData> implements OnInit, OnDestroy {
	private PAGE_SIZE = 8;
	launchModalData: LaunchModalData;
	page$: Observable<Page<LaunchArticle>>;
	headers: Header[] = [
		{name: 'article'},
		{name: 'databaseRadId'},
		{name: 'status'},
		{name: 'logs'},
		{name: 'lastChanged'}
	];
	private refresh$ = new Subject<void>();
	private currentPage$ = new Subject<number>();

	private subscription = new Subscription();

	constructor(private readonly productionCatalogueService: ProductionCatalogueService,
				private readonly toastrMessageService: ToastrMessageService,
				private readonly translateService: TranslateService,
				public override activeModal: NgbActiveModal) {
		super(activeModal);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	ngOnInit(): void {
		this.page$ = combineLatest([
			this.currentPage$.pipe(startWith(1)),
			this.refresh$.pipe(startWith(''))
		]).pipe(
			switchMap(([currentPage]) => this.productionCatalogueService.getLaunchArticles(currentPage - 1, this.launchModalData.prodCatUuid, this.launchModalData.launchUuid, this.PAGE_SIZE))
		);
	}

	loadPage(page: number): void {
		this.currentPage$.next(page);
	}

	downloadFile(file: ProductionErrorFileData, launchArticleUuid: string): void {
		if (this.isDownloading()) {
			return;
		}
		this.setDownloading(true);

		this.subscription.add(this.doDownloadFile(file, this.launchModalData.prodCatUuid, launchArticleUuid).subscribe({
			next: () => {
				this.toastrMessageService.show({
					type: ToastrMessageType.SUCCESS,
					messageHTML: `<i class="ti ti-check me-2 fs-5"></i> ${this.translateService.instant('production-catalogue.production-error.download.success-message')}`
				});
				this.activeModal.close();
			},
			error: () => {
				const messageHtml = `<i class="ti ti-trash me-2 fs-5"></i> ${this.translateService.instant('production-catalogue.production-error.download.failure-message')}`;
				this.toastrMessageService.show({
					type: ToastrMessageType.ERROR,
					messageHTML: messageHtml
				});
				this.activeModal.close();
			}
		}));
	}

	private doDownloadFile(file: ProductionErrorFileData, prodCatUuid: string, launchArticleUuid: string): Observable<any> {
		return this.productionCatalogueService.downloadFileStream(prodCatUuid, launchArticleUuid, file.uuid)
			.pipe(
				tap(downloadEvent => {
					if (downloadEvent.state === 'DONE') {
						const a = document.createElement('a');
						a.href = window.URL.createObjectURL(downloadEvent.content);
						a.download = file.fileName + '.zip';
						a.target = '_self';
						a.click();
					}
				}),
				filter(downloadEvent => downloadEvent.state === 'DONE'),
				catchError(error => {
					throw error;
				})
			);
	}

}
