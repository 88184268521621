import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Observable, startWith, Subject, Subscription, switchMap} from 'rxjs';
import {Page} from '../../shared/pagination/page';
import {Header} from '../../shared/table/header';
import {SourceRadicalIdentifier} from '../_model/source-radical-identifier';
import {SourceRadicalIdentifierService} from '../_service/source-radical-identifier.service';
import {ModalService} from '../../shared/modal/modal.service';
import {SourceRadicalIdentifierFormModalComponent} from '../source-radical-identifier-form-modal/source-radical-identifier-form-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {DeleteObserverFactory} from '../../shared/delete/delete-observer-factory';


@Component({
	selector: 'app-source-radical-identifier-overview',
	templateUrl: './source-radical-identifier-overview.component.html',
	standalone: false
})
export class SourceRadicalIdentifierOverviewComponent implements OnInit, OnDestroy {
	page$: Observable<Page<SourceRadicalIdentifier>>;
	headers: Header[] = [
		{name: 'sourceRadicalIdentifier'},
		{name: 'usualDesignation'},
		{name: 'projects'}
	];
	private refresh$ = new Subject<void>();
	private currentPage$ = new Subject<number>();

	private subscription = new Subscription();

	constructor(
		private readonly sourceRadicalIdentifierService: SourceRadicalIdentifierService,
		private readonly translateService: TranslateService,
		private modalService: ModalService,
		private deleteObserverFactory: DeleteObserverFactory) {
	}

	ngOnInit(): void {
		this.page$ = combineLatest([this.currentPage$.pipe(startWith(1)), this.refresh$.pipe(startWith(''))])
			.pipe(
				switchMap(([currentPage]) => this.sourceRadicalIdentifierService.search(currentPage - 1))
			);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	loadPage(page: number): void {
		this.currentPage$.next(page);
	}

	openCreateModal(): void {
		this.modalService.openCreateModal(SourceRadicalIdentifierFormModalComponent, 'source-radical-identifier').subscribe(() => this.refresh$.next());
	}

	openUpdateModal(sourceRadicalIdentifier: SourceRadicalIdentifier): void {
		this.modalService.openUpdateModal(SourceRadicalIdentifierFormModalComponent, sourceRadicalIdentifier, 'source-radical-identifier').subscribe(() => this.refresh$.next());
	}

	deleteSourceRadicalIdentifier(sourceRadicalIdentifier: SourceRadicalIdentifier): void {
		this.subscription.add(
			this.sourceRadicalIdentifierService.delete(sourceRadicalIdentifier.uuid)
				.subscribe(this.deleteObserverFactory.createFactory('source-radical-identifier.delete.success-message', this.refresh$))
		);
	}

	getConfirmationMessage(): string {
		return `<p>${this.translateService.instant('source-radical-identifier.delete.confirmation-message.are-you-sure')}</p>`;
	}
}
