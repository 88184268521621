import {Component} from '@angular/core';
import {UploadBaseComponent} from '../upload-base/upload-base.component';

@Component({
	selector: 'app-db-upload-db',
	templateUrl: './upload-db.component.html',
	standalone: false
})
export class UploadDbComponent extends UploadBaseComponent {

}
