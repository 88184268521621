import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ProductionCatalogueService} from '../../_service/production-catalogue.service';
import {combineLatest, Observable, startWith, Subject, Subscription, switchMap} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ProductionCatalogue} from '../../_model/production-catalogue';
import {ActivatedRoute, Router} from '@angular/router';
import {Page} from '../../../shared/pagination/page';
import {Launch} from '../../_model/launch';
import {DeleteObserverFactory} from '../../../shared/delete/delete-observer-factory';

@Component({
	selector: 'app-production-catalogue-view',
	templateUrl: 'production-catalogue-view.component.html',
	standalone: false
})
export class ProductionCatalogueViewComponent implements OnInit, OnDestroy {
	private subscription = new Subscription();

	productionCatalogue$: Observable<ProductionCatalogue>;
	productionCatalogueUuid: string | null = null;
	page$: Observable<Page<Launch>>;
	isLaunching: boolean = false;

	private refresh$ = new Subject<void>();
	private currentPage$ = new Subject<number>();
	private closePage$ = new Subject<void>();

	constructor(private readonly productionCatalogueService: ProductionCatalogueService,
				private readonly deleteObserverFactory: DeleteObserverFactory,
				private readonly translateService: TranslateService,
				private readonly router: Router,
				private readonly activatedRoute: ActivatedRoute,
				private readonly location: Location) {
	}

	ngOnInit(): void {
		this.activatedRoute.params.subscribe((params): void => {
			this.productionCatalogueUuid = params['uuid'];
			this.productionCatalogue$ = this.productionCatalogueService.get(this.productionCatalogueUuid);
			this.page$ = combineLatest([
				this.currentPage$.pipe(startWith(1)),
				this.refresh$.pipe(startWith(''))
			]).pipe(
				switchMap(([currentPage]) => this.productionCatalogueService.getLaunchHistory(currentPage - 1, this.productionCatalogueUuid))
			);
			this.subscription.add(this.page$.subscribe(page => {
				this.isLaunching = page.content[0].launchArticles.every(art => art.launching);
			}));
			this.subscription.add(this.closePage$.subscribe(() => this.closePage()));
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	closePage(): void {
		this.location.back();
	}

	deleteCatalogue(): void {
		this.subscription.add(this.productionCatalogueService.delete(this.productionCatalogueUuid)
			.subscribe(this.deleteObserverFactory.createFactory('production-catalogue.delete.success-message', this.closePage$))
		);
	}

	editCatalogue(uuid: string): void {
		this.router.navigate([`/production-catalogues/edit/${uuid}`]);
	}

	openLaunchPage(uuid: string): void {
		this.router.navigate([`/production-catalogues/launch/${uuid}`]);
	}

	getConfirmationMessage(): string {
		return `<p>${this.translateService.instant('production-catalogue.delete.confirmation-message.are-you-sure')}</p>`;
	}

	exportProductionCatalogueArticles(productionCatalogue: ProductionCatalogue): void {
		this.productionCatalogueService.downloadExportArticles(productionCatalogue.uuid, productionCatalogue.identification);
	}

	exportProductionCatalogue(productionCatalogue: ProductionCatalogue): void {
		this.productionCatalogueService.downloadExport(productionCatalogue.uuid, productionCatalogue.identification);
	}

	checkLaunch(productionCatalogue: ProductionCatalogue): boolean {
		return productionCatalogue?.status.status !== 'READY_FOR_PRODUCTION' || this.isLaunching;
	}
}
