import {Component, Input} from '@angular/core';
import {ModularFormControlInputWithLayout} from '../modular-form-control-layout/modular-form-control-input-with-layout';

@Component({
	selector: 'app-modular-form-control-input',
	templateUrl: './modular-form-control-input.component.html',
	standalone: false
})
export class ModularFormControlInputComponent extends ModularFormControlInputWithLayout {

	@Input() type: 'text' | 'number' = 'text';

	@Input() small = false;

}
