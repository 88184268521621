import {Injectable} from '@angular/core';
import {ToastrMessageService} from '../toastr/toastr.message.service';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {DeleteObserver} from './delete-observer';

@Injectable({
	providedIn: 'root'
})
export class DeleteObserverFactory {
	constructor(private toastrMessageService: ToastrMessageService,
				private translateService: TranslateService) {
	}

	createFactory(successMessageKey: string, successAction$?: Subject<void>): DeleteObserver {
		return new DeleteObserver(this.toastrMessageService, this.translateService, successMessageKey, successAction$);
	}
}
