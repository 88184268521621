import {Component, Input} from '@angular/core';
import {User} from '../_model/user';

@Component({
	selector: 'app-user-information-modal',
	templateUrl: './user-information.modal.component.html',
	standalone: false
})
export class UserInformationModalComponent {

	@Input()
	public user: Partial<User>;

}
