<div class="modal-header">
	<h1 class="modal-title fs-5">
		<span>{{ "notice.overview.download.title" | translate }}</span>
	</h1>
	<button type="button" class="btn-close" (click)="close()" [disabled]="isDownloading()"></button>
</div>
<div class="modal-body" style="padding: unset">
	<div class="d-flex flex-column justify-content-center">
		<app-table [id]="'notice-files-table'"
				   [headerNamePrefix]="'notice.overview.headers.'"
				   [headers]="[]"
				   [addButtonColumn]="true"
				   [removeStickyHeader]="true">
			<tr *ngFor="let file of files; let i  = index">
				<td id="file-name-{{i}}" class="w-25 text-center">{{ file.fileName }}</td>
				<td id="file-tag-{{i}}" class="w-25 fs-4">
					<app-malware-status [malwareTagProvider]="getMalwareStatus(file.uuid)"  (isCorruptedFile$)="onMalwareStatusUpdate(file, $event)"/>
				</td>
				<td class="w-25 text-center"><i role="button" id="download-{{i}}" class="ti ti-file-download fs-4" (click)="downloadFiles(file.uuid)" [hidden]="file.isCorrupted"></i></td>
			</tr>
		</app-table>
	</div>
</div>
<div class="modal-footer">
	<button id="close" type="button" class="btn btn-secondary" (click)="close()"
			[disabled]="isDownloading()">{{ "general.go-back" | translate }}
	</button>
	<button id="download-all" type="button" class="btn btn-primary" (click)="downloadFiles(null)"
			[disabled]="isDownloading() || isAnyFileCorrupted()">
		<span>{{ "general.download-all" | translate }}</span>
	</button>
</div>
