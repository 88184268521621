import {Component, HostBinding} from '@angular/core';
import {UserService} from '../user/_service/user.service';
import {Observable} from 'rxjs';
import {User} from '../user/_model/user';
import {InfoService} from '../info/info.service';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.css'],
	standalone: false
})
export class SidebarComponent {

	@HostBinding('class') hostClasses = 'col-auto col-md-3 col-xl-2 px-0 min-vh-99 sidebar';

	user$: Observable<User>;
	visible = new Visible();

	version$: Observable<string>;

	constructor(private userService: UserService,
				infoService: InfoService) {
		this.visible['viewer'] = false;
		this.user$ = this.userService.getCurrentUser();
		this.version$ = infoService.getVersion();
	}

	toggleCollapse(key: string): void {
		this.visible[key] = !this.visible[key];
	}

}

class Visible {
	[id: string]: boolean
}
