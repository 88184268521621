<form [formGroup]="form" (ngSubmit)="this.submit()" class="row flex-xxl-row-reverse">

	<div class="col-12 col-xxl-6">
		<app-modular-form-control-files name="actualFiles"/>

		<app-modular-form-dynamic-array arrayControlName="actualFiles" name="files"
										[createFormGroup]="createFileFormGroup">
			<ng-template let-index>
				<app-modular-form-control-input [name]="'files.' + index + '.fileName'"/>

				<app-modular-form-control-input [name]="'files.' + index + '.hash'"/>

				<app-modular-form-control-select [name]="'files.' + index + '.fileType'" [options]="datasetFileTypes"/>
			</ng-template>
		</app-modular-form-dynamic-array>
	</div>

	<div class="col-12 col-xxl-6">
		<app-modular-form-control-select name="sourceRadicalIdentifierUuid" layout="flex" [options]="sourceRadicalIdentifiers$ | async"/>

		<app-modular-form-control-input name="datasetIdentifier" layout="flex"/>

		<app-modular-form-control-input name="usualDesignation" layout="flex"/>

		<app-modular-form-control-input name="airacCycle" layout="flex"/>

		<app-modular-form-control-input name="releaseNumber" layout="flex" type="number"/>

		<app-modular-form-control-multiselect name="projects" layout="flex" [options]="projects$ | async"></app-modular-form-control-multiselect>
	</div>

	<div class="d-flex flex-row justify-content-end">
		<input id='upload-button' type="submit" class="btn btn-primary"
			   [value]="'dataset.upload.start-upload' | translate"
			   [disabled]="this.form.invalid"/>
	</div>
</form>
