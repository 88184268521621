import {Component, Input} from '@angular/core';
import {finalize, Observable} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {formatDate} from '@angular/common';

@Component({
	selector: 'app-direct-download',
	templateUrl: 'direct-download.component.html',
	standalone: false
})
export class DirectDownloadComponent {
	@Input() exportFunction = new Observable<HttpResponse<Blob>>();
	@Input() filePrefix: string;
	exporting = false;

	export(): void {
		if (this.exporting) {
			return;
		}
		this.exporting = true;
		const _filePrefix = this.filePrefix;
		const subscription = this.exportFunction.pipe(
			finalize(() => {
				this.exporting = false;
			})
		).subscribe(response => {
			const a = document.createElement('a');
			a.download = _filePrefix + '_' + formatDate(Date.now(), 'yyyy-MM-dd_HH-mm-ss', 'en') + '.csv';
			a.href = window.URL.createObjectURL(response.body);
			a.click();
			subscription.unsubscribe();
		});
	}
}
