import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Page} from '../../shared/pagination/page';
import {Program} from '../model/program';
import {CreateOrUpdateProgramRequest} from '../model/create.or.update.program.request';

@Injectable({
	providedIn: 'root'
})
export class ProgramService {

	readonly baseUrl = environment.backend_url + '/program';

	constructor(private http: HttpClient) {
	}

	findAll(): Observable<Array<Program>> {
		return this.http.get<Array<Program>>(this.baseUrl);
	}

	search(pageNumber: number): Observable<Page<Program>> {
		return this.http.get<Page<Program>>(this.baseUrl + '/search', {params: {'page': pageNumber}});
	}

	create(program: CreateOrUpdateProgramRequest): Observable<Program> {
		return this.http.post<Program>(this.baseUrl, program);
	}

	update(uuid: string, program: CreateOrUpdateProgramRequest): Observable<Program> {
		return this.http.put<Program>(`${this.baseUrl}/${uuid}`, program);
	}

	delete(programUuid: string): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${programUuid}`);
	}

}
