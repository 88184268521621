<div class="d-flex flex-column p-5">

	<div class="d-flex mb-3">
		<h1>{{ 'db.viewer.title' | translate }}</h1>
	</div>

	<div class="d-inline-flex flex-row justify-content-between w-100 mb-3">
		<app-tsv-file-selector (flightDatabaseSelected)="flightDatabaseSelected($event)"
							   (tsvFileSelected)="tsvFileSelected($event)"></app-tsv-file-selector>

		<a [routerLink]="'/viewerdbupload'" *permissionsAllowed="['UPLOAD_VIEWER_DBS']" class="text-decoration-none">
			<button type="button" class="btn btn-primary">
				<i class="ti ti-upload me-2 fs-4"></i>{{ 'general.upload' | translate }}
			</button>
		</a>
	</div>

	<div>
		<ng-container *ngIf="selectedFlightDatabaseTsvFile$ | async">
			<div class="d-flex flex-row">
				<app-dropdown-multiselect [providedData]="viewSelectionDropdownData"
										  [selectAll]="true"
										  (selection)="updateVisibleColumns($event)"
										  placeholder="db.viewer.view">
				</app-dropdown-multiselect>
			</div>
			<app-table [id]="'tsv-line-table'"
					   [headers]="headers"
					   [resetSearch]="resetSearch$"
					   [resetTriggersSearch]="false"
					   (searchValue)="search($event)">
				<ng-container *ngIf="searchResult as page else noResults">
					<tr *ngFor="let tsvLine of page.content; let i  = index">
						<ng-container *ngFor="let header of headers">
							<td *ngIf="header.visible">{{ tsvLine.content[header.name] }}</td>
						</ng-container>
					</tr>
				</ng-container>
			</app-table>

			<app-pagination *ngIf="searchResult" [page]="searchResult" (pageEvent)="loadPage($event)"></app-pagination>
			<div class="d-flex flex-row justify-content-end gap-3 align-items-center">
				<div class="mt-3">
					<app-direct-download [exportFunction]="getExportFunction()"
										 [filePrefix]="filePrefix"></app-direct-download>
				</div>
				<div class="mt-3">
					<button id="reset-search-button" type="button" class="btn btn-primary"
							(click)="resetSearch()">{{ 'db.viewer.reset-search-button' | translate }}
					</button>
				</div>
			</div>

		</ng-container>
	</div>
	<app-spinner class="center" *ngIf="loading"></app-spinner>
</div>

<ng-template #noResults>
	<tr>
		<td colspan="100%">
			<p class="empty-state-text my-4 justify-content-center">{{ 'db.viewer.no-results' | translate }}</p>
		</td>
	</tr>
</ng-template>
