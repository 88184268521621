<div class="modal-header">
	<h1 class="modal-title fs-5">
		<span *ngIf="!sourceRadicalIdentifier">{{ "source-radical-identifier.create.modal.title" | translate }}</span>
		<span *ngIf="sourceRadicalIdentifier">{{ "source-radical-identifier.update.modal.title" | translate }}</span>
	</h1>
	<button type="button" class="btn-close" (click)="close()" [disabled]="submitting"></button>
</div>
<div class="modal-body">
	<div class="mb-3">
		<form [formGroup]="form">
			<app-modular-form-control-input name="name"/>
			<app-modular-form-control-input name="usualDesignation"/>
			<app-modular-form-control-multiselect name="projects" [options]="projects$ | async"></app-modular-form-control-multiselect>
		</form>
	</div>
	<app-invalid-feedback></app-invalid-feedback>
</div>
<div class="modal-footer">
	<button id="close" type="button" class="btn btn-secondary" (click)="close()"
			[disabled]="submitting">{{ "general.close" | translate }}
	</button>
	<button id="save-source-radical-identifier" type="button" class="btn btn-primary" (click)="save()"
			[disabled]="form.invalid || submitting">
		<span *ngIf="!sourceRadicalIdentifier">{{ "general.create" | translate }}</span>
		<span *ngIf="sourceRadicalIdentifier">{{ "general.save" | translate }}</span>
	</button>
</div>
