import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Job} from '../_model/job';
import {CreateOrUpdateJobRequest} from '../_model/create.or.update.job.request';

@Injectable({
	providedIn: 'root'
})
export class JobService {

	readonly baseUrl = environment.backend_url + '/job';

	constructor(private http: HttpClient) {
	}

	getAll(): Observable<Job[]> {
		return this.http.get<Job[]>(this.baseUrl);
	}

	update(jobId: string, job: CreateOrUpdateJobRequest): Observable<Job> {
		return this.http.put<Job>(`${this.baseUrl}/${jobId}`, job);
	}
}
