<div class="d-flex flex-column p-5">
	<div class="d-flex mb-3">
		<h1>{{ 'production-catalogue.overview.title' | translate }}</h1>
	</div>

	<div *ngIf="page$ | async as page">
		<div *ngIf="page.totalElements > 0 || hasSearchCriteria(); else emptyState">
			<div class="d-inline-flex flex-row justify-content-between w-100 mb-3">
				<div class="d-inline-flex gap-2">
					<app-multilevel-dropdown-multiselect [providedData]="filterDropdownData$ | async"
														 (selection)="filtersSelected($event)"
														 translationPrefix="production-catalogue.overview.filter."
														 placeholder="general.filter"
														 placeholderIcon="ti-filter">
					</app-multilevel-dropdown-multiselect>

					<app-search inputFieldId="production-catalogue-search-field"
								placeHolder="production-catalogue.overview.search.placeholder"
								(searchEvent)="search($event)">
					</app-search>
				</div>

				<div class="d-inline-flex gap-2">
					<button id="open-create-page" *permissionsAllowed="['CREATE_PRODUCTION_CATALOGUES']" type="button" class="btn btn-primary"
							(click)="openCreatePage()">
						<i class="ti ti-plus me-2 fs-4"></i>{{ 'production-catalogue.overview.create-button' | translate }}
					</button>
				</div>
			</div>
			<app-table [addButtonColumn]="true" [headers]="headers"
					   [headerNamePrefix]="'production-catalogue.overview.headers.'"
					   [id]="'production-catalogue-table'">
				<ng-container *ngIf="page.totalElements > 0 else noResults">
					<tr *ngFor="let productionCatalogue of page.content; let i = index">
						<td>
								<span class="badge list-group-item-info cursor-default me-2 mb-1"
									  [class.badge-bg-green]="productionCatalogue.productionCatalogueStatus.status === 'PRODUCTION_COMPLETE'"
									  [class.badge-bg-red]="productionCatalogue.productionCatalogueStatus.status === 'CANCELED'"
									  [class.badge-bg-orange]="productionCatalogue.productionCatalogueStatus.status === 'REDACTION_IN_PROGRESS'"
									  [class.badge-bg-blue]="productionCatalogue.productionCatalogueStatus.status === 'READY_FOR_PRODUCTION'">
										{{ 'production-catalogue.status.' + productionCatalogue.productionCatalogueStatus.status | translate }}
								</span>
						</td>
						<td>{{ productionCatalogue.approvalDate ? productionCatalogue.approvalDate : '/' }}</td>
						<td>{{ productionCatalogue.identification }}</td>
						<td>{{ productionCatalogue.airacCycle }}</td>
						<td>{{ productionCatalogue.requester.firstName + ' ' + productionCatalogue.requester.lastName }}</td>
						<td>{{ productionCatalogue.project.name }}</td>
						<td class="packed">
							<div *permissionsAllowed="['UPDATE_PRODUCTION_CATALOGUES', 'COPY_PRODUCTION_CATALOGUES', 'LAUNCH_PRODUCTION_CATALOGUES_TEST', 'LAUNCH_PRODUCTION_CATALOGUES_PRD',
								'LAUNCH_PRODUCTION_CATALOGUES_ONLY_PRE_PRD', 'EXPORT_PRODUCTION_CATALOGUES', 'DELETE_PRODUCTION_CATALOGUES']" class="d-flex justify-content-end align-items-center">
								<div class="me-4">
									<ng-container *permissionsAllowed="['LAUNCH_PRODUCTION_CATALOGUES_TEST', 'LAUNCH_PRODUCTION_CATALOGUES_PRD', 'LAUNCH_PRODUCTION_CATALOGUES_ONLY_PRE_PRD']">
										<a *ngIf="productionCatalogue.productionCatalogueStatus.status === 'READY_FOR_PRODUCTION'" (click)="openLaunchPage(productionCatalogue.uuid)">
											<i id="launch-production-catalogue-{{i}}" class="ti ti-rocket pt-5 fs-4 pointer position-relative top-10 me-1"></i>
											<span class="pointer">{{ "general.launch" | translate }}</span>
										</a>
									</ng-container>
								</div>
								<i id="view-production-catalogue-{{i}}" class="ti ti-eye me-2 fs-4 pointer"
								   (click)="openViewPage(productionCatalogue.uuid)"></i>
								<div id="dots-{{i}}" class="dropbtn-dropdown" (click)="toggleDropdown(i)">
									<button class="dropbtn ti ti-dots fs-4"></button>
									<div class="dropdown-content pointer dropdown-content-visible " *ngIf="openedDropdown === i">
										<ng-container *permissionsAllowed="['UPDATE_PRODUCTION_CATALOGUES']">
											<a (click)="openEditPage(productionCatalogue.uuid)">
												<i id="edit-production-catalogue-{{i}}" class="ti ti-pencil pt-5 fs-4 pointer position-relative top-10 me-1"></i>
												<span class="pointer">{{ 'general.edit' | translate }}</span>
											</a>
										</ng-container>

										<ng-container *permissionsAllowed="['COPY_PRODUCTION_CATALOGUES']">
											<a (click)="openCopyPage(productionCatalogue.uuid)">
												<i id="copy-production-catalogue-{{i}}" class="ti ti-copy pt-5 fs-4 pointer position-relative top-10 me-1"></i>
												<span class="pointer">{{ 'general.copy' | translate }}</span>
											</a>
										</ng-container>

										<ng-container *permissionsAllowed="['LAUNCH_PRODUCTION_CATALOGUES_TEST', 'LAUNCH_PRODUCTION_CATALOGUES_PRD', 'LAUNCH_PRODUCTION_CATALOGUES_ONLY_PRE_PRD']">
											<a *ngIf="productionCatalogue.productionCatalogueStatus.status === 'READY_FOR_PRODUCTION'" (click)="openLaunchPage(productionCatalogue.uuid)">
												<i id="launch-production-catalogue-{{i}}" class="ti ti-rocket pt-5 fs-4 pointer position-relative top-10 me-1"></i>
												<span class="pointer">Launch</span>
											</a>
										</ng-container>

										<ng-container *permissionsAllowed="['EXPORT_PRODUCTION_CATALOGUES']">
											<a (click)="exportProductionCatalogue(productionCatalogue)">
												<i id="export-production-catalogue-{{i}}" class="ti ti-download pt-5 fs-4 pointer position-relative top-10 me-1"></i>
												<span class="pointer">{{ 'general.export' | translate }}</span>
											</a>
										</ng-container>

										<ng-container *permissionsAllowed="['EXPORT_PRODUCTION_CATALOGUES']">
											<a (click)="exportProductionCatalogueArticles(productionCatalogue)">
												<i id="export-production-catalogue-articles-{{i}}" class="ti ti-download pt-5 fs-4 pointer position-relative top-10 me-1"></i>
												<span class="pointer">{{ 'production-catalogue.export-articles-csv' | translate }}</span>
											</a>
										</ng-container>

										<ng-container *permissionsAllowed="['DELETE_PRODUCTION_CATALOGUES']">
											<hr class="m-0 custom-separator" *ngIf="productionCatalogue.productionCatalogueStatus.status === 'REDACTION_IN_PROGRESS'">
											<a appConfirmationModal (confirmAction)="deleteCatalogue(productionCatalogue.uuid)" confirmationMessage="{{getConfirmationMessage()}}">
												<i id="delete-production-catalogue-{{i}}" class="ti ti-trash text-danger pt-5 fs-4 pointer position-relative top-10 me-1"></i>
												<span class="pointer text-danger">{{ 'general.delete' | translate }}</span>
											</a>
										</ng-container>
									</div>
								</div>
							</div>
						</td>
					</tr>
				</ng-container>
			</app-table>
			<app-pagination [page]="page" (pageEvent)="loadPage($event)"></app-pagination>
		</div>
	</div>
</div>

<ng-template #noResults>
	<tr>
		<td colspan="100%">
			<p class="empty-state-text my-4 justify-content-center">{{ 'production-catalogue.overview.search.no-results' | translate }}</p>
		</td>
	</tr>
</ng-template>

<ng-template #emptyState>
	<div class="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
		<p class="empty-state-text mb-4">{{ 'production-catalogue.overview.no-content' | translate }}</p>
		<button *permissionsAllowed="['CREATE_PRODUCTION_CATALOGUES']" type="button" class="btn btn-primary" (click)="openCreatePage()">
			<i class="ti ti-plus me-2 fs-4"></i>{{ 'production-catalogue.overview.create-button' | translate }}
		</button>
	</div>
</ng-template>
