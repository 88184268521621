import {NgModule} from '@angular/core';
import {DeleteObserverFactory} from './delete-observer-factory';

@NgModule({
	providers: [
		DeleteObserverFactory
	]
})
export class DeleteModule {

}
