import {Component, OnDestroy} from '@angular/core';
import {finalize, Observable, Subscription} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UpdateForm} from '../../shared/modal/update.form';
import {Project} from '../_model/project';
import {ProjectService} from '../_service/project.service';
import {CreateOrUpdateProjectRequest} from '../_model/create-or-update-project.request';

@Component({
	selector: 'app-project-form-modal',
	templateUrl: './project-form-modal.component.html',
	standalone: false
})
export class ProjectFormModalComponent implements OnDestroy, UpdateForm<Project> {

	project: Project;

	protected submitting = false;

	private subscription = new Subscription();

	constructor(private activeModal: NgbActiveModal,
				private projectService: ProjectService) {
	}

	setContent(content: Project): void {
		this.project = content;
	}

	close(): void {
		if (!this.submitting) {
			this.activeModal.dismiss();
		}
	}

	submitForm(request: CreateOrUpdateProjectRequest): void {
		if (this.submitting) {
			return;
		}
		this.submitting = true;

		this.subscription.add(this.doSubmit(request)
			.pipe(finalize(() => {
				this.submitting = false;
			}))
			.subscribe((project) => {
				this.activeModal.close(project);
			})
		);
	}

	doSubmit(request: CreateOrUpdateProjectRequest): Observable<Project> {
		if (this.project) {
			return this.projectService.update(this.project.uuid, request);
		} else {
			return this.projectService.create(request);
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	get i18nTitle(): string {
		return this.project ? 'project.update.modal.title' : 'project.create.modal.title';
	}

	get i18nSubmit(): string {
		return this.project ? 'general.save' : 'general.create';
	}
}

