<div class="d-flex bd-highlight mb-3">
	<div><h1>{{ titleTranslationKey | translate }}</h1></div>
	<div class="ms-auto mt-4">
		<div class="d-flex justify-content-end gap-3">
			<button id="close" type="button" class="btn btn-secondary" (click)="close()">{{ "general.go-back" | translate }}
			</button>
			<button id="submit-article" type="button" class="btn btn-primary"
					*ngIf = "!readonly"
					(click)="submitForm(articleForm.getArticle())"
					[disabled]="articleForm.isInvalid() || submitting">
				{{ getSubmitTranslationKey() | translate }}
			</button>
			<div *ngIf="user$ | async as user">
				<button id="request-article" type="button" class="btn btn-primary"
						*ngIf = "!readonly && (approvalUser$ | async)"
						(click)="requestApproval(articleForm.getArticle())"
						[disabled]="articleForm.isInvalidForApproval() || submitting ">
					{{ "article.form.approval.request" | translate }}
				</button>
				<button id="approve-article" type="button" class="btn btn-primary"
						*ngIf = "!readonly && (approvalProvidor$ | async) && article && article.articleProductionStatus === 'FOR_APPROVAL'"
						(click)="approveArticle(articleForm.getArticle(), articleForm.includeApprovalMail())"
						[disabled]="!articleForm.isValidForApproval()|| submitting ">
					{{ "article.form.approval.approve" | translate }}
				</button>
			</div>
		</div>
	</div>
</div>

<app-article-form #articleForm="articleForm" [article]="article" [readonly]="submitting || readonly"/>
