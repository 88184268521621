import {Component, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, distinctUntilChanged, filter, map, Observable, ReplaySubject, startWith, Subject, Subscription, switchMap} from 'rxjs';
import {Header} from '../../shared/table/header';
import {FlightDatabaseService} from '../_service/flight-database.service';
import {FlightDatabaseTsvFileData} from '../_model/flight-database-tsv-file-data';
import {FlightDatabase} from '../_model/flight-database';
import {Content} from '../../shared/model/content';
import {DropdownData} from '../../shared/dropdown-multiselect/dropdown-data';
import {HttpResponse} from '@angular/common/http';
import {mapContentToContentArray} from '../../shared/model/content-array';
import {Page} from '../../shared/pagination/page';
import {TsvDiffLine} from '../_model/tsv-diff-line';

@Component({
	selector: 'app-db-updates-viewer',
	templateUrl: './db-updates-viewer.component.html',
	styleUrls: ['./db-updates-viewer.component.css']
})
export class DbUpdatesViewerComponent implements OnDestroy {

	public readonly DIFF_COLUMN = 'db.updates-viewer.headers.diff';
	loading= false;
	subscription = new Subscription();
	private currentPage$ = new Subject<number>();
	selectedFlightDatabase$ = new ReplaySubject<FlightDatabase>(1);
	selectedFlightDatabaseTsvFile$ = new ReplaySubject<FlightDatabaseTsvFileData>(1);
	private selectedCriteria$ = new ReplaySubject<Content>(1);
	updatesOnly$ = new BehaviorSubject(false);
	resetSearch$ = new Subject<void>();
	headers: Header[];
	viewSelectionDropdownData: DropdownData[];
	private exportFunction$: Observable<HttpResponse<Blob>>;

	searchResult: Page<TsvDiffLine>;

	constructor(private dbService: FlightDatabaseService) {
		this.subscription.add(
			combineLatest([
				this.selectedFlightDatabase$,
				this.selectedFlightDatabaseTsvFile$
			]).pipe(
				filter(([currentDb, currentTsvFile]) => this.databaseAndFileSelected(currentDb, currentTsvFile)),
				switchMap(([currentDb, currentTsvFile])=> this.dbService.logViewerUpdatesConsults(currentDb.uuid, currentTsvFile.uuid))
			).subscribe()
		);

		this.subscription.add(
			combineLatest([
				this.currentPage$.pipe(startWith(0), distinctUntilChanged()),
				this.selectedFlightDatabase$,
				this.selectedFlightDatabaseTsvFile$,
				this.updatesOnly$,
				this.selectedCriteria$
			]).pipe(
				filter(([, currentDb, currentTsvFile]) => this.databaseAndFileSelected(currentDb, currentTsvFile)),
				switchMap(([currentPage, currentDb, currentTsvFile, updatesOnly, selectedCriteria]) => {
					this.loading = true;
					return this.dbService.searchTsvDiffLines(currentPage, currentDb.uuid, currentTsvFile.uuid, updatesOnly, selectedCriteria);
				})
			).subscribe({
				next: searchResult => {
					this.searchResult = searchResult;
					this.loading = false;
				},
				error: () => {
					this.searchResult = null;
					this.loading = false;
				}
			})
		);

		this.exportFunction$ = combineLatest([
			this.selectedFlightDatabase$,
			this.selectedFlightDatabaseTsvFile$,
			this.updatesOnly$,
			this.selectedCriteria$.pipe(map(selectedCriteria => mapContentToContentArray(selectedCriteria)))
		]).pipe(
			filter(([currentDb, currentTsvFile]) => this.databaseAndFileSelected(currentDb, currentTsvFile)),
			switchMap(([currentDb, currentTsvFile, updatesOnly, contentArray]) => this.dbService.exportTsvDiffLines(currentDb.uuid, currentTsvFile.uuid, updatesOnly, contentArray))
		);
	}

	private databaseAndFileSelected(flightDatabase: FlightDatabase, flightDatabaseTsvFileData: FlightDatabaseTsvFileData): boolean {
		return flightDatabase !== null && flightDatabase !== undefined
			&& flightDatabaseTsvFileData !== null && flightDatabaseTsvFileData !== undefined;
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	loadPage(page: number): void {
		this.currentPage$.next(page - 1);
	}

	flightDatabaseSelected(flightDatabase: any): void {
		this.selectedFlightDatabaseTsvFile$.next(null);
		this.selectedFlightDatabase$.next(flightDatabase);
	}

	tsvFileSelected(file: any): void {
		this.extractHeaders(file);
		this.updateVisibleColumns(this.headers.map(header => header.name));

		this.resetSearch();

		this.viewSelectionDropdownData = this.headers.map(header => {
			return {
				label: header.name,
				value: header.name,
				isSelected: true
			};
		});

		this.selectedFlightDatabaseTsvFile$.next(file);
	}

	updateUpdatesOnly(event: any): void {
		this.updatesOnly$.next(event);
	}

	updateVisibleColumns(columns: string[]): void {
		this.headers.forEach(header => {
			header.visible = columns.indexOf(header.name) >= 0;
		});
	}

	search(selectedCriteria: Content): void {
		this.currentPage$.next(0);
		this.selectedCriteria$.next(selectedCriteria);
	}

	resetSearch(): void {
		this.searchResult = null;
		this.resetSearch$.next();
	}

	getExportFunction(): Observable<HttpResponse<Blob>> {
		return this.exportFunction$;
	}

	private extractHeaders(file: any): void {
		this.headers = file.columnHeaders.map((headerValue: string) => {
			return {name: headerValue, searchable: true};
		}) as Header[];
		this.headers.splice(0, 0, {name: this.DIFF_COLUMN, searchable: true});
	}
}
