import {Component} from '@angular/core';
import {UploadBaseComponent} from '../upload-base/upload-base.component';

@Component({
	selector: 'app-db-upload-viewer',
	templateUrl: './upload-viewer.component.html',
	standalone: false
})
export class UploadViewerComponent extends UploadBaseComponent {

}
