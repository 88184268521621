import {Component, OnDestroy} from '@angular/core';
import {DatasetService} from '../_service/dataset.service';
import {BaseDownloadComponent} from '../../shared/download/base-download.component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {combineLatest, filter, Observable, Subscription, tap} from 'rxjs';
import {DownloadEvents} from '../../shared/download/download-events';
import {ToastrMessageService} from '../../shared/toastr/toastr.message.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrMessageType} from '../../shared/toastr/toastr.message.type';
import {DatasetFile} from '../_model/dataset-file';
import {GuardDutyFileScanStatus} from '../../shared/model/guard-duty-file-scan-status';
import {NoticeFile} from '../../notices/notice/notice-file';

@Component({
	selector: 'app-dataset-download-files-modal-component',
	templateUrl: './dataset-download-files-modal.component.html',
	standalone: false
})
export class DatasetDownloadFilesModalComponent extends BaseDownloadComponent<DatasetFile> implements OnDestroy {

	protected downloadingIndex = Number.NaN;

	private subscription = new Subscription();
	progress = {} as DownloadEvents;

	constructor(public datasetService: DatasetService,
				public override activeModal: NgbActiveModal,
				public toastrMessageService: ToastrMessageService,
				public translateService: TranslateService) {
		super(activeModal);
	}


	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	downloadFiles(fileUuid: string, index: number): void {
		if (this.isDownloading()) {
			return;
		}
		this.setDownloading(true);
		this.downloadingIndex = index;

		this.toastrMessageService.show({
			messageHTML: 'general.download-checksum-alert',
			sticky: true,
			type: ToastrMessageType.WARNING
		});

		let observable: Observable<any>;
		if (!fileUuid) {
			const observables = this.files.map(file => this.doDownloadFile(file, this.entityUuid));
			observable = combineLatest(observables);
		} else {
			observable = this.doDownloadFile(this.files[index], this.entityUuid);
		}
		this.subscription.add(observable.subscribe({
			next: () => {
				this.toastrMessageService.show({
					type: ToastrMessageType.SUCCESS,
					messageHTML: `<i class="ti ti-check me-2 fs-5"></i> ${this.translateService.instant('dataset.download.success-message')}`
				});
				this.activeModal.close();
			},
			error: (error) => {
				let messageHtml = `<i class="ti ti-alert-circle me-2 fs-5"></i> ${this.translateService.instant('dataset.download.failure-message')}`;

				if (error?.error?.message) {
					//client side error
					messageHtml = `<i class="ti ti-alert-circle me-2 fs-5"></i> ${this.translateService.instant('dataset.download.client-failure-message')}`;
				}
				this.toastrMessageService.show({
					type: ToastrMessageType.ERROR,
					messageHTML: messageHtml
				});
				this.activeModal.close();
			}
		}));
	}

	private doDownloadFile(file: DatasetFile, entityUuid: string): Observable<any> {
		return this.datasetService.downloadFileStream(entityUuid, file.uuid)
			.pipe(
				tap(downloadEvent => {
					this.progress[file.uuid] = downloadEvent;
					if (downloadEvent.state === 'DONE') {
						const a = document.createElement('a');
						a.href = window.URL.createObjectURL(downloadEvent.content);
						a.download = file.fileName;
						a.target = '_self';
						a.click();
					}
				}),
				filter(downloadEvent => downloadEvent.state === 'DONE')
			);
	}

	getMalwareStatus(fileUuid: string): () => Observable<GuardDutyFileScanStatus> {
		return () => this.datasetService.getFilesWithStatus(this.entityUuid, fileUuid);
	}

	onMalwareStatusUpdate(file: NoticeFile, isCorrupted: boolean): void {
		file.isCorrupted = isCorrupted;
	}

	isAnyFileCorrupted(): boolean {
		return this.files?.some(file => file.isCorrupted);
	}
}
