import {AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormControl, Validators} from '@angular/forms';
import {SelectOption} from '../../shared/modular-forms/_model/select-option';
import {Observable, shareReplay, Subject, Subscription, switchMap} from 'rxjs';
import {mapArticleDescriptors, mapProjects, mapStatusToSelectOption} from '../../shared/modular-forms/_model/select-option.factory';
import {ProjectService} from '../../project/_service/project.service';
import {UserService} from '../../user/_service/user.service';
import {ProductionCatalogue} from '../_model/production-catalogue';
import {CustomValidators} from '../../shared/validators/custom-validators';
import {ArticleService} from '../../article/_service/article.service';
import {ModularFormComponent} from '../../shared/modular-forms/modular-form/modular-form.component';


@Component({
	selector: 'app-production-catalogue-form',
	templateUrl: './production-catalogue-form.component.html',
	exportAs: 'productioncatalogueForm'
})
export class ProductionCatalogueFormComponent extends ModularFormComponent implements AfterViewChecked, OnInit, OnDestroy {

	@Input()
	public productionCatalogue: ProductionCatalogue;

	public _readonly = false;

	@Input()
	public copy: boolean;

	@Input()
	public launch: boolean = false;

	projects$: Observable<SelectOption[]>;
	articles$ = new Subject<SelectOption[]>;
	productionCatalogueStatuses: SelectOption[];
	oldStatus: SelectOption;

	private subscription = new Subscription();


	constructor(private readonly projectService: ProjectService,
				private readonly userService: UserService,
				private articleService: ArticleService,
				private readonly changeDetectorRef: ChangeDetectorRef) {
		super('production-catalogue');
		this.projects$ = this.projectService.getSelectableProjects().pipe(
			mapProjects(),
			shareReplay()
		);
		this.form.addControl('status', new FormControl<string>(''));
		this.form.addControl('approvalDate', new FormControl<string>(''));
		this.form.addControl('identification', new FormControl<string>('', [Validators.required]));
		this.form.addControl('airacCycle', new FormControl<string>('', [Validators.required, CustomValidators.validAirac]));
		this.form.addControl('requesterUser', new FormControl<string>({
			value: '',
			disabled: true
		}, [Validators.required]));
		this.form.addControl('requesterUserUuid', new FormControl<string>(null));
		this.form.addControl('project', new FormControl('', [Validators.required]));
		this.form.addControl('selectedArticles', new FormControl([], [Validators.required]));
		this.form.addControl('articles', new FormArray([]));

		this.subscription.add(this.form.get('project').valueChanges
			.pipe(
				switchMap(projectUuid => this.articleService.getArticlesLinkedToProjectAndInStateReady(projectUuid)),
				mapArticleDescriptors(),
				shareReplay()
			)
			.subscribe(articles => {
				this.articles$.next(articles);
				let selectedArticles = this.form.get('selectedArticles').value as SelectOption[];
				selectedArticles = selectedArticles.filter(selectedArticle => articles.find(a => a.id === selectedArticle.id));
				this.form.get('selectedArticles').patchValue(selectedArticles);
				this.form.get('selectedArticles').updateValueAndValidity();
			})
		);
	}

	ngAfterViewChecked(): void {
		this.changeDetectorRef.detectChanges();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	override set readonly(value: boolean) {
		this._readonly = value;
	}

	ngOnInit(): void {
		if (!this.productionCatalogue) {
			this.subscription.add(this.userService.getCurrentUser().subscribe(user => {
				this.form.get(('requesterUserUuid')).patchValue(user.uuid);
				this.form.get('requesterUser').patchValue(user.firstName + ' ' + user.lastName);
				this.form.get('requesterUser').updateValueAndValidity();
			}));

			this.subscription.add(this.projects$.subscribe((projects) => {
				if (projects.length === 1) {
					this.form.controls['project'].patchValue(projects[0].value);
				}
			}));

		} else {
			this.setProductionCatalogue(this.productionCatalogue);
		}

		if (this._readonly) {
			this.form.controls['approvalDate'].disable();
			this.form.controls['status'].disable();
			this.form.controls['identification'].disable();
			this.form.controls['airacCycle'].disable();
			this.form.controls['requesterUser'].disable();
			this.form.controls['selectedArticles'].disable();
			this.form.controls['project'].disable();
			this.form.controls['articles'].disable();
			this.form.updateValueAndValidity();
		}

		this.subscription.add(this.form.get('status').valueChanges.subscribe((value) => {
			if (value === 'READY_FOR_PRODUCTION') {
				const today = new Date();
				const day = String(today.getDate()).padStart(2, '0');
				const month = String(today.getMonth() + 1).padStart(2, '0');
				const year = today.getFullYear();
				this.form.controls['approvalDate'].patchValue(`${day}/${month}/${year}`);
			} else if (this.oldStatus?.value !== 'READY_FOR_PRODUCTION') {
				this.form.controls['approvalDate'].patchValue('');
			}
		}));
	}

	setProductionCatalogue(prodcat: ProductionCatalogue): void {
		this.productionCatalogue = prodcat;
		this.productionCatalogueStatuses = prodcat.status.nextStatuses.map(s => this.mapProductionCatalogueStatusToSelectOption(s));
		const status = this.mapProductionCatalogueStatusToSelectOption(prodcat.status.status);
		this.productionCatalogueStatuses.push(status);
		this.form.controls['status'].patchValue(status.value);
		this.oldStatus = status;
		this.form.controls['approvalDate'].patchValue(prodcat.approvalDate);
		this.form.controls['identification'].patchValue(prodcat.identification);
		this.form.controls['airacCycle'].patchValue(prodcat.airacCycle);
		this.form.controls['requesterUser'].patchValue(prodcat.requester.firstName + ' ' + prodcat.requester.lastName);
		this.form.controls['requesterUserUuid'].patchValue(prodcat.requester.uuid);
		this.form.controls['requesterUser'].updateValueAndValidity();
		if (prodcat.status.status !== 'REDACTION_IN_PROGRESS') {
			this.form.controls['approvalDate'].disable();
			this.form.controls['identification'].disable();
			this.form.controls['airacCycle'].disable();
			this.form.controls['project'].disable();
			this.form.controls['articles'].disable();
			this.form.controls['selectedArticles'].disable();
		}

		this.subscription.add(this.articles$.subscribe(articles => {
			const selectedArticles = prodcat.articles
				.map(article => articles.find(option => option.id === article.articleUuid))
				.filter(article => !!article);
			this.form.get('selectedArticles').patchValue(selectedArticles);
			this.form.get('selectedArticles').updateValueAndValidity();
		}));

		this.form.controls['project'].patchValue(prodcat.project.uuid);
		this.form.controls['project'].updateValueAndValidity();
	}

	get informationKeyForCurrentState(): string {
		if (this.productionCatalogue.status.status == 'CANCELED') {
			return 'production-catalogue.form.not.editable.state.is.canceled';
		}
		return 'production-catalogue.form.not.editable.state.is.not.redaction.in.progress';
	}

	private mapProductionCatalogueStatusToSelectOption(status: string): SelectOption {
		return mapStatusToSelectOption(status, 'production-catalogue.status.');
	}
}
