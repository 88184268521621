import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-base-modal',
	templateUrl: './base.modal.component.html',
	standalone: false
})
export class BaseModalComponent {

	@Input()
	public title: string;

	@Input()
	public disabled = false;

	@Output()
	public dismiss = new EventEmitter<any>();

	constructor(private modal: NgbActiveModal) {
	}

	closeModal(): void {
		this.dismiss.emit();
		this.modal.dismiss();
	}
}
