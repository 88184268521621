import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, switchMap, tap, timer} from 'rxjs';
import {GuardDutyFileScanStatus} from '../model/guard-duty-file-scan-status';

@Component({
	selector: 'app-malware-status',
	templateUrl: './malware-status.component.html',
	styleUrls: ['./malware-status.component.css'],
	standalone: false
})
export class MalwareStatusComponent implements OnInit {

	@Input() public malwareTagProvider: () => Observable<GuardDutyFileScanStatus>;
	@Output() isCorruptedFile$ = new EventEmitter<boolean>();
	malwareTag$: Observable<GuardDutyFileScanStatus>;

	ngOnInit(): void {
		this.malwareTag$ = timer(0, 10000).pipe(
			switchMap(() => this.malwareTagProvider()),
			tap(scan => this.isCorruptedFile$.next(scan.status !== 'NO_THREATS_FOUND'))
		);
	}

}
