import { HttpEvent, HttpEventType } from '@angular/common/http';
import {DownloadEvent} from './download-event';
import {map, OperatorFunction} from 'rxjs';

export function mapHttpEventToDownloadEvent(): OperatorFunction<HttpEvent<Blob>, DownloadEvent> {
	return map((event: HttpEvent<Blob>) => {
		if (event && event.type === HttpEventType.DownloadProgress) {
			return {
				progress: Math.round((100 * event.loaded) / event.total),
				state: 'IN_PROGRESS',
				content: null
			} as DownloadEvent;
		} else if (event && event.type === HttpEventType.Response) {
			return {
				progress: 100,
				state: 'DONE',
				content: event.body
			} as DownloadEvent;
		} else {
			return {
				progress: 0,
				state: 'PENDING',
				content: null
			} as DownloadEvent;
		}
	});
}
