import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Page} from '../../shared/pagination/page';
import {SourceRadicalIdentifier} from '../_model/source-radical-identifier';
import {CreateOrUpdateSourceRadicalIdentifierRequest} from '../_model/create-or-update-source-radical-identifier-request';


@Injectable()
export class SourceRadicalIdentifierService {

	readonly baseUrl = environment.backend_url + '/sourceradicalidentifier';

	constructor(private http: HttpClient) {
	}

	search(pageNumber: number): Observable<Page<SourceRadicalIdentifier>> {
		return this.http.get<Page<SourceRadicalIdentifier>>(this.baseUrl + '/search', {params: {'page': pageNumber}});
	}

	create(request: CreateOrUpdateSourceRadicalIdentifierRequest): Observable<SourceRadicalIdentifier> {
		return this.http.post<SourceRadicalIdentifier>(this.baseUrl, request);
	}

	update(uuid: string, request: CreateOrUpdateSourceRadicalIdentifierRequest): Observable<SourceRadicalIdentifier> {
		return this.http.put<SourceRadicalIdentifier>(`${this.baseUrl}/${uuid}`, request);
	}

	delete(radicalIdentifierUuid: string): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${radicalIdentifierUuid}`);
	}

	findAll(): Observable<Array<SourceRadicalIdentifier>> {
		return this.http.get<Array<SourceRadicalIdentifier>>(this.baseUrl);
	}

}
