import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {TableModule} from '../shared/table/table.module';
import {PaginationModule} from '../shared/pagination/pagination.module';
import {PermissionsModule} from '../shared/permission/permissions.module';
import {ErrorHandlingModule} from '../shared/error-handling/error-handling.module';
import {DatasetOverviewComponent} from './dataset-overview/dataset-overview.component';
import {DatasetService} from './_service/dataset.service';
import {DatasetUploadFormComponent} from './upload/upload-form/dataset-upload-form.component';
import {ModularFormsModule} from '../shared/modular-forms/modular-forms.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DatasetUploadComponent} from './upload/dataset-upload.component';
import {DatasetUploadProgressComponent} from './upload/upload-progress/dataset-upload-progress.component';
import {UploadModule} from '../shared/upload/upload.module';
import {RouterModule} from '@angular/router';
import {DatasetDownloadFilesModalComponent} from './download-files-modal/dataset-download-files-modal.component';
import {DownloadModule} from '../shared/download/download.module';
import {NgbProgressbar} from '@ng-bootstrap/ng-bootstrap';
import {ModalModule} from '../shared/modal/modal.module';
import {ProjectModule} from '../project/project.module';
import {MalwareStatusModule} from '../shared/malware-status/malware-status.module';


@NgModule({
	declarations: [
		DatasetOverviewComponent,
		DatasetUploadFormComponent,
		DatasetUploadProgressComponent,
		DatasetUploadComponent,
		DatasetDownloadFilesModalComponent
	],
	exports: [
		DatasetOverviewComponent,
		DatasetUploadComponent
	],
	imports: [
		CommonModule,
		TranslateModule,
		TableModule,
		PaginationModule,
		PermissionsModule,
		ErrorHandlingModule,
		ModularFormsModule,
		FormsModule,
		ReactiveFormsModule,
		UploadModule,
		RouterModule,
		DownloadModule,
		NgbProgressbar,
		ModalModule,
		ProjectModule,
		MalwareStatusModule
	],
	providers: [
		DatasetService
	]
})
export class DatasetModule {

}
