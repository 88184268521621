import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DropdownData} from './dropdown-data';

@Component({
	selector: 'app-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.css'],
	standalone: false
})
export class DropdownComponent {

	@Input() providedData: DropdownData[];

	@Input() placeholder: string;

	@Input() id: string;

	@Output() selection = new EventEmitter<string>();

	setValue(value: string): void {
		this.selection.emit(value);
	}
}
