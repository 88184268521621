import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup} from '@angular/forms';
import {ArticleService} from '../../../article/_service/article.service';
import {map, Observable, shareReplay} from 'rxjs';
import {SelectOption} from '../../../shared/modular-forms/_model/select-option';
import {mapArticleDescriptorUuids} from '../../../shared/modular-forms/_model/select-option.factory';
import {Subscription} from '../../_model/subscription';

@Component({
	selector: 'app-customer-subscription-form',
	templateUrl: './customer-subscription-form.component.html',
	standalone: false
})
export class CustomerSubscriptionFormComponent implements OnInit {

	@Input() public offset: boolean;

	@Input() public subscriptions: Subscription[];

	@Input() public name: string;

	@Input() public formArray: FormArray;

	articles$: Observable<SelectOption<string>[]>;


	constructor(private articleService: ArticleService) {
		this.articles$ = this.articleService.findAll().pipe(
			mapArticleDescriptorUuids(),
			shareReplay());
	}

	ngOnInit(): void {
		this.subscriptions?.forEach((subscription: Subscription) => this.addSubscription(subscription));
	}

	getArticles(index: number): Observable<SelectOption<string>[]> {
		const uuids = this.formArray.getRawValue().map((sub: any) => sub.articleUuid);
		delete uuids[index];
		return this.articles$.pipe(map(articles => articles.filter(article => !uuids.includes(article.value))));
	}

	getControls(): AbstractControl[] {
		return this.formArray.controls;
	}

	getSubscriptionCount(): number {
		return this.getControls().length;
	}

	addSubscription(subscription?: Subscription): void {
		const controls: { [key: string]: FormControl } = {};
		controls['articleUuid'] = new FormControl(subscription?.articleUuid);
		controls['startDate'] = new FormControl(subscription?.startDate);
		controls['endDate'] = new FormControl(subscription?.endDate);

		if (this.offset) {
			controls['offset'] = new FormControl(subscription ? subscription.offset : -7);
		}

		const group = new FormGroup(controls);
		if (this.formArray.disabled) {
			group.disable();
		}

		this.formArray.push(group);
	}

	removeSubscription(index: number): void {
		this.getControls().splice(index, 1);
	}

}
