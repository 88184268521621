<form [formGroup]="form">
	<ng-container *ngIf="launchArticles$ | async as articlesList">
		<app-modular-form-control-checkbox name="allArticles"></app-modular-form-control-checkbox>

		<div *ngIf="form.get('launchArticles').errors" class="alert alert-danger alert-dismissible fade show" role="alert">
			<span [innerHTML]="'production-catalogue.launch.form.launchArticles.validation.min1Selected' | translate"></span>
		</div>

		<div class="container">
			<div class="row mb-2">
				<span class="col col-sm-2 fw-bold">{{ 'production-catalogue.launch.form.headers.article' | translate }}</span>
				<span class="col col-sm-2 fw-bold">{{ 'production-catalogue.launch.form.headers.production-date' | translate }}</span>
				<span class="col col-sm-3 fw-bold">{{ 'production-catalogue.launch.form.headers.dataset-identifier' | translate }}</span>
				<span class="col col-sm-3 fw-bold" *ngIf="isAnArticleMultiLoad">{{ 'production-catalogue.launch.form.headers.previous-database' | translate }}</span>
			</div>

			<div *ngFor="let article of articlesList; let i  = index">
				<div class="row">
					<div class="col col-sm-2 d-inline-flex mt-2">
						<app-modular-form-control-checkbox
							[name]="'launchArticles.' + i + '.selected'"
							[hideLabel]="true">
						</app-modular-form-control-checkbox>
						<span [id]="'launchArticles-' + i + '-articleReference'" class="mx-1">
							{{ form.get('launchArticles.' + i + '.articleReference').value }}
						</span>
					</div>
					<div class="col col-sm-2">
						<app-modular-form-control-date
							[name]="'launchArticles.' + i + '.productionDate'"
							[layout]="'stack-inline'"
							[hideLabel]="true">
						</app-modular-form-control-date>
					</div>
					<div class="col col-sm-3">
						<div *ngIf="(articleDatasets.has(article.articleUuid) && articleDatasets.get(article.articleUuid).length>0)">
							<app-modular-form-control-select
								[name]="'launchArticles.' + i + '.dataset'"
								[layout]="'stack-inline'"
								[hideLabel]="true"
								[options]="articleDatasets.get(article.articleUuid)"
							>
							</app-modular-form-control-select>
						</div>
						<div class="input-wrapper" *ngIf="!(articleDatasets.has(article.articleUuid) && articleDatasets.get(article.articleUuid).length>0)">
							<div class="layout-wrapper d-flex justify-content-between gap-2 flex-inline">
								<input class="form-control badge-bg-red"
									   [placeholder]="'production-catalogue.launch.form.launchArticles.dataset.validation.noSourceFile' | translate"
									   readonly>
							</div>
						</div>
					</div>
					<div class="col col-sm-3">
						<div *ngIf="article.multiLoad">
							<app-modular-form-control-select
								[name]="'launchArticles.' + i + '.database'"
								[layout]="'stack-inline'"
								[hideLabel]="true"
								[options]="articleDatabases.get(article.articleUuid)">
							</app-modular-form-control-select>
						</div>
					</div>
					<div class="col col-sm-2">
						<div *ngIf="article.multiLoad">
							<button id="copy-button-{{i}}" type="button" class="btn btn-secondary" (click)="copyPreviousDatabase(article)" [disabled]="!form.get('launchArticles.' + i + '.selected').value">
								{{'production-catalogue.launch.form.launchArticles.multiLoad.copy' | translate}}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<app-modular-form-control-select (change)="selectionChanged()" name="type" [options]="launchTypes"></app-modular-form-control-select>
	</ng-container>
</form>
