<div class="d-flex flex-column p-5">
	<div *ngIf="productionCatalogue$ | async as productionCatalogue">
		<div class="d-flex bd-highlight mb-3">
			<div><h1>{{ 'production-catalogue.characteristics.title' | translate }}</h1></div>
			<div class="ms-auto mt-4">
				<div class="d-flex justify-content-end gap-3">
					<button id="close" type="button" class="btn btn-secondary"
							(click)="closePage()">{{ "general.go-back" | translate }}
					</button>
					<button id="launch" type="button" class="btn btn-primary"
							*permissionsAllowed="['LAUNCH_PRODUCTION_CATALOGUES_TEST', 'LAUNCH_PRODUCTION_CATALOGUES_PRD', 'LAUNCH_PRODUCTION_CATALOGUES_ONLY_PRE_PRD']"
							(click)="openLaunchPage(productionCatalogue.uuid)" [disabled]="checkLaunch(productionCatalogue)">
						<span>{{ "general.launch" | translate }}</span>
					</button>
					<div id="dots" class="dropdown"
						 (click)="content.classList.toggle('dropdown-content-visible'); content.classList.toggle('dropdown-content-hidden')">
						<button class="btn btn-tertiary dropbtn">
							{{ "production-catalogue.characteristics.more" | translate }}
							<i class="ti ti-dots fs-4 ms-1"></i>
						</button>
						<div class="dropdown-content pointer " #content>
							<ng-container *permissionsAllowed="['EXPORT_PRODUCTION_CATALOGUES']">
								<a (click)="exportProductionCatalogue(productionCatalogue)">
									<i id="export-production-catalogue" class="ti ti-download pt-5 fs-4 pointer position-relative top-10 me-1"></i>
									<span class="pointer">{{ 'general.export' | translate }}</span>
								</a>
							</ng-container>
							<ng-container *permissionsAllowed="['EXPORT_PRODUCTION_CATALOGUES']">
								<a (click)="exportProductionCatalogueArticles(productionCatalogue)">
									<i id="export-production-catalogue-articles" class="ti ti-download pt-5 fs-4 pointer position-relative top-10 me-1"></i>
									<span class="pointer">{{ 'production-catalogue.export-articles-csv' | translate }}</span>
								</a>
							</ng-container>
							<a appConfirmationModal
							   (confirmAction)="deleteCatalogue()"
							   confirmationMessage="{{getConfirmationMessage()}}"
							   *permissionsAllowed="['DELETE_PRODUCTION_CATALOGUES']">
								<i id="delete-catalogue"
								   class="ti ti-trash text-danger pt-5 fs-4 pointer position-relative top-10 me-1">
								</i>
								<span class="pointer text-danger">{{ 'general.delete' | translate }}</span>
							</a>
						</div>
					</div>
					<button id="edit-catalogue" type="button" class="btn btn-primary"
							*permissionsAllowed="['UPDATE_PRODUCTION_CATALOGUES']"
							(click)="editCatalogue(productionCatalogue.uuid)" [disabled]="!form.form.valid">
						<span>{{ "general.edit" | translate }}</span>
					</button>
				</div>
			</div>
		</div>
		<div *ngIf="isLaunching" class="alert alert-warning flex-column">
			{{ 'production-catalogue.launch.processing-alert' | translate }}
		</div>
		<app-production-catalogue-form #form="productioncatalogueForm" [productionCatalogue]="productionCatalogue"></app-production-catalogue-form>
	</div>
</div>
