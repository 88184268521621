import {Component, OnInit} from '@angular/core';
import {combineLatest, Observable, startWith, Subject, Subscription, switchMap} from 'rxjs';
import {Page} from '../../shared/pagination/page';
import {Header} from '../../shared/table/header';
import {ProjectService} from '../_service/project.service';
import {ModalService} from '../../shared/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {Project} from '../_model/project';
import {ProjectFormModalComponent} from '../project-form-modal/project-form-modal.component';
import {DeleteObserverFactory} from '../../shared/delete/delete-observer-factory';

@Component({
	selector: 'app-project-overview',
	templateUrl: './project-overview.component.html',
	standalone: false
})
export class ProjectOverviewComponent implements OnInit {

	protected page$: Observable<Page<Project>>;
	protected readonly headers: Header[] = [
		{name: 'name'}
	];

	private subscription = new Subscription();

	private refresh$ = new Subject<void>();
	private currentPage$ = new Subject<number>();

	constructor(private projectService: ProjectService,
				private modalService: ModalService,
				private deleteObserverFactory: DeleteObserverFactory,
				private translateService: TranslateService) {
	}

	ngOnInit(): void {
		this.page$ = combineLatest([this.currentPage$.pipe(startWith(1)), this.refresh$.pipe(startWith(''))])
			.pipe(switchMap(([currentPage]) => this.projectService.search(currentPage - 1)));
	}

	loadPage(page: number): void {
		this.currentPage$.next(page);
	}

	openCreateModal(): void {
		this.modalService.openCreateModal(ProjectFormModalComponent, 'project').subscribe(() => this.refresh$.next());
	}

	openUpdateModal(project: Project): void {
		this.modalService.openUpdateModal(ProjectFormModalComponent, project, 'project').subscribe(() => this.refresh$.next());
	}

	deleteProject(project: Project): void {
		this.subscription.add(
			this.projectService.delete(project.uuid)
				.subscribe(this.deleteObserverFactory.createFactory('project.delete.success-message', this.refresh$))
		);
	}

	getConfirmationMessage(): string {
		return `<p>${this.translateService.instant('project.delete.confirmation-message.are-you-sure')}</p>`;
	}
}

