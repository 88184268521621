import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../../shared/validators/custom-validators';
import {Observable, shareReplay, Subscription} from 'rxjs';
import {CreateOrUpdateDatasetRequest} from '../../_model/create-or-update-dataset-request';
import {CreateDatasetFileData} from '../../_model/create-dataset-file-data';
import {UploadFormResult} from './upload-form-result';
import {SelectOption} from '../../../shared/modular-forms/_model/select-option';
import {SourceRadicalIdentifierService} from '../../../sourceradicalidentifier/_service/source-radical-identifier.service';
import {datasetFileTypeOptions, mapProjects, mapSourceRadicalIdentifiers} from '../../../shared/modular-forms/_model/select-option.factory';
import {ModularFormComponent} from '../../../shared/modular-forms/modular-form/modular-form.component';
import {ProjectService} from '../../../project/_service/project.service';
import {SourceRadicalIdentifier} from '../../../sourceradicalidentifier/_model/source-radical-identifier';

@Component({
	selector: 'app-dataset-upload-form',
	templateUrl: './dataset-upload-form.component.html',
	standalone: false
})
export class DatasetUploadFormComponent extends ModularFormComponent implements OnInit, OnDestroy {

	@Output()
	public submitForm = new EventEmitter<UploadFormResult>();

	@Input() reset$: Observable<void>;

	projects$: Observable<SelectOption<string>[]>;
	sourceRadicalIdentifiers$: Observable<SelectOption<SourceRadicalIdentifier>[]>;
	datasetFileTypes: SelectOption<string>[];

	private subscription = new Subscription();

	constructor(private sourceRadicalIdentifierService: SourceRadicalIdentifierService,
				private projectService: ProjectService) {
		super('dataset.upload');

		this.form.addControl('usualDesignation', new FormControl('', [Validators.maxLength(100)]));
		this.form.addControl('datasetIdentifier', new FormControl('', [Validators.required, Validators.maxLength(255)]));
		this.form.addControl('airacCycle', new FormControl('', [Validators.required, CustomValidators.validAirac]));
		this.form.addControl('releaseNumber', new FormControl(0, [Validators.required, Validators.min(0)]));
		this.form.addControl('sourceRadicalIdentifierUuid', new FormControl('', [Validators.required]));
		this.form.addControl('actualFiles', new FormControl([], [CustomValidators.requireNonEmptyArray]));
		this.form.addControl('projects', new FormControl('', [Validators.required]));
		this.form.addControl('files', new FormArray([]));

		this.sourceRadicalIdentifiers$ = this.sourceRadicalIdentifierService.findAll().pipe(
			mapSourceRadicalIdentifiers(),
			shareReplay()
		);

		this.projects$ = this.projectService.getSelectableProjects().pipe(
			mapProjects(),
			shareReplay()
		);

		this.datasetFileTypes = datasetFileTypeOptions();
	}

	ngOnInit(): void {
		this.subscription.add(this.reset$.subscribe(() => this.form.reset()));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public createFileFormGroup(file: File): FormGroup {
		const fileNameControl = new FormControl(file.name, [Validators.required]);
		fileNameControl.disable();

		return new FormGroup({
			fileName: fileNameControl,
			hash: new FormControl('', [Validators.required, Validators.maxLength(255)]),
			fileType: new FormControl('', [Validators.required])
		});
	}

	public submit(): void {
		this.submitForm.emit({
			request: this.createRequest(),
			selectedFiles: this.form.get('actualFiles').value
		});
	}

	public createRequest(): CreateOrUpdateDatasetRequest {
		const files = this.extractFiles();
		return {
			usualDesignation: this.form.get('usualDesignation').value,
			identifier: this.form.get('datasetIdentifier').value,
			sourceRadicalIdentifierUuid: this.form.get('sourceRadicalIdentifierUuid').value?.uuid,
			airacCycle: this.form.get('airacCycle').value,
			releaseNumber: +this.form.get('releaseNumber').value,
			files: files,
			projectUuids: this.form.get('projects').value?.map((p: SelectOption<string>) => p.id)
		};
	}

	private extractFiles(): CreateDatasetFileData[] {
		const filesControls = this.form.get('files') as FormArray;
		return filesControls.controls.map(controls => controls.getRawValue()).map(values => {
			return {
				fileName: values.fileName,
				hash: values.hash,
				fileType: values.fileType
			} as CreateDatasetFileData;
		});
	}
}
