import {Component, Input, OnInit} from '@angular/core';
import {Notice} from '../notice';
import {FormControl} from '@angular/forms';
import {ModularFormComponent} from '../../../shared/modular-forms/modular-form/modular-form.component';

@Component({
	selector: 'app-view-notice-modal',
	templateUrl: './view-notice-modal.component.html',
	standalone: false
})
export class ViewNoticeModalComponent extends ModularFormComponent implements OnInit {

	@Input() public notice: Notice;

	constructor() {
		super('notice.view.modal');

		this.form.addControl('availableCustomers', new FormControl([]));
		this.form.addControl('availablePrograms', new FormControl([]));
	}

	ngOnInit(): void {
		const customers = this.notice.customers.map(customer => customer.name);
		this.form.get('availableCustomers').setValue(customers);

		const programs = this.notice.programs.map(program => program.name);
		this.form.get('availablePrograms').setValue(programs);
	}


}
