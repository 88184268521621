import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReplaySubject, Subscription} from 'rxjs';
import {ProductionCatalogueService} from '../../_service/production-catalogue.service';
import {ToastrMessageService} from '../../../shared/toastr/toastr.message.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup} from '@angular/forms';
import {ToastrMessageType} from '../../../shared/toastr/toastr.message.type';
import {CreateProductionCatalogueRequest} from '../../_model/create-production-catalogue-request';
import {ProductionCatalogue} from '../../_model/production-catalogue';

@Component({
	selector: 'app-production-catalogue-create',
	templateUrl: 'production-catalogue-create.component.html',
	standalone: false
})
export class ProductionCatalogueCreateComponent implements OnInit, OnDestroy {
	private subscription = new Subscription();

	productionCatalogue$ = new ReplaySubject<ProductionCatalogue>();
	productionCatalogueUuid: string | null = null;
	isCopy: boolean = false;

	constructor(private readonly productionCatalogueService: ProductionCatalogueService,
				private readonly translateService: TranslateService,
				private readonly toastrMessageService: ToastrMessageService,
				private readonly router: Router,
				private readonly activatedRoute: ActivatedRoute) {


	}

	ngOnInit(): void {
		this.activatedRoute.params.subscribe((params): void => {
			const path = this.activatedRoute.snapshot.routeConfig?.path;
			this.isCopy = path.includes('copy');

			if (this.isCopy) {
				this.productionCatalogueUuid = params['uuid'];
				this.productionCatalogueService.get(this.productionCatalogueUuid).subscribe((prodCat): void => {
					prodCat = {
						...prodCat,
						uuid: undefined,
						comments: [],
						status: {status: 'REDACTION_IN_PROGRESS', nextStatuses: []},
						approvalDate: undefined,
						identification: this.translateService.instant('general.copy-of') + prodCat.identification
					};
					this.productionCatalogue$.next(prodCat);
				});
			} else {
				this.productionCatalogue$.next({} as ProductionCatalogue);
			}
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	closePage(): void {
		this.router.navigate(['production-catalogue']);
	}

	submitForm(form: FormGroup): void {
		this.createCatalogue(this.getCreateProductionCatalogueRequest(form));
	}

	createCatalogue(request: CreateProductionCatalogueRequest): void {
		this.subscription.add(this.productionCatalogueService.create(request).subscribe(() => {
			this.closePage();
			this.toastrMessageService.show({
				type: ToastrMessageType.SUCCESS,
				messageHTML: `<i class="ti ti-check me-2 fs-5"></i> ${this.translateService.instant('production-catalogue.create.success-message')}`
			});
		}));
	}

	getCreateProductionCatalogueRequest(form: FormGroup): CreateProductionCatalogueRequest {
		const articles = form.get('articles').value;

		articles.forEach((article: any) => {
			if (typeof article.productionDate !== 'string') {
				const day = String(article.productionDate['day']).padStart(2, '0');
				const month = String(article.productionDate['month']).padStart(2, '0');
				const year = article.productionDate['year'];
				article.productionDate = year + '-' + month + '-' + day;
			}
		});

		return {
			airacCycle: form.get('airacCycle').value,
			articleProductions: articles,
			identification: form.get('identification').value,
			projectUuid: form.get('project').value,
			requesterUserUuid: form.get('requesterUserUuid').value
		};
	}

	get i18nSubmit(): string {
		if (this.isCopy) {
			return 'general.copy';
		} else {
			return 'general.create';
		}
	}

	get titleTranslationKey(): string {
		if (this.isCopy) {
			return 'production-catalogue.copy.title';
		} else {
			return 'production-catalogue.create.title';
		}
	}
}
