import {Component, Input, OnInit} from '@angular/core';
import {ProductionCatalogueService} from '../_service/production-catalogue.service';
import {Header} from '../../shared/table/header';
import {Launch} from '../_model/launch';
import {LaunchArticle} from '../_model/launch-article';
import {ProductionStepName, ProductionStepStatus} from '../../launcharticle/production-step/_model/launch-article-production-step';
import {Page} from '../../shared/pagination/page';
import {combineLatest, Observable, startWith, Subject, switchMap} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LaunchModalComponent} from '../launch-modal/launch-modal.component';

@Component({
	selector: 'app-launch-history-overview',
	templateUrl: './launch-history-overview.component.html'
})
export class LaunchHistoryOverviewComponent implements OnInit {
	@Input() prodCatUuid: string;
	page$: Observable<Page<Launch>>;
	headers: Header[] = [
		{name: 'launchTime'},
		{name: 'launchStatus'},
		{name: 'user'}
	];
	private refresh$ = new Subject<void>();
	private currentPage$ = new Subject<number>();

	constructor(private readonly productionCatalogueService: ProductionCatalogueService,
				private readonly ngbModal: NgbModal) {
	}

	ngOnInit(): void {
		this.page$ = combineLatest([
			this.currentPage$.pipe(startWith(1)),
			this.refresh$.pipe(startWith(''))
		]).pipe(
			switchMap(([currentPage]) => this.productionCatalogueService.getLaunchHistory(currentPage - 1, this.prodCatUuid))
		);
	}

	loadPage(page: number): void {
		this.currentPage$.next(page);
	}

	getLaunchArticleCounter(launchArticles: LaunchArticle[]): string {
		return this.calculateNumberOfSuccessfullyUploadedLaunchArticles(launchArticles) + ' / ' + launchArticles.length;
	}

	calculateNumberOfSuccessfullyUploadedLaunchArticles(launchArticles: LaunchArticle[]): number {
		let numberOfSuccessfullyUploadedLaunchArticles = 0;
		for (const launchArticle of launchArticles) {
			for (const step of launchArticle.productionSteps) {
				if (step.name === ProductionStepName.UPLOAD && step.status === ProductionStepStatus.SUCCESS) {
					numberOfSuccessfullyUploadedLaunchArticles++;
				}
			}
		}
		return numberOfSuccessfullyUploadedLaunchArticles;
	}

	getBadgeStatusColor(launchArticles: LaunchArticle[]): string {
		const anyHasFailed = launchArticles.some(launchArticle => launchArticle.productionSteps.some(step => step.status === ProductionStepStatus.FAIL));
		const anyHasWarning = launchArticles.some(launchArticle => launchArticle.productionSteps.some(step => step.status === ProductionStepStatus.WARNING));
		const anyIsInProgress = launchArticles.some(launchArticle => launchArticle.productionSteps.some(step => step.status === ProductionStepStatus.IN_PROGRESS));
		const allSucceeded = launchArticles.every(launchArticle => launchArticle.productionSteps.every(step => step.status === ProductionStepStatus.SUCCESS));

		switch (true) {
			case anyHasFailed:
				return 'badge-bg-red';
			case !anyHasFailed && anyHasWarning:
				return 'badge-bg-orange';
			case anyIsInProgress:
				return 'badge-bg-blue';
			case allSucceeded:
				return 'badge-bg-green';
			default:
				return '';
		}
	}

	launchArticleFailed(launchArticles: LaunchArticle[]): boolean {
		return launchArticles.some(launchArticle => launchArticle.productionSteps.some(step => step.status === ProductionStepStatus.FAIL));
	}

	getNumberOfFailedLaunchArticles(launchArticles: LaunchArticle[]): string {
		const failedLaunchArticles = launchArticles.filter(launchArticle => launchArticle.productionSteps.some(step => step.status === ProductionStepStatus.FAIL));
		return failedLaunchArticles.length.toString();
	}

	openViewModal(launch: Launch): void {
		const modalRef = this.ngbModal.open(LaunchModalComponent, {
			windowClass: 'modal-large',
			modalDialogClass: 'modal-dialog-xl'
		});
		modalRef.componentInstance.launchModalData = {prodCatUuid: this.prodCatUuid, launchCreatedDate: launch.createdDate, launchCreatedBy: launch.createdBy, launchUuid: launch.uuid};
	}
}
