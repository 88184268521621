import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {ProductionCatalogueService} from '../../_service/production-catalogue.service';
import {Observable, Subscription} from 'rxjs';
import {ToastrMessageType} from '../../../shared/toastr/toastr.message.type';
import {ToastrMessageService} from '../../../shared/toastr/toastr.message.service';
import {TranslateService} from '@ngx-translate/core';
import {ProductionCatalogue} from '../../_model/production-catalogue';
import {UpdateProductionCatalogueRequest} from '../../_model/update-production-catalogue-request';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup} from '@angular/forms';
import {ModalService} from '../../../shared/modal/modal.service';

@Component({
	selector: 'app-production-catalogue-edit',
	templateUrl: 'production-catalogue-edit.component.html',
	standalone: false
})
export class ProductionCatalogueEditComponent implements OnInit, OnDestroy {
	private subscription = new Subscription();

	productionCatalogue$: Observable<ProductionCatalogue>;
	productionCatalogueUuid: string | null = null;

	constructor(private readonly productionCatalogueService: ProductionCatalogueService,
				private readonly toastrMessageService: ToastrMessageService,
				private readonly translateService: TranslateService,
				private readonly modalService: ModalService,
				private readonly router: Router,
				private readonly activatedRoute: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.activatedRoute.params.subscribe((params): void => {
			this.productionCatalogueUuid = params['uuid'];
			this.productionCatalogue$ = this.productionCatalogueService.get(this.productionCatalogueUuid);
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	closePage(): void {
		this.router.navigate(['production-catalogue']);
	}

	submitForm(form: FormGroup, oldStatus: string): void {
		if (oldStatus !== form.get('status').value) {
			this.modalService.showConfirmationModal({
				cancelAction: new EventEmitter<void>(), cancelButtonText: 'general.cancel', confirmButtonText: 'general.confirm', danger: true, hasFooter: true, modalIcon: 'ti-alert-circle',
				confirmationMessage: this.translateService.instant('production-catalogue.status-change.confirmation-message.are-you-sure'),
				confirmAction: new EventEmitter<void>(),
				confirmFunction: () => this.updateCatalogue(this.getUpdateProductionCatalogueRequest(form))
			});
		} else {
			this.updateCatalogue(this.getUpdateProductionCatalogueRequest(form));
		}
	}

	private updateCatalogue(request: UpdateProductionCatalogueRequest): void {
		this.subscription.add(this.productionCatalogueService.update(request.uuid, request).subscribe(() => {
			this.closePage();
			this.toastrMessageService.show({
				type: ToastrMessageType.SUCCESS,
				messageHTML: `<i class="ti ti-check me-2 fs-5"></i> ${this.translateService.instant('production-catalogue.edit.success-message')}`
			});
		}));
	}

	private getUpdateProductionCatalogueRequest(form: FormGroup): UpdateProductionCatalogueRequest {
		return {
			uuid: this.productionCatalogueUuid,
			identification: form.get('identification').value,
			status: form.get('status').value,
			airacCycle: form.get('airacCycle').value,
			projectUuid: form.get('project').value,
			articleProductions: form.get('articles').value
		};
	}
}
