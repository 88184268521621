import {Component, ContentChild, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormArray, FormGroup} from '@angular/forms';
import {ModularFormControl} from '../modular-form-control/modular-form-control';

@Component({
	selector: 'app-modular-form-dynamic-array',
	templateUrl: './modular-form-dynamic-array.component.html',
	standalone: false
})
export class ModularFormDynamicArrayComponent extends ModularFormControl implements OnInit, OnDestroy {

	@ContentChild(TemplateRef) templateRef: TemplateRef<any>;

	@Input() public arrayControlName: string;
	@Input() public createFormGroup: (obj: any) => FormGroup;

	formArray: FormArray<FormGroup>;
	private current: any[] = [];

	private readonly subscription = new Subscription();

	override ngOnInit(): void {
		super.ngOnInit();
		this.update([]);

		this.subscription.add(
			this.controlContainer.control.get(this.arrayControlName).valueChanges.subscribe((updated: any[]) => this.update(updated))
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private update(updated: any[]): void {
		const controls: FormGroup[] = [];
		if (updated instanceof Array) {
			for (const obj of updated) {
				const index = this.current.indexOf(obj);
				if (index !== -1) {
					controls.push(this.formArray.controls[index]);
				} else {
					controls.push(this.createFormGroup(obj));
				}
			}
		} else {
			updated = [];
		}

		this.formArray = new FormArray(controls);
		this.getForm().setControl(this.name, this.formArray);
		this.current = updated;
	}

}
