import {Component, Input} from '@angular/core';
import {ProductionStepStatus, LaunchArticleProductionStep, ProductionStepName, ProductionStepShortName} from './_model/launch-article-production-step';
import {PreparationStep, ProductionStep, ProductionStepType, UploadStep, ValidationStep} from './_model/production-step-item';

@Component({
	selector: 'app-production-step',
	templateUrl: './production-step.component.html',
	styleUrls: ['./production-step.component.css'],
	standalone: false
})
export class ProductionStepComponent {

	productionSteps: ProductionStepType[] = [];
	protected readonly launchArticleProductionStepStatus = ProductionStepStatus;

	@Input()
	set steps(steps: LaunchArticleProductionStep[]) {
		steps.forEach((step: LaunchArticleProductionStep) => {

			this.productionSteps.push(this.createProductionStep(step));
		});
	}


	private createProductionStep(step: LaunchArticleProductionStep): ProductionStepType {
		switch (step.name) {
			case ProductionStepName.PREPARATION:
				return this.createProductionStepPreparation(step);
			case ProductionStepName.PRODUCTION:
				return this.createProductionStepProduction(step);
			case ProductionStepName.VALIDATION:
				return this.createProductionStepValidation(step);
			case ProductionStepName.UPLOAD:
				return this.createProductionStepUpload(step);
			default:
				return this.createProductionStepPreparation(step);
		}
	}

	private createProductionStepPreparation(step: LaunchArticleProductionStep): PreparationStep {
		return {
			type: ProductionStepName.PREPARATION,
			productionStepShortName: ProductionStepShortName.PREP,
			productionStepName: step.name,
			status: step.status,
			position: 1
		};
	}

	private createProductionStepProduction(step: LaunchArticleProductionStep): ProductionStep {
		return {
			type: ProductionStepName.PRODUCTION,
			productionStepShortName: ProductionStepShortName.PROD,
			productionStepName: step.name,
			status: step.status,
			position: 2
		};
	}

	private createProductionStepValidation(step: LaunchArticleProductionStep): ValidationStep {
		return {
			type: ProductionStepName.VALIDATION,
			productionStepShortName: ProductionStepShortName.VAL,
			productionStepName: step.name,
			status: step.status,
			position: 3
		};
	}

	private createProductionStepUpload(step: LaunchArticleProductionStep): UploadStep {
		return {
			type: ProductionStepName.UPLOAD,
			productionStepShortName: ProductionStepShortName.UPL,
			productionStepName: step.name,
			status: step.status,
			position: 4
		};
	}
}
