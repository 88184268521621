import {Component, Input} from '@angular/core';
import {SelectOption} from '../_model/select-option';
import {ModularFormControlInputWithLayout} from '../modular-form-control-layout/modular-form-control-input-with-layout';

@Component({
	selector: 'app-modular-form-control-radiogroup',
	templateUrl: './modular-form-control-radiogroup.component.html',
	styleUrls: ['modular-form-control-radiogroup.component.css'],
	standalone: false
})
export class ModularFormControlRadioGroupComponent extends ModularFormControlInputWithLayout {

	@Input()
	public options: SelectOption<any>[] = [];

}
