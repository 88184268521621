import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Page} from '../../shared/pagination/page';
import {DatasetOverview} from '../_model/dataset-overview';
import {CreateOrUpdateDatasetRequest} from '../_model/create-or-update-dataset-request';
import {DownloadEvent} from '../../shared/download/download-event';
import {mapHttpEventToDownloadEvent} from '../../shared/download/download.util';
import {DatasetFile} from '../_model/dataset-file';
import {GuardDutyFileScanStatus} from '../../shared/model/guard-duty-file-scan-status';

@Injectable()
export class DatasetService {

	readonly baseUrl = environment.backend_url + '/dataset';

	constructor(private http: HttpClient) {
	}

	search(pageNumber: number, searchValue: string): Observable<Page<DatasetOverview>> {
		let params = new HttpParams();
		params = params.append('page', pageNumber);
		if (searchValue) {
			params = params.append('search.*', searchValue);
		}
		return this.http.get<Page<DatasetOverview>>(this.baseUrl + '/search', {params});
	}

	uploadFile(file: File, uploadUuid: string): Observable<HttpEvent<any>> {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('uploadUuid', uploadUuid);
		return this.http.post(`${this.baseUrl}/upload`, formData, {
			reportProgress: true,
			observe: 'events'
		});
	}

	createDataset(request: CreateOrUpdateDatasetRequest, uploadUuid: string): Observable<any> {
		return this.http.post(`${this.baseUrl}/create`, request, {params: {'uploadUuid': uploadUuid}});
	}

	getLinkedDatasets(uuid: string): Observable<DatasetOverview[]> {
		return this.http.get<DatasetOverview[]>(`${this.baseUrl}/sourceradicalidentifier/${uuid}`);
	}

	getFilesWithStatus(uuid: string, fileUuid: string): Observable<GuardDutyFileScanStatus> {
		return this.http.get<GuardDutyFileScanStatus>(`${this.baseUrl}/${uuid}/files/${fileUuid}/status`);
	}

	downloadFileStream(uuid: string, fileUuid: string): Observable<DownloadEvent> {
		return this.http.get(`${this.baseUrl}/download/${uuid}/${fileUuid}/stream`, {
			reportProgress: true,
			observe: 'events',
			responseType: 'blob'
		}).pipe(mapHttpEventToDownloadEvent());
	}

	getFiles(uuid: string): Observable<DatasetFile[]> {
		return this.http.get<DatasetFile[]>(this.baseUrl + `/${uuid}/files`);
	}

	delete(uuid: string): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${uuid}`);
	}

	getDataSetFilesStatuses(uuid: string): Observable<GuardDutyFileScanStatus[]> {
		return this.http.get<GuardDutyFileScanStatus[]>(this.baseUrl + `/${uuid}/files/status`);
	}
}
