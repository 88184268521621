import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {User} from '../_model/user';
import {RoleService} from '../../role/role.service';
import {CustomerService} from '../../customer/_service/customer.service';
import {map, Observable, shareReplay, Subscription} from 'rxjs';
import {SelectOption} from '../../shared/modular-forms/_model/select-option';
import {mapCustomers, mapProjects, mapRoles} from '../../shared/modular-forms/_model/select-option.factory';
import {ProjectService} from '../../project/_service/project.service';
import {ModularFormComponent} from '../../shared/modular-forms/modular-form/modular-form.component';
import {Role} from '../../role/role';
import {Customer} from '../../customer/_model/customer';

@Component({
	selector: 'app-user-form',
	templateUrl: './user-form.component.html',
	exportAs: 'userForm',
	standalone: false
})
export class UserFormComponent extends ModularFormComponent implements OnInit, OnDestroy {

	@Input()
	public user: Partial<User>;

	roles$: Observable<SelectOption<Role>[]>;
	customers$: Observable<SelectOption<Customer>[]>;
	projects$: Observable<SelectOption<string>[]>;
	types: SelectOption<string>[] = [
		{value: null, label: 'user.form.type.options.no-type', translate: true, id: '0'},
		{value: 'BUYER', label: 'user.form.type.options.buyer', translate: true, id: '1'},
		{value: 'OPERATOR', label: 'user.form.type.options.operator', translate: true, id: '2'}
	];

	isOptionalFieldsRequired = false;
	isProjectRequired = false;

	subscription = new Subscription();

	constructor(private roleService: RoleService,
				private customerService: CustomerService,
				private projectService: ProjectService) {
		super('user');
		this.roles$ = this.roleService.findAll().pipe(
			mapRoles(),
			shareReplay()
		);
		this.customers$ = this.customerService.findAll().pipe(
			mapCustomers(),
			shareReplay()
		);
		this.projects$ = this.projectService.getSelectableProjects().pipe(
			mapProjects(),
			shareReplay()
		);

		this.form.addControl('userName', new FormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern('[a-zA-Z0-9-_.]*')]));
		this.form.addControl('firstName', new FormControl('', [Validators.required, Validators.maxLength(50)]));
		this.form.addControl('lastName', new FormControl('', [Validators.required, Validators.maxLength(50)]));
		this.form.addControl('email', new FormControl('', [Validators.required, Validators.maxLength(100), Validators.email]));
		this.form.addControl('roles', new FormControl(null, [Validators.required]));
		this.form.addControl('customer', new FormControl(null));
		this.form.addControl('type', new FormControl(null));
		this.form.addControl('projects', new FormControl(null));

		this.form.valueChanges.subscribe(() => {
			this.updateOptionalFields();
		});
	}

	ngOnInit(): void {
		this.form.patchValue(this.user);
		if (this.isEditing()) {
			this.form.get('userName').disable();
			this.form.get('email').disable();
			this.setSelectedRoles();
			this.setSelectedProjects();
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	updateOptionalFields(): void {
		this.calculateIsProjectRequired();
		this.calculateRequiresOptionalFields();
		if (this.isOptionalFieldsRequired) {
			this.form.controls['customer'].addValidators(Validators.required);
			this.form.controls['type'].addValidators(Validators.required);
		} else {
			this.form.controls['customer'].clearValidators();
			this.form.controls['type'].clearValidators();
		}

		if (this.isProjectRequired) {
			this.form.controls['projects'].addValidators(Validators.required);
		} else {
			this.form.controls['projects'].clearValidators();
		}
	}

	calculateRequiresOptionalFields(): void {
		this.subscription.add(this.getFilteredSelectedRoles()
			.subscribe(filteredValues => {
				this.isOptionalFieldsRequired = filteredValues?.some(filteredValue => filteredValue.value?.isInternal === false);
			}));
	}

	calculateIsProjectRequired(): void {
		this.subscription.add(this.getFilteredSelectedRoles()
			.subscribe(filteredValues => filteredValues?.some(filteredValue =>
				this.isProjectRequired = filteredValue.value?.isProjectRequired
			)));
	}

	private isEditing(): boolean {
		return this.user !== undefined;
	}

	private setSelectedProjects(): void {
		const projectUuids = this.user.projects.map(project => project.uuid);
		this.projects$.pipe(
			map(projects => projects.filter(project => projectUuids.indexOf(project.id) >= 0))
		)
			.subscribe(selectedProjects => this.form.get('projects').patchValue(selectedProjects));
	}

	public getUser(): User {
		const user = this.form.value;

		if (!this.isOptionalFieldsRequired) {
			user.customer = null;
			user.type = null;
		}

		user.projectUuids = this.form.controls['projects'].value?.map((p: SelectOption<string>) => p.id);
		user.roleUuids = this.form.controls['roles'].value.map((r: SelectOption<Role>) => r.id);

		delete user.roles;

		return user;
	}

	private setSelectedRoles(): void {
		const roleUuids = this.user.roles.map(role => role.uuid);
		this.roles$.pipe(
			map(roles => roles.filter(role => {
				return roleUuids.indexOf(role.id) >= 0;
			}))
		)
			.subscribe(selectedRoles => {
				this.form.get('roles').patchValue(selectedRoles);
			});
	}

	private getFilteredSelectedRoles(): Observable<SelectOption<Role>[]> {
		return this.roles$
			.pipe(map(values =>
				values?.filter(value => this.form.controls['roles'].value?.some((selectOption: SelectOption<Role>) => selectOption.id === value.id)
				)
			));
	}
}
