import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {DndModule} from 'ngx-drag-drop';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ValidatedInputModule} from '../shared/validated-input/validated-input.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TableModule} from '../shared/table/table.module';
import {PaginationModule} from '../shared/pagination/pagination.module';
import {PermissionsModule} from '../shared/permission/permissions.module';
import {ModalModule} from '../shared/modal/modal.module';
import {ProgramOverviewComponent} from './program/program-overview/program-overview.component';
import {CreateOrUpdateProgramModalComponent} from './program/create-or-update-program/create-or-update-program-modal.component';
import {CreateOrUpdateFolderModalComponent} from './folder/create-or-update-folder/create-or-update-folder-modal.component';
import {ErrorHandlingModule} from '../shared/error-handling/error-handling.module';
import {ProgramService} from './_service/program.service';
import {UploadModule} from '../shared/upload/upload.module';
import {DropdownModule} from '../shared/dropdown-multiselect/dropdown.module';
import {FolderOverviewComponent} from './folder/folder-overview/folder-overview.component';
import {NoticeOverviewComponent} from './notice/notice-overview/notice-overview.component';
import {FolderService} from './_service/folder.service';
import {DownloadNoticeFileComponent} from './download-notice-file/download-notice-file.component';
import {RouterModule} from '@angular/router';
import {DocumentOverviewComponent} from './notice/document-overview/document-overview.component';
import {UploadNoticeComponent} from './upload/upload-notice/upload-notice.component';
import {ModularFormsModule} from '../shared/modular-forms/modular-forms.module';
import {UploadNoticeFormComponent} from './upload/upload-notice-form/upload-notice-form.component';
import {UploadNoticeProgressComponent} from './upload/upload-notice-progress/upload-notice-progress.component';
import {ViewNoticeModalComponent} from './notice/view-notice-modal/view-notice-modal.component';
import {MalwareStatusModule} from '../shared/malware-status/malware-status.module';

@NgModule({
	declarations: [
		ProgramOverviewComponent,
		FolderOverviewComponent,
		CreateOrUpdateFolderModalComponent,
		NoticeOverviewComponent,
		CreateOrUpdateProgramModalComponent,
		CreateOrUpdateFolderModalComponent,
		DownloadNoticeFileComponent,
		DocumentOverviewComponent,
		UploadNoticeComponent,
		UploadNoticeFormComponent,
		UploadNoticeProgressComponent,
		ViewNoticeModalComponent
	],
	imports: [
		TranslateModule,
		DndModule,
		CommonModule,
		ReactiveFormsModule,
		ValidatedInputModule,
		NgbModule,
		TableModule,
		PaginationModule,
		PermissionsModule,
		ModalModule,
		FormsModule,
		ErrorHandlingModule,
		UploadModule,
		DropdownModule,
		RouterModule,
		ModularFormsModule,
		MalwareStatusModule
	],
	providers: [
		ProgramService,
		FolderService
	]
})
export class NoticeModule {
}
