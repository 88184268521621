import {Component} from '@angular/core';
import {ModularFormControlInputWithLayout} from '../modular-form-control-layout/modular-form-control-input-with-layout';

@Component({
	selector: 'app-modular-form-control-date',
	templateUrl: './modular-form-control-date.component.html',
	standalone: false
})
export class ModularFormControlDateComponent extends ModularFormControlInputWithLayout {

}
