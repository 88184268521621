import {Component} from '@angular/core';
import {LogoutService} from './logout.service';

@Component({
	selector: 'app-logout-component',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.css'],
	standalone: false
})
export class LogoutComponent {

	constructor(public logoutService: LogoutService) {
	}

}
