import {Component, OnInit} from '@angular/core';
import {Notice} from '../notice';
import {Header} from '../../../shared/table/header';
import {NoticeService} from '../../_service/notice.service';
import {TranslateService} from '@ngx-translate/core';
import {startWith, Subject, Subscription, switchMap} from 'rxjs';
import {BaseOverviewComponent} from '../base-overview/base-overview.component';
import {DeleteObserverFactory} from '../../../shared/delete/delete-observer-factory';

@Component({
	selector: 'app-document-overview',
	templateUrl: './document-overview.component.html',
	standalone: false
})
export class DocumentOverviewComponent extends BaseOverviewComponent implements OnInit {

	notices: Array<Notice>;
	activeSorting: string;
	folders: Array<string>;
	sortedNotices: Array<Notice>;
	headers: Header[] = [
		{name: 'publication-date'},
		{name: 'reference'},
		{name: 'title'},
		{name: 'programs'}
	];

	private refresh$ = new Subject<void>();
	private subscription = new Subscription();

	constructor(private noticeService: NoticeService,
				private translateService: TranslateService,
				private deleteObserverFactory: DeleteObserverFactory) {
		super();
	}

	ngOnInit(): void {
		this.subscription.add(this.refresh$.pipe(startWith('')).pipe(switchMap(() => this.noticeService.getAllDocuments()))
			.subscribe(data => {
				this.notices = data;
				this.folders = [...new Set(data.map(n => n.folder))];
				this.activeSorting = this.folders[0];
				this.sortedNotices = data.filter(n => n.folder === this.activeSorting);
			}));
	}

	setSorting(sorting: string): void {
		this.activeSorting = sorting;
		this.sortedNotices = this.notices.filter(n => n.folder === sorting);
	}

	deleteNotice(notice: Notice): void {
		this.subscription.add(this.noticeService.delete(notice.uuid)
			.subscribe(this.deleteObserverFactory.createFactory('notice.document-overview.delete.success-message', this.refresh$))
		);
	}

}
