 <app-base-modal [title]="getTitleTranslationKey() | translate" [disabled]="submitting">
	<app-user-form #userForm="userForm" [user]="user" [readonly]="submitting"/>
	<ng-container actions>
		<button id="submit-user" type="button" class="btn btn-primary"
				(click)="submitForm(userForm.getUser())"
				[disabled]="userForm.isInvalid() || submitting">
			{{ getSubmitTranslationKey() | translate }}
		</button>
	</ng-container>
</app-base-modal>
