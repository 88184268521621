import {NgModule} from '@angular/core';
import {UserService} from './_service/user.service';
import {UserOverviewComponent} from './user-overview/user-overview.component';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {TableModule} from '../shared/table/table.module';
import {PaginationModule} from '../shared/pagination/pagination.module';
import {ErrorHandlingModule} from '../shared/error-handling/error-handling.module';
import {ReactiveFormsModule} from '@angular/forms';
import {ValidatedInputModule} from '../shared/validated-input/validated-input.module';
import {RoleModule} from '../role/role.module';
import {ModalModule} from '../shared/modal/modal.module';
import {ToastrMessageModule} from '../shared/toastr/toastr.message.module';
import {PermissionsModule} from '../shared/permission/permissions.module';
import {SearchModule} from '../shared/search/search.module';
import {DropdownModule} from '../shared/dropdown-multiselect/dropdown.module';
import {UserFormComponent} from './user-form/user-form.component';
import {ModularFormsModule} from '../shared/modular-forms/modular-forms.module';
import {UserFormModalComponent} from './user-form-modal/user-form.modal.component';
import {UserInformationModalComponent} from './user-information-modal/user-information.modal.component';
import {DownloadModule} from '../shared/download/download.module';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	declarations: [
		UserOverviewComponent,
		UserFormComponent,
		UserFormModalComponent,
		UserInformationModalComponent
	],
	providers: [
		UserService
	],
	imports: [
		TranslateModule,
		TableModule,
		CommonModule,
		PaginationModule,
		ErrorHandlingModule,
		ReactiveFormsModule,
		ValidatedInputModule,
		RoleModule,
		ModalModule,
		ToastrMessageModule,
		PermissionsModule,
		SearchModule,
		DropdownModule,
		ModularFormsModule,
		NgbTooltip,
		DownloadModule
	]
})
export class UserModule {
}

