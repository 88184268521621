import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Role} from './role';

@Injectable()
export class RoleService {

	readonly baseUrl = environment.backend_url + '/role';

	constructor(private http: HttpClient) {
	}

	findAll(): Observable<Array<Role>> {
		return this.http.get<Array<Role>>(this.baseUrl);
	}
}
