import {Observer, Subject} from 'rxjs';
import {ToastrMessageService} from '../toastr/toastr.message.service';
import {ToastrMessageType} from '../toastr/toastr.message.type';
import {TranslateService} from '@ngx-translate/core';

export class DeleteObserver implements Observer<any> {

	constructor(private toastrMessageService: ToastrMessageService,
				private translateService: TranslateService,
				private successMessageKey: string,
				private successAction$?: Subject<void>) {
	}

	complete(): void {
		//do nothing
	}

	error(errorResponse: any): void {
		this.toastrMessageService.show({
			type: ToastrMessageType.ERROR,
			messageHTML: `<i class="ti ti-trash me-2 fs-5"></i> ${this.translateService.instant(errorResponse.error.errorCode)}`,
			sticky: true
		});
	}

	next(): void {
		this.toastrMessageService.show({
			type: ToastrMessageType.SUCCESS,
			messageHTML: `<i class="ti ti-check me-2 fs-5"></i> ${this.translateService.instant(this.successMessageKey)}`,
			sticky: false
		});
		if (this.successAction$) {
			this.successAction$.next();
		}
	}

}
