import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {SelectOption} from '../_model/select-option';
import {Subscription} from 'rxjs';
import {ModularFormControlInputWithLayout} from '../modular-form-control-layout/modular-form-control-input-with-layout';

@Component({
	selector: 'app-modular-form-control-multiselect',
	templateUrl: './modular-form-control-multiselect.component.html',
	standalone: false
})
export class ModularFormControlMultiselectComponent extends ModularFormControlInputWithLayout implements OnInit, AfterViewInit, OnDestroy, OnChanges {

	@Input() public options: SelectOption<any>[] = [];
	@Input() public selectAll!: string;
	@Input() public itemsShowLimit = 5;

	dropdownSettings: any = {};

	private subscription = new Subscription();

	override ngOnInit(): void {
		super.ngOnInit();

		this.subscription.add(this.formControl.valueChanges.subscribe(() => {
			this.changeButtonValidity();
		}));
	}

	ngOnChanges(): void {
		this.options?.filter(option => option.translate).forEach(option => option.label = this.translateService.instant(option.label));

		this.dropdownSettings = {
			'singleSelection': false,
			'defaultOpen': false,
			'idField': 'id',
			'textField': 'label',
			'itemsShowLimit': this.itemsShowLimit,
			'allowSearchFilter': false,
			'enableCheckAll': !!this.selectAll,
			'selectAllText': this.selectAll,
			'unSelectAllText': this.selectAll
		};
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.changeButtonValidity();
	}

	private changeButtonValidity(): void {
		const element = document.getElementById(this.id()).getElementsByClassName('dropdown-btn')[0];
		if (this.hasErrors()) {
			element.classList.add('has-validation', 'is-invalid');
		} else {
			element.classList.remove('has-validation', 'is-invalid');
		}
		if (this.formControl.value || this.formControl.value === '') {
			element.classList.add('placeholder');
		} else {
			element.classList.remove('placeholder');
		}
	}

}
